import { Divider, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Body, Caption } from 'shared/typography';
import { formatPrice } from 'shared/data';
import { ConfigurationCol, ItemPrice } from 'shared/pageElements/styledComponents';
import {
  hideUnselectedOptionsAtom, newBodyAtom, newNeckAtom, partEditModeAtom, useTestDataAtom,
} from 'shared/state/pricingState';
import { PriceFieldWrapper, RateOptionNameWrapper, RateOptionWrapper } from '../styledComponents';
import { IRateOption, IUnitOption } from '../../../../shared/types/pricingTool';

const _ = require('lodash');
const shortid = require('shortid');

interface ComponentInterface {
  optionMasterList: IRateOption[];
  recordOptionSet: any[];
  partType: 'body'|'neck';
  listTitle: string;
}

export default ({
  optionMasterList, recordOptionSet, partType, listTitle,
}: ComponentInterface) => {
  // @ts-ignore
  const [itemState, setItemState] = useRecoilState(partType === 'body' ? newBodyAtom : newNeckAtom);
  const editMode = useRecoilValue(partEditModeAtom);
  const [optionsSelected, setOptionsSelected] = useState(false);
  const useTestData = useRecoilValue(useTestDataAtom);

  const onChange = (item: IRateOption) => (e: any) => {
    const existing = _.find(recordOptionSet, (o: IRateOption) => o.id === item.id);

    const newOptionRecord = {
      ...(existing || item),
      quantity: parseFloat(e.currentTarget.value),
    };

    const newOptions = [
      ...recordOptionSet.filter((o: IRateOption) => o.id !== newOptionRecord.id), newOptionRecord];

    const newRecord = {
      ...itemState,
      options: {
        ...itemState.options,
        ...{ [item.type]: newOptions },
      },
    };

    // @ts-ignore
    // newRecord[partType] = {
    //   @ts-ignore
    // ...newRecord[partType],
    // options: {
    //   @ts-ignore
    //   ...newRecord[partType].options,
    // ...{ [item.type]: newOptions },
    // },
    // };

    setItemState(newRecord);
    setOptionsSelected(newOptions.length > 0);
  };

  useEffect(() => {
    let listType = _.uniq(optionMasterList.map((o: IRateOption) => o.type));
    listType = listType.length ? listType[0] : null;
    setOptionsSelected(itemState.options[listType]?.length > 0);
  }, [itemState]);

  return (
    <>
      { (editMode || optionsSelected)
          && (
          <>
            <Divider orientation="left">{listTitle}</Divider>
            <ConfigurationCol>
              {optionMasterList.map((o: IRateOption) => {
                const currentOption = _.find(recordOptionSet, (r: IRateOption) => r.id === o.id);
                const listOption = currentOption || o;
                return (
                  <>
                    {(editMode || !!listOption.quantity)
                    && (
                    <RateOptionWrapper key={shortid.generate()}>
                      <RateOptionNameWrapper>
                        <Body>{o.name}</Body>
                        <Caption>{`@ ${formatPrice(listOption.price)}/${listOption.rate}`}</Caption>
                      </RateOptionNameWrapper>
                      <PriceFieldWrapper>
                        <InputNumber
                          placeholder="0.00"
                          step="0.25"
                          value={listOption.quantity}
                          defaultValue={0}
                          // @ts-ignore
                          onBlur={onChange(listOption)}
                        />
                        <Caption>{`${o.rate}s`}</Caption>
                        <ItemPrice testMode={useTestData} selected={listOption.quantity > 0}>
                          {formatPrice(listOption.quantity * listOption.price)}
                        </ItemPrice>
                      </PriceFieldWrapper>
                    </RateOptionWrapper>
                    )}
                  </>
                );
              })}
            </ConfigurationCol>
          </>
          )}
    </>
  );
};
