import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';
import { adminUserEmailsAtom, customerUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import { FlexRow } from 'shared/containers/FlexContainer';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import CopyItemButton from './CopyItemButton';
import OtherActionsButton from './OtherActionsButton';
import CopyPartDataButton from './CopyPartDataButton';
import SeeParentPartButton from './SeeParentPartButton';

interface IComponent {
  customerPart: any;
  sortMode: 'like'|'sku'|'description';
}

const ComponentWrapper = styled(FlexRow)`
    gap: 4px;
`;
const ActionButtons = ({ customerPart, sortMode }: IComponent) => {
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const partType = (customerPart.type.match(/[G|B]N/i) || customerPart.type === 'neck') ? 'neck' : 'body';
  const copyButtonId = `${customerPart.Sku}-copy`;
  const [_customerPart, setCustomerPart] = useState<any>(customerPart);
  const [_sortMode, setSortMode] = useState<'like'|'sku'|'description'>(sortMode);

  useEffect(() => {
    setSortMode(sortMode);
  }, [sortMode]);

  useEffect(() => {
    setCustomerPart(customerPart);
  }, [customerPart]);

  return (
    <>
      <ScopedComponent whitelist={[...adminUsers.emails, ...superAdminUsers.emails]}>
        <ComponentWrapper>
          <Tooltip title="Copy part data">
            <CopyItemButton isPriced={!!_customerPart.pricing} partType={partType} pricedItem={_customerPart} />
          </Tooltip>
          <OtherActionsButton description={_customerPart.Description} partNumber={_customerPart.Sku} parentSku={_customerPart.parent?.Sku} sortMode={sortMode} />
        </ComponentWrapper>
      </ScopedComponent>
      <ScopedComponent whitelist={[...customerEmails.emails]}>
        <FlexRow>
          <OtherActionsButton description={_customerPart.Description} partNumber={_customerPart.Sku} parentSku={_customerPart.parent?.Sku} sortMode={sortMode} />
          <CopyPartDataButton hidden id={copyButtonId} record={_customerPart} />
        </FlexRow>
      </ScopedComponent>
    </>
  );
};

export default ActionButtons;
