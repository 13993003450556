import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { currentInventoryPartAtom } from 'shared/state/inventoryState';
import { noop } from 'lodash';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWIthCallback';
import SubItemStatusSelector from './SubItemStatusSelector';
import SubItemParentDropdown from './SubItemParentDropdown';
import SubItemQtyPerParentInput from './SubItemQtyPerParentInput';

const ComponentWrapper = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

const AllocationRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;
const ParentSelectorRow = styled(AllocationRow)`
    width: 100%;
`;

interface IComponent {
  disabled: boolean;
}
const SubItemParentDescription = ({ disabled }: IComponent) => {
  const partState = useRecoilValue(currentInventoryPartAtom);

  return (
    <ComponentWrapper>
      <AllocationRow>
        <SubItemStatusSelector disabled={disabled} />
        <SubItemQtyPerParentInput disabled={disabled} />
      </AllocationRow>
      {partState.Parent && (
        <ParentSelectorRow>
          <SubItemParentDropdown disabled={disabled} />
          <DetailInputWithCallback
            width="400px"
            id="inventory-part-parent-description"
            label="Description"
            placeholder=""
            value={partState.Parent.Description}
            callback={noop}
            isLabel
            extend
            disabled
          />
        </ParentSelectorRow>
      )}

    </ComponentWrapper>
  );
};

export default SubItemParentDescription;
