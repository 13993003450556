import React, { useEffect, useState } from 'react';
import {
  Progress, Modal, Upload, Tooltip, Menu, Dropdown, Button,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { CaretDown, Upload as UploadIcon } from '@styled-icons/fa-solid';
import useFirebase from 'vendor/Firebase';
import bodyParser from 'shared/partParser/bodyParser';
import neckParser from 'shared/partParser/neckParser';
import { useRecoilValue, useRecoilState } from 'recoil';
import { uploadProgressAtom } from 'shared/state/partViewState';
import { useTestDataAtom } from 'shared/state/pricingState';
import { devLog } from 'shared/util/logging';
import styled, { keyframes } from 'styled-components';
import theme from 'shared/theme';
import {
  every, first, groupBy, noop, omit,
} from 'lodash';
import { CSVDownload, CSVLink } from 'react-csv';
import { checkGeometricDifferences, isSamePart, sanitizePartDescription } from '../../../shared/partParser/util';
import { CSVDownloadButton, DownloadButton } from '../../../shared/styledComponents/inputs';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const UploadButton = styled(Button)`
  flex-grow: 2;
  border-radius: 8px;
  min-width: 160px;
`;
const SaveSpinner = styled.div`
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-left: 32px;

  border-radius: 50%;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.primary.hue};

  animation: ${rotation} 1s linear infinite;
`;

export default () => {
  const { firestore } = useFirebase();
  const [processedPercent, setProcessedPercent] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useRecoilState(uploadProgressAtom);
  const [csvData, setCsvData] = useState<any>([]);

  const onUploadParts = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result
        .split('\n')
        .slice(1)
        .map((d: string) => d.trim().replace(/"/g, '').split(',').filter((s: string) => s.length > 0))
        .filter((d: string[]) => d.length > 1);
      const rawBodyData = data.filter((d: string[]) => d[2].match(/[G|B]B_/));
      const parsedBodyData = bodyParser(rawBodyData);
      const rawNeckData = data.filter((d: string[]) => d[2].match(/[G|B]N_/));
      const parsedNeckData = neckParser(rawNeckData);

      const partCount = parsedBodyData.length + parsedNeckData.length;
      let processedCount = 0;
      let newPartCount = 0;
      setUploadInProgress(true);

      if (partCount > 1000) {
        Modal.confirm({
          title: 'Large Upload Detected',
          content: 'You are attempting to upload a large set of data. This operation may take several minutes, with periods of inactivity from the UI. Continue?',
          okText: 'Yes',
          cancelText: 'No',
          onCancel: () => {
            setUploadInProgress(false);
            return false;
          },
        });
      }
      const finishUp = () => {
        setProcessedPercent(Math.ceil((processedCount / partCount) * 100));
        if (processedCount === partCount) {
          devLog('JBDataUploader', 65, 'Upload complete!');
          const cachedDataKeys = Object.keys(localStorage).filter((k: string) => k.match(/partViewerData$/));
          cachedDataKeys.forEach((k: string) => localStorage.removeItem(k));
          Modal.info({
            content: `${processedCount - newPartCount} parts updated, ${newPartCount} added to database. Click OK to reload!`,
            okText: 'OK',
            onOk: () => {
              window.location.reload();
            },
          });
        }
      };

      [...parsedBodyData, ...parsedNeckData].forEach((part: any) => {
        const partCategory = part.type.match(/[G|B]N/i) ? 'neck' : 'body';
        const partRecord = { ...part, partCategory };

        firestore.collection('part-viewer-data').doc(partRecord.Sku).get().then((doc) => {
          let docData = partRecord;
          if (!doc.exists) {
            newPartCount += 1;
          } else {
            docData = doc.data();
          }
          const updateData = { ...docData, ...partRecord };
          // if (docData.pricing) updateData = { ...partRecord, price: partRecord.price, pricing: docData.pricing };
          if (partRecord.volume !== '') {
            const vol = parseFloat(partRecord.volume).toFixed(3);
            updateData.volume = vol;
          }
          console.log(updateData);
          firestore.collection('part-viewer-data').doc(partRecord.Sku).set(updateData).then(() => {
            processedCount += 1;
            // if (!isDev) {
            //   processedCount -= 1;
            //   firestore.collection('part-viewer-data-test').doc(partRecord.partNumber).set(updateData).then(() => {
            //     processedCount += 1;
            //     devLog('JBDataUploader', 101, `Uploaded: ${partRecord.partNumber}`);
            //   })
            //     .finally(() => {
            //       finishUp();
            //     });
            // }
          })
            .catch((err) => {
              devLog('JBDataUploader', 107, err);
            })
            .finally(() => {
              finishUp();
            });
        })
          .catch((err: any) => {
            devLog('JBDataUploader', 114, partRecord);
            devLog('JBDataUploader', 115, err);
          });
      });
    };

    reader.readAsText(file);

    return false;
  };
  const onUploadOrder = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(1)
        .map((d: string) => d.trim().split(','));
      // .map((d: string[]) => d.filter((s: string) => s.match(/^[G|B][B|N]/)));

      const customer = data[0][0].split('_')[0];
      const partCandidates = data.map((d: string[]) => ({ sku: d[0], description: d[1] })).filter((d) => d && d.description);
      const bodyCandidates = partCandidates.filter((c: { sku: string, description: string }) => c.description.match(/^[G|B]B/));
      const neckCandidates = partCandidates.filter((c: { sku: string, description: string }) => c.description.match(/^[G|B]N/));

      const parsedBodies = bodyParser(bodyCandidates.map((c: { sku: string, description: string }) => [true, '', sanitizePartDescription(c.description), '', '', '']));
      const parsedNecks = neckParser(neckCandidates.map((c: { sku: string, description: string }) => [true, '', sanitizePartDescription(c.description), '', '', '']));

      const customerPartDocs = await firestore.collection('part-viewer-data').where('customer', '==', customer).get();
      const customerParts = customerPartDocs.docs.map((d) => d.data());

      const newParts = [...parsedBodies, ...parsedNecks].map((parsedItem: any) => {
        // FIRST, check if this part has a twin, e.g., it exists already
        const twin = first(customerParts
          .filter((p) => !!p.Description)
          .map((p) => (isSamePart(p, parsedItem) ? p : null))
          .filter((m) => m));

        // @ts-ignore -- twin is checked for definition before calling
        if (twin !== undefined) return { requested: parsedItem.Description, part: omit(twin, 'parent'), result: 'exists' };

        // NEXT, if no twin is found, check for a parent, e.g. a part that is geometrically equivalent
        const parentCandidates = customerParts
          .map((p) => {
            const geoDiff = checkGeometricDifferences(p, parsedItem);
            if (every(geoDiff)) {
              return p;
            }
            return null;
          }).filter((p) => p);

        if (parentCandidates.length > 0) {
          const parent = parentCandidates[0] as any;
          return {
            requested: parsedItem.Description,
            part: { ...parsedItem, Sku: 'NEW', parent: parent.Sku },
            result: 'new child',
          };
        }

        // If neither a twin nor a parent exists, this is a new part
        return {
          requested: parsedItem.Description,
          part: { ...parsedItem, Sku: 'NEW' },
          result: 'new part',
        };
      });

      const records: string[][] = newParts.map((p) => [p.part.Sku as string, p.requested as string, p.result as string, p.part.parent as string || '']);
      setCsvData(records);
    };

    reader.readAsText(file);

    return false;
  };

  const onClickDownload = (e: any) => {
    setTimeout(() => {
      setCsvData([]);
    }, 100);
  };
  const importMenu = (
    <Menu onClick={noop}>
      <Menu.Item key="helm-data">
        <Upload
          accept=".txt, .csv"
          showUploadList={false}
          beforeUpload={onUploadParts}
        >
          <UploadButton type="primary" icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>HELM Parts</UploadButton>
        </Upload>
      </Menu.Item>
      <Menu.Item key="order-data">
        <Upload
          accept=".txt, .csv"
          showUploadList={false}
          beforeUpload={onUploadOrder}
        >
          <UploadButton icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>Customer Order</UploadButton>
        </Upload>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {uploadInProgress ? (
        <>
          {processedPercent >= 1 ? (
            <Progress type="circle" percent={processedPercent} width={64} style={{ marginLeft: 24 }} />
          ) : (
            <SaveSpinner />
          )}
        </>
      ) : (
        <>
          {csvData.length === 0 ? (
            <Tooltip placement="top" title="Use this to upload data in a csv format back into the pricing tool.">
              <Dropdown.Button
                trigger={['hover']}
                icon={<CaretDown style={{ width: 10, marginBottom: 4 }} />}
                onClick={noop}
                overlay={importMenu}
                type="default"
                placement="bottom"
              >
                Import
              </Dropdown.Button>
            </Tooltip>
          ) : (
            <CSVDownloadButton filename="Customer Order Data.csv" data={csvData || []} onClick={onClickDownload}>Download</CSVDownloadButton>
          )}
        </>
      )}
    </>
  );
};
