import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { useRecoilValue } from 'recoil';
import { find, noop } from 'lodash';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWIthCallback';
import { INVENTORY_ITEMS_COLLECTION } from 'shared/state/inventoryState';
import { IInventoryChild, IInventoryPart } from 'shared/types/dbRecords';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWIthCallback';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import useFirebase from 'vendor/Firebase';

const GAP = 20;
const ComponentWrapper = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`;

const SubItemWrapper = styled(FlexRow)`
    width: 100%;
    justify-content: flex-start;
    gap: 12px; 
    padding-top: 4px;
    border-bottom: 1px solid lightgrey;
`;
interface IComponent {
  partChildren: IInventoryChild[];
  weightChangeCallback: (children: { Sku: string, PurchaseCost: number, purchaseWeight: number }[]) => void;
}

interface IChildRecord {
  Sku: string;
  Description: string;
  QtyOnHand: number;
  Parent: { ParentPerChild: number };
  purchaseWeight: number;
  PurchaseCost: number;
  Vendor: string;
}
const InventoryPartChildren = ({ partChildren, weightChangeCallback }: IComponent) => {
  const { firestore } = useFirebase();
  const [_children, setChildren] = useState<IChildRecord[]>([]);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);

  const onChangeWeight = (childSku: string) => (weight: string) => {
    const numericWeight = parseFloat(weight) > 1 ? parseFloat(weight) / 100 : parseFloat(weight);
    const updatedChildren = _children.map((c: IChildRecord) => {
      if (c.Sku === childSku) return { Sku: c.Sku, PurchaseCost: c.PurchaseCost, purchaseWeight: numericWeight };
      // divvy up the remaining available percentage amongst the other children
      const otherPartWeight = parseFloat(((1 - numericWeight) / (_children.length - 1)).toFixed(2));
      return { Sku: c.Sku, PurchaseCost: c.PurchaseCost, purchaseWeight: otherPartWeight };
    });
    weightChangeCallback(updatedChildren);
  };

  useEffect(() => {
    if (partChildren.length) {
      firestore.collection(inventoryDbString).where('Sku', 'in', partChildren.map((c: IInventoryChild) => c.sku)).get().then((snap) => {
        const _childData = snap.docs.map((d) => d.data()) as IInventoryPart[];
        setChildren(_childData.map((c: IInventoryPart) => {
          const childOfParent = find(partChildren, (_c: IInventoryChild) => _c.sku === c.Sku) as IInventoryChild;
          // if there is a child, return the record with the purchase weight
          // if there is no purchase weight (e.g., it has not yet been set), calculate it as 1 / n
          // where n is the number of children of the inventory item.
          return {
            Sku: c.Sku,
            Description: c.Description,
            Parent: { ParentPerChild: c.Parent?.ParentPerChild || 1 },
            purchaseWeight: childOfParent?.purchaseWeight || parseFloat((1 / _childData.length).toFixed(2)),
            QtyOnHand: c.QtyOnHand,
            PurchaseCost: c.PurchaseCost,
            Vendor: c.PrefVendorRef.name,
          } as IChildRecord;
        }) as IChildRecord[]);
      });
    }
  }, [partChildren]);

  return (
    <ComponentWrapper>
      <PartDetailColumnHeader marginTop={GAP}>Sub-items</PartDetailColumnHeader>
      <>
        {_children.map((c: IChildRecord) => (
          <>
            <SubItemWrapper>
              <DetailInputWithCallback
                id={`inventory-part-${c.Sku}`}
                label="Part Number"
                placeholder=""
                value={c.Sku}
                callback={noop}
                extend={false}
                isLabel
                disabled
              />
              <DetailInputWithCallback
                width="80px"
                id={`inventory-vendor-${c.Sku}`}
                label="Vendor"
                placeholder=""
                value={c.Vendor}
                callback={noop}
                extend={false}
                isLabel
                disabled
              />
              <DetailInputWithCallback
                id={`inventory-description-${c.Sku}`}
                label="Description"
                placeholder=""
                value={c.Description}
                callback={noop}
                extend
                isLabel
                disabled
              />
              <DetailNumberInputWithCallback
                width="80px"
                id={`inventory-available-${c.Sku}`}
                label="On Hand"
                placeholder="0"
                value={Math.round(c.QtyOnHand * (c.Parent?.ParentPerChild || 1))}
                isLabel
                callback={noop}
                disabled
              />
              <DetailNumberInputWithCallback
                width="80px"
                id={`inventory-available-${c.Sku}`}
                label="Weight"
                placeholder="100%"
                value={c.purchaseWeight || parseFloat((1 / _children.length).toFixed(2))}
                callback={onChangeWeight(c.Sku)}
                isPercent
              />
            </SubItemWrapper>
          </>
        ))}
      </>
    </ComponentWrapper>
  );
};

export default InventoryPartChildren;
