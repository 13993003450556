import { atom } from 'recoil';
import firebase from 'firebase';
import { IQATicket } from '../types/dbRecords';

export const qualityAssuranceTicketsAtom = atom<IQATicket[]>({
  key: 'qualityAssuranceTicketsAtom',
  default: [] as IQATicket[],
});

export const QA_TICKETS_COLLECTION = atom<string>({
  key: 'QA_TICKETS_COLLECTION',
  default: 'qa-tickets',
});
export const currentQaTicketAtom = atom<IQATicket>({
  key: 'currentQaTicketAtom',
  default: {
    closedBy: null,
    closedDate: null,
    createdBy: '',
    createdDate: firebase.firestore.Timestamp.fromDate(new Date()),
    customer: '',
    department: '',
    estimatedCost: 1,
    id: '',
    immediateCorrectionDescription: '',
    implementationDate: null,
    implementationEmployee: '',
    incidentDate: firebase.firestore.Timestamp.fromDate(new Date()),
    lastUpdatedDate: null,
    nonConformanceCorrectiveAction: '',
    nonConformanceDescription: '',
    nonConformanceExceptionCode: '',
    nonConformanceImageUrls: [],
    otherModelsAffected: '',
    partDescription: '',
    partNumber: '',
    partQuantity: 1,
    perceivedCauseDescription: '',
    permanentCorrectionDescription: '',
    results: '',
    reportType: 'non-conformance',
    salesOrder: '',
    title: '',
    underInvestigation: false,
  },
});

export const qaTicketImageEditModeAtom = atom<boolean>({
  key: 'qaTicketImageEditMode',
  default: false,
});

export const qaInvestigationUsers = atom<string[]>({
  key: 'qaInvestigationUsers',
  default: [
    'keithh@wildwoodmfg.com',
    'darrans@wildwoodmfg.com',
    'bruceh@wildwoodmfg.com',
  ],
});

export const qaTicketEdited = atom<boolean>({
  key: 'qaTicketEdited',
  default: false,
});

export const showNCReportAtom = atom<boolean>({
  key: 'showNCReportAtom',
  default: false,
});
