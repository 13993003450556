import React, { useContext, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  find, flatten, includes, sortBy,
} from 'lodash';
// import * as _ from 'lodash';
import qs from 'qs';
import {
  NON_CONFORMANCE_COLLECTION, ROUTER_DATA_PATH,
  shopOperatorsObjectAtom, GL_CODES_COLLECTION, PRODUCT_CODES_COLLECTION,
} from 'shared/state/siteState';
import { routerStepsAtom, customerUserEmailsAtom } from 'shared/state/routingState';
import {
  BODY_DATA_PATH,
  bodyDataAtom, customerPartsAtom, NECK_DATA_PATH, neckDataAtom, useTestDataAtom,
} from 'shared/state/pricingState';

import {
  PART_VIEWER_COLLECTION,
  partViewerConfigDataAtom,
} from 'shared/state/partViewState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { useParams } from 'react-router-dom';
import firebase from 'firebase';
import {
  glCodesAtom,
  ncCorrectiveActionsObjectAtom,
  ncReasonsObjectAtom,
  ncRecordsAtom,
  productCodesAtom,
} from 'shared/state/utilState';
import {
  accessToken, orderShippingAddress, orderBillingAddress,
} from 'shared/data/jb_api';
import {
  ICustomerRecord, IInventoryPart, INCRecord, IOrderItem, IPurchaseOrder, IQATicket, IShipment, IShippingAddress,
} from 'shared/types/dbRecords';
import {
  IContact,
  IGlCode, IProductCode,
} from 'shared/types/jb';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import { IVendor } from 'shared/types/vendor';
import { VENDOR_COLLECTION, vendorRecordsAtom } from 'shared/state/vendorState';
import { qboAuthRefreshTokenAtom } from 'shared/state/qboState';
import {
  currentShopOrderAtom, openOrdersDataAtom, ORDER_ITEMS_DB_COLLECTION,
  orderBillingDataAtom,
  orderItemsAtom,
  ORDERS_DB_COLLECTION, orderShipmentsAtom,
  orderShippingDataAtom, shopOrdersAtom,
} from '../shared/state/orderState';
import { urlQueryString } from '../shared/util';
import { ISalesOrder, IShopOrder } from '../pages/Orders/types';
import {
  activeCustomerDisplayAtom,
  currentCustomerAtom,
  currentCustomerShippingAddressesAtom,
  CUSTOMER_CONTACT_DB_COLLECTION, CUSTOMER_SHIPPING_DB_COLLECTION,
  customerContactsAtom,
  CUSTOMERS_DB_COLLECTION,
  customersAtom,
  customerShippingAddressesAtom,
} from '../shared/state/customerState';
import { devLog } from '../shared/util/logging';
import { purchaseOrdersAtom } from '../shared/state/purchaseOrderState';
import { SHIPMENT_ITEMS_COLLECTION, shipmentItemsAtom } from '../shared/state/shipmentState';
import { QA_TICKETS_COLLECTION, qualityAssuranceTicketsAtom } from '../shared/state/qualityAssuranceState';

export default (dependencyList: string[]) => {
  const { database, firestore } = useFirebase();
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const routeParams = useParams();
  const [routerSteps, setRouterSteps] = useRecoilState(routerStepsAtom);
  const [customers, setCustomers] = useRecoilState(customersAtom);
  const [customerContacts, setCustomerContacts] = useRecoilState(customerContactsAtom);
  const [customerShippingAddresses, setCustomerShippingAddresses] = useRecoilState(customerShippingAddressesAtom);
  const [ncRecords, setNcRecords] = useRecoilState(ncRecordsAtom);
  const [openOrders, setOpenOrders] = useRecoilState(openOrdersDataAtom);
  const [orders, setOrders] = useRecoilState(shopOrdersAtom);
  const [bodyData, setBodyData] = useRecoilState(bodyDataAtom);
  const [neckData, setNeckData] = useRecoilState(neckDataAtom);
  const [inventoryItems, setInventoryItems] = useRecoilState(inventoryItemsAtom);
  const [purchaseOrders, setPurchaseOrders] = useRecoilState(purchaseOrdersAtom);
  const [glCodes, setGlCodes] = useRecoilState(glCodesAtom);
  const [productCodes, setProductCodes] = useRecoilState(productCodesAtom);
  const [partViewerConfigData, setPartViewerConfigData] = useRecoilState(partViewerConfigDataAtom);
  const [ncReasonCodes, setNcReasonCodes] = useRecoilState(ncReasonsObjectAtom);
  const [ncCorrectiveActions, setNcCorrectiveActions] = useRecoilState(ncCorrectiveActionsObjectAtom);
  const [shopOperators, setShopOperators] = useRecoilState(shopOperatorsObjectAtom);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [customerParts, setCustomerParts] = useRecoilState(customerPartsAtom);
  const [currentCustomerShippingAddresses, setCurrentCustomerShippingAddresses] = useRecoilState(currentCustomerShippingAddressesAtom);
  const [vendors, setVendors] = useRecoilState(vendorRecordsAtom);
  const [qaTickets, setQaTickets] = useRecoilState(qualityAssuranceTicketsAtom);
  const setCurrentShopOrder = useSetRecoilState(currentShopOrderAtom);
  const setOrderShipments = useSetRecoilState(orderShipmentsAtom);
  const [billingData, setBillingData] = useRecoilState(orderBillingDataAtom);
  const customersDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const customerContactDbString = useRecoilValue(CUSTOMER_CONTACT_DB_COLLECTION);
  const customerShippingAddressDbString = useRecoilValue(CUSTOMER_SHIPPING_DB_COLLECTION);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const vendorsDbString = useRecoilValue(VENDOR_COLLECTION);
  const qaTicketsDbCollection = useRecoilValue(QA_TICKETS_COLLECTION);
  const glCodeDbString = useRecoilValue(GL_CODES_COLLECTION);
  const nonConformanceDbString = useRecoilValue(NON_CONFORMANCE_COLLECTION);
  const productCodeDbString = useRecoilValue(PRODUCT_CODES_COLLECTION);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const orderShipmentsDbString = useRecoilValue(SHIPMENT_ITEMS_COLLECTION);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const bodyDataString = useRecoilValue(BODY_DATA_PATH);
  const neckDataString = useRecoilValue(NECK_DATA_PATH);
  const routerDataString = useRecoilValue(ROUTER_DATA_PATH);
  const useTestData = useRecoilValue(useTestDataAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const customerViewType = useRecoilValue(activeCustomerDisplayAtom);

  const dbDeps = {
    routerSteps: {
      fn: async () => {
        database.ref(`${routerDataString}/steps`).once('value').then((snapshot) => {
          const data = snapshot.val();
          setRouterSteps(data);
          devLog('useDependencies', 92, 'Router steps loaded');
        });
      },
      test: () => routerSteps.length > 0,
    },
    customers: {
      fn: async () => {
        const includeInactive = includes(['all', 'inactive'], customerViewType);
        devLog('useDependencies', 115, `Customer view type: ${customerViewType}`);
        devLog('useDependencies', 115, `Show inactive customers: ${includeInactive}`);
        devLog('useDependencies', 115, routeParams);
        firestore.collection(customersDbString)
          .get().then((snapshot) => {
            const data: ICustomerRecord[] = [];
            snapshot.forEach((c) => {
              const d = c.data();
              if (d.active || includeInactive) {
                data.push(d as ICustomerRecord);
              }
            });
            setCustomers(sortBy(data, (d: ICustomerRecord) => d.DisplayName));
            if (includes(customerEmails.emails, currentUser.email)) {
              const login = currentUser.email.split('@')[0];
              const cust = find(data, (c: ICustomerRecord) => c.login === login);
              if (cust) setCurrentCustomer(cust);
            } else if (localStorage.getItem('currentCustomerId')) {
              const localId = localStorage.getItem('currentCustomerId');
              const cust = find(data, (c: ICustomerRecord) => c.id === localId);
              if (cust) setCurrentCustomer(cust);
            }
            devLog('useDependencies', 115, 'Customers loaded');
          });
      },
      test: () => customers.length > 0,
    },
    customerContacts: {
      fn: async () => {
        firestore.collection(customerContactDbString).get().then((snap) => {
          const data: IContact[] = [];
          snap.forEach((doc) => {
            data.push(doc.data().contacts as IContact);
          });
          setCustomerContacts(flatten(data));
        });
      },
      test: () => customerContacts.length > 0,
    },
    glCodes: {
      fn: async () => {
        firestore.collection(glCodeDbString).get().then((snapshot) => {
          const data: IGlCode[] = [];
          snapshot.forEach((g) => {
            data.push(g.data() as IGlCode);
          });
          setGlCodes(data);
        });
      },
      test: () => glCodes.length > 0,
    },
    inventory: {
      fn: async () => {
        firestore.collection(inventoryDbString).get().then((snapshot) => {
          const data: IInventoryPart[] = snapshot.docs.map((d) => d.data() as IInventoryPart);
          setInventoryItems(data);
          devLog('useDependencies', 138, 'Inventory loaded');
        });
      },
      test: () => inventoryItems.length > 0,
    },
    purchaseOrders: {
      fn: async () => {
        firestore.collection('purchase-orders').get().then((snapshot) => {
          const data: IPurchaseOrder[] = snapshot.docs.map((d) => d.data() as IPurchaseOrder);
          if (!data.length) setPurchaseOrders([null]);
          setPurchaseOrders(data);
          devLog('useDependencies', 193, 'Purchase Orders loaded');
        });
      },
      test: () => purchaseOrders.length > 0,
    },
    openOrders: {
      fn: async () => {
        firestore.collection(ordersDbString)
          .where('completed', '==', false)
          .get().then((snapshot) => {
            const data: IShopOrder[] = snapshot.docs.map((d) => d.data() as IShopOrder);
            setOpenOrders(data);
          });
      },
      test: () => openOrders.length > 0,
    },
    allOrders: {
      fn: async () => {
        firestore.collection(ordersDbString)
          .get().then((snapshot) => {
            const data: ISalesOrder[] = [];
            snapshot.forEach((d) => {
              data.push(d.data() as ISalesOrder);
            });
            setOrders(data as ISalesOrder);
          });
      },
      test: () => orders.length > 0,
    },
    productCodes: {
      fn: async () => {
        firestore.collection(productCodeDbString).get().then((snapshot) => {
          const data: IProductCode[] = [];
          snapshot.forEach((p) => {
            data.push(p.data() as IProductCode);
          });
          setProductCodes(data);
        });
      },
      test: () => productCodes.length > 0,
    },
    nonConformanceData: {
      fn: async () => {
        firestore.collection(nonConformanceDbString)
          .get().then((snapshot) => {
            const data: INCRecord[] = [];
            snapshot.forEach((c) => {
              data.push(c.data() as INCRecord);
            });
            if (!data.length) {
              const dateRecorded = firebase.firestore.Timestamp.fromDate(new Date(1980, 0, 21));
              setNcRecords([{
                id: 'foooooo',
                dateRecorded,
                salesOrder: '9999',
                customerId: 'FOOBAR',
                workOrder: '1/1',
                department: 'Office',
                partNumber: '666',
                partDescription: 'The Devil\'s Bane',
                quantity: 1,
                reasonCode: 'Other',
                correctiveAction: 'None',
                notes: 'Blue hunnids',
                type: 'body',
              }]);
            } else {
              setNcRecords(_.sortBy(data, (d: INCRecord) => d.dateRecorded.toDate()));
            }
          });
      },
      test: () => ncRecords.length > 0,
    },
    partData: {
      fn: async () => {
        database.ref('/').once('value').then((dbData) => {
          const bData = dbData.val()[bodyDataString];
          const nData = dbData.val()[neckDataString];
          setBodyData(bData);
          setNeckData(nData);
        });
      },
      test: () => Object.keys(bodyData).length > 0 && Object.keys(neckData).length > 0,
    },
    partViewerConfig: {
      fn: async () => {
        database.ref('/customerPartViewerConfig').once('value').then((dbData) => {
          const data = dbData.val();
          if (_.includes(customerEmails.emails, currentUser.email)) {
            const customerKey = _.find(Object.keys(data), (k: string) => currentUser.email.match(new RegExp(`^${k.slice(0, 4)}`, 'i')));
            const config = {
              UNIVERSAL: data.UNIVERSAL,
            };
            config[customerKey] = data[customerKey];
            setPartViewerConfigData(config);
          } else {
            setPartViewerConfigData(data);
          }
        });
      },
      test: () => Object.keys(partViewerConfigData).length > 1,
    },
    ncConfig: {
      fn: async () => {
        database.ref('/ncConfig').once('value').then((dbData) => {
          const { correctiveActions, reasonCodes } = dbData.val();
          setNcCorrectiveActions(correctiveActions);
          setNcReasonCodes(reasonCodes);
        });
      },
      test: () => (Object.values(ncCorrectiveActions).length > 0 && Object.values(ncReasonCodes).length > 0),
    },
    operatorData: {
      fn: async () => {
        database.ref('/operators').once('value').then((dbData) => {
          const operators = dbData.val();
          setShopOperators(operators);
        });
      },
      test: () => !!shopOperators.length,
    },
    orderBillingData: {
      fn: async () => {
        const currentOrderId = qs.parse(window.location.href.replace('?', '')).orderId;
        if (currentOrderId === 'ph') {
          setBillingData(false);
          return;
        }
        if (currentOrderId) {
          firestore.collection(ordersDbString).doc(currentOrderId).get().then((doc) => {
            if (!doc.exists) setBillingData(false);
            const id = doc.data().customer.id;

            accessToken().then((token) => {
              // @ts-ignore
              orderBillingAddress(token, id)
                .then((res) => {
                  // @ts-ignore
                  setBillingData(res);
                  devLog('useDependencies', 282, 'Billing info loaded');
                })
                .catch((err) => setBillingData(false));
            });
          });
        }
      },
      test: () => billingData !== null,
    },
    shopOrderData: {
      fn: async () => {
        const currentOrderId = qs.parse(window.location.href.replace('?', '')).orderId;
        if (currentOrderId) {
          // get the shop order data
          const shopOrderDoc = await firestore.collection(ordersDbString).doc(currentOrderId).get();
          const shopOrderData = shopOrderDoc.data();
          if (!shopOrderDoc.exists || !shopOrderData) {
            setOrderItems([null]);
            return;
          }
          setCurrentShopOrder(shopOrderData as IShopOrder);
          setCurrentCustomer(shopOrderData.customer);
          devLog('useDependencies', 360, 'shop order data loaded');

          const customerPartDocs = await firestore.collection(partViewerDbString).where('customer', '==', shopOrderData.customer.DisplayName).get();
          setCustomerParts(customerPartDocs.docs.map((d) => d.data()) as any);
          devLog('useDependencies', 364, 'customer parts data loaded');

          // @ts-ignore
          const orderItemDoc = await firestore.collection(orderItemsDbString).doc(shopOrderDoc.id).get();
          const itemsData = orderItemDoc.data();
          if (!orderItemDoc.exists || !itemsData || Object.keys(itemsData).length === 0 || itemsData.orderItems.filter((i: IOrderItem) => i).length === 0) {
            setOrderItems([null]);
          } else {
            const orderItemData = itemsData.orderItems
              .filter((i: IOrderItem) => !i.Sku.match(/^[1]/))
              .map((i: IOrderItem) => ({
                ...i,
                Sku: i.Sku,
                Description: i.Description,
                quantityOpen: i.quantityOrdered - (i.quantityShipped + i.quantityCanceled),
              }));
            setOrderItems(orderItemData);
            devLog('useDependencies', 338, 'shop order items loaded');
          }
          const shippingAddressesDoc = await firestore.collection(customerShippingAddressDbString).doc(shopOrderData.customer.DisplayName).get();
          const shippingAddresses = shippingAddressesDoc.data()?.shippingAddresses;
          if (!shippingAddressesDoc.exists || !shippingAddresses || shippingAddresses.filter((i: IOrderItem) => i).length === 0) {
            setCurrentCustomerShippingAddresses([null]);
          } else {
            setCurrentCustomerShippingAddresses(shippingAddresses as IShippingAddress[]);
            devLog('useDependencies', 374, `shipping addresses for ${shopOrderData.customer.DisplayName} loaded`);
          }

          const orderShipmentsDoc = await firestore.collection(orderShipmentsDbString).where('orderId', '==', currentOrderId).get();
          const orderShipments = orderShipmentsDoc.docs.map((d) => d.data() as IShipment);
          setOrderShipments(orderShipments);
        } else {
          devLog('useDependencies', 376, 'No order data found, exiting dependency loader');
          setOrderItems([null]);
        }
      },
      test: () => orderItems && !!orderItems.length,
    },
    shippingAddresses: {
      fn: async () => {
        // @ts-ignore
        const { customer } = qs.parse(urlQueryString(window.location.href)) as string;
        const shippingAddressesDoc = await firestore.collection(customerShippingAddressDbString).doc(customer).get();
        const shippingAddresses = shippingAddressesDoc.data()?.shippingAddresses;
        if (!shippingAddressesDoc.exists || !shippingAddresses || shippingAddresses.filter((i: IOrderItem) => i).length === 0) {
          setCurrentCustomerShippingAddresses([null]);
        } else {
          setCurrentCustomerShippingAddresses(shippingAddresses as IShippingAddress[]);
          devLog('useDependencies', 338, `shipping addresses for ${customer} loaded`);
        }
      },
      test: () => !!currentCustomerShippingAddresses.length,
    },
    qualityAssuranceTickets: {
      fn: async () => {
        const qaTicketDocs = await firestore.collection(qaTicketsDbCollection).get();
        const qaTicketData = qaTicketDocs.docs.map((d) => d.data() as IQATicket);
        setQaTickets(qaTicketData);
      },
      test: () => qaTickets.length > 0,
    },
    vendors: {
      fn: async () => {
        firestore.collection(vendorsDbString).get().then((snapshot) => {
          const data: IVendor[] = [];
          snapshot.forEach((v) => {
            data.push(v.data() as IVendor);
          });
          setVendors(data);
        });
      },
      test: () => vendors.length > 0,
    },
  };

  // @ts-ignore
  const depsReady = () => _.every(dependencyList.map((d: string) => dbDeps[d].test()));

  // @ts-ignore
  useEffect(() => {
    if (!currentUser) return;

    dependencyList.forEach((d: string) => {
      // @ts-ignore
      dbDeps[d].fn();
    });
  }, [useTestData]);

  return depsReady;
};
