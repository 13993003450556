import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { every, find, sortBy } from 'lodash';
import { inventoryPartToBom } from 'shared/util';
import { useRecoilState, useRecoilValue } from 'recoil';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { partBomItemsAtom } from 'shared/state/partViewState';
import { Select } from 'antd';
import { FlexRow } from '../../../../shared/containers/FlexContainer';
import { IInventoryPart } from '../../../../shared/types/dbRecords';

const SearchSelect = styled(Select)`
  width: 130px;
  margin-bottom: 0;
`;
interface IComponent {
  open: boolean;
  partNumber: string;
  changeCallback: (e: any) => void;
}
interface IValueOption {
  label?: string;
  keycode: string;
  shade: boolean;
}
const PartDetailDropdownItemRow = styled(FlexRow)`
  width: 600px;
  height: 100%;
  justify-content: flex-start;
  padding: 0 12px;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.03)' : 'transparent')};
  border-bottom: 1px solid lightgrey;
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      font-weight: bold;
      width: 90px;
    }
  }
  & span {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;
const BomItemSearchSelect = ({
  changeCallback, open, partNumber,
}: IComponent) => {
  const [currentOptions, setCurrentOptions] = useState<{ label: Element, value: string}[]>([]);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const [currentPartNumber, setCurrentPartNumber] = useState(partNumber);

  const onChangeSearch = (inventoryId: any) => {
    const id = typeof (inventoryId) === 'string' ? inventoryId : inventoryId.target.value;
    if (changeCallback) changeCallback(id);
  };
  const onSelect = (inventoryId: string, option: IValueOption) => {
    // inventoryId is a mashup of partNumber, description, and productCode
    // the first seven characters are the partNumber.
    const _partNumber = inventoryId.slice(0, 7);
    setCurrentPartNumber(_partNumber);
    if (changeCallback) changeCallback(inventoryId);
  };
  const PartDetailDropdownMenuItem = ({ keycode, shade, label = undefined }: IValueOption) => (
    <PartDetailDropdownItemRow key={`${keycode}-value-option`} id={`${keycode}-value-option`} shade={shade}>
      <p>{keycode}</p>
      <span />
      <p>{label}</p>
    </PartDetailDropdownItemRow>
  );
  useEffect(() => {
    const items = sortBy(inventoryItems, (i: IInventoryPart) => i.Sku)
      .filter((i: IInventoryPart) => !i.Parent)
      .map((i: IInventoryPart, index) => ({
        label: <PartDetailDropdownMenuItem label={i.Description} keycode={i.Sku} shade={index % 2 === 1} />,
        value: `${i.Sku}${i.Description}${i.ProductCode?.productCode || ''}`,
      }));
    setCurrentOptions(items);
  }, []);
  return (
    // @ts-ignore
    <SearchSelect
      showSearch
      dropdownStyle={{ width: 600, minWidth: 600 }}
      // @ts-ignore
      onChange={onChangeSearch}
      onBlur={onChangeSearch}
      disabled={!open}
      id="part-bom-search-dropdown"
      placeholder="Search for a part to add to the BOM"
      // @ts-ignore
      onSelect={onSelect}
      options={currentOptions}
      value={currentPartNumber}
      filterOption={(input, option) => {
        const terms = input.split(',').map((s) => s.trim());
        const matchString = option?.value;
        const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      }}
      filterSort={(optionA, optionB) =>
        (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}
    />
  );
};

export default BomItemSearchSelect;
