import { includes } from 'lodash';
import qs from 'qs';
import { IInventoryPart } from '../types/dbRecords';
import { stepForPart } from '../router/utils';

const nodemailer = require('nodemailer');

export const MIN_WEIGHT = 3.75;
export const MAX_WEIGHT = 5;

export const MIN_VOL = 1.1;
export const MAX_VOL = 2.1;

export const DEFAULT_SPREAD_WIDTH = 14;
export const DEFAULT_SPREAD_LENGTH = 19;
export const DEFAULT_SPREAD_THICK = 1.94;

export const allWeights = (minWeight: number = MIN_WEIGHT, maxWeight: number = MAX_WEIGHT) => Array.from(
  { length: ((maxWeight - minWeight) * 16) + 1 },
  (_, i) => i,
)
  .map((i) => i / 16)
  .map((i) => minWeight + i);

export const allVolumes = Array.from(
  { length: ((MAX_VOL - MIN_VOL) * 100) + 1 },
  (_, i) => i,
)
  .map((i) => i / 100)
  .map((i) => Math.round((i + MIN_VOL) * 100) / 100);

export const weightsForVolumes = allVolumes.map((v) => ({
  volume: v,
  densities: allWeights().map((w) => ({ weight: w, density: Math.round((w / v) * 100) / 100 })),
}));

export const poundWeight = (weight: number) => Math.floor(weight);

export const ounceWeight = (weight: number) => (weight - poundWeight(weight)) * 16;

export const isMobile = () => /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const partNumberWithZeroes = (partNumber: string): string => partNumber.padStart(5, '0');

export const TRANSPORTER = nodemailer.createTransport({
  host: 'smtp.office365.com',
  port: 587,
  secure: false,
  auth: {
    user: process.env.SMTP_EMAIL,
    pass: process.env.SMTP_PASS,
  },
  tls: {
    ciphers: 'SSLv3',
  },
});

export const bufferToData = (buffer, title, toEmail = null, contentType = null) => ({
  from: '"Wildwood Manufacturing" <noreply@wildwoodmfg.com>',
  sender: '"Wildwood Manufacturing" <noreply@wildwoodmfg.com>',
  to: toEmail,
  subject: title,
  text: '',
  html: '',
  attachments: [
    {
      filename: title,
      content: buffer,
      contentType: contentType || '*/*',
    },
  ],
});

// export const sortList = (list: any[], sortKeys: any[]): [] => sortKeys.reduce((acc, key) => _.sortBy(acc, key), list);

export const sortList = (list: any[], sortKeys: string[]) => {
  if (sortKeys.length === 0) return list;
  return sortList(_.sortBy(list, (r: any) => r[sortKeys[0]]), sortKeys.slice(1));
};

export const inventoryPartToBom = (part: IInventoryPart) => {
  const steps = {
    body: {
      // body steps
      100: 'd9RR4FS3O',
      110: 't0FS3ARf0',
      120: 't0FS3ARf0',
      121: 't0FS3ARf0',
      122: 't0FS3ARf0',
      123: 'DsGT5gQg-dn',
    },
    neck: {
      // neck steps
      140: 'w6onwpgNRxd',
      110: 'Q9ANbIKcC',
      150: 'Q9ANbIKcC',
      151: 'Q9ANbIKcC',
      152: 'Q9ANbIKcC',
      122: '8itJFuQJVT0',
      153: '8itJFuQJVT0',
      154: '8itJFuQJVT0',
      155: 'ejhdPLv2l',
      156: 'rAkvCIHRH6i',
      157: 'aaKxatWdehe',
      159: '8itJFuQJVT0',
    },
  };
  const partType = includes(['GN', 'BN'], part.Description.slice(0, 2)) ? 'neck' : 'body';
  // @ts-ignore
  const stepId = steps[partType][part.partNumber.slice(0, 3)];
  const bomItem = {
    description: i.Description,
    isPurchased: true,
    lastModDate: i.MetaData.LastUpdatedTime,
    partNumber: i.Sku,
    quantity: 1,
    quantityConsumed: 0,
    stepId: stepForPart(i.Sku, partType),
    totalCost: i.PurchaseCost,
    totalPrice: i.UnitPrice,
    totalQuantity: 1,
    uniqueID: i.Id,
    unit: i.PurchasingUnit,
    unitCost: i.PurchaseCost,
    unitPrice: i.UnitPrice,
    vendor: i.Vendor.vendorCode,
  };

  return bomItem;
};

export const stageRedirect = (href: string|null = null) => {
  const redirectStorage = sessionStorage.getItem('redirectUrl') ?? '';
  const redirectUrl = href || `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const redirectStack = [encodeURIComponent(redirectUrl), ...redirectStorage.split('|')];
  sessionStorage.setItem('redirectUrl', redirectStack.join('|'));
};

export const cleanCurrentUrl = () => `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
export const clearRedirect = () => {
  sessionStorage.removeItem('redirectUrl');
};
export const popRedirect = () => {
  const redirectStorage = sessionStorage.getItem('redirectUrl') ?? '';
  const redirectStack = redirectStorage.split('|');
  if (!redirectStack.length) {
    sessionStorage.removeItem('redirectUrl');
    return null;
  }
  sessionStorage.setItem('redirectUrl', redirectStack.slice(1).join('|'));
  return decodeURIComponent(redirectStack[0]);
};
export const redirect = () => {
  const redirectStorage = sessionStorage.getItem('redirectUrl') ?? '';
  const redirectStack = redirectStorage.split('|').filter((s: string) => s.length > 0);

  if (redirectStack.length) {
    const redirectUrl = redirectStack[0];

    sessionStorage.setItem('redirectUrl', redirectStack.slice(1).join('|'));
    if (decodeURIComponent(redirectUrl) === window.location.href) {
      redirect();
    } else {
      window.location.href = decodeURIComponent(redirectUrl);
    }
  } else if (window.location.href.match(/orderId/)) {
    const { orderId } = qs.parse(window.location.href);
    const orderViewType = localStorage.getItem('salesOrderViewType') || 'week';
    window.location.href = `/schedule/${orderViewType}#${orderId}`;
  } else if (window.location.href.match(/partId/)) {
    const { partId } = qs.parse(window.location.href);
    window.location.href = `/pricing?partId=${partId}`;
  } else {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  }
};

export const urlQueryString = (url: string) => new URL(url).search.substring(1);
export const isProduction = () => !window.location.origin.match(/staging|local|127/);

export const nullOp = () => {};

export const listFilterQuery = (query: string) => query
  .replace(/\bor\b/g, '|')
  // .replace(/\bnot\b/g, '^')
  .split(',')
  .map((s) => {
    if (s.match(/not\b/)) {
      return `${s.replace(/\bnot\b/, '^(')})`.replace(/\s/g, '');
    }
    return s.replace(/\s/g, '');
  });

export const partTypeFromDesc = (description: string) => {
  if (includes(['GB', 'BB'], description.substring(0, 2))) return 'body';
  if (includes(['GN', 'BN'], description.substring(0, 2))) return 'neck';
  return 'other';
};
