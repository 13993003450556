import React, { useEffect, useState } from 'react';

import { Radio } from 'antd';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';

interface IComponent {
  ihs: boolean;
  callback: (ihs: boolean) => void;
}

const PartHouseSample = ({ ihs, callback }: IComponent) => {
  const [makeIhs, setMakeIhs] = useState<boolean>(ihs);
  // no-op
  const onChange = (e: any) => {
    const value = e.target.value;
    setMakeIhs(value);
    callback(value);
  };

  useEffect(() => {
    setMakeIhs(ihs);
  }, [ihs]);

  return (
    <ItemDetailsColumnWrapper key="order-topped-or-bound-details-wrapper" width="164px">
      <ItemDetailsLabel key="order-topped-or-bound-details-label">House sample?</ItemDetailsLabel>
      <Radio.Group
        buttonStyle="solid"
        value={makeIhs}
        onChange={onChange}
      >
        <Radio.Button value={false}>No</Radio.Button>
        <Radio.Button value>Yes</Radio.Button>
      </Radio.Group>
    </ItemDetailsColumnWrapper>
  );
};

export default PartHouseSample;
