import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IUnitOption } from 'shared/types/pricingTool';
import { PartDetailColumn as ComponentWrapper } from 'shared/styledComponents/containers';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import {
  bodyDataAtom, neckDataAtom, newBodyAtom, newNeckAtom,
} from 'shared/state/pricingState';
import {
  ArchetypeMenu, DropDownSection, PartRateOptions, PartUnitOptions, WoodGroupMenu,
} from './index';
import PartMiscellaneousAdjustments from './PartPricingInputs/PartMiscellaneousAdjustments';
import BodyModificationMenu from './PartPricingInputs/BodyModificationMenu';

const ComponentInnerWrapper = styled.div`
  width: 90%;
  height: auto;
`;

interface IComponent {
  partType: 'body'|'neck';
}

const PartPricingColumn = ({ partType }: IComponent) => {
  const newPartAtom = partType === 'body' ? newBodyAtom : newNeckAtom;
  // @ts-ignore
  const [newPartState, setNewPartState] = useRecoilState(newPartAtom);
  const dataAtom = partType === 'body' ? bodyDataAtom : neckDataAtom;
  const partData = useRecoilValue(dataAtom);

  // @ts-ignore
  const coreWood = partType === 'body' ? partData.bodyWood : partData.neckWood;
  // @ts-ignore
  const coreState = partType === 'body' ? newPartState.bodyWood : newPartState.neckWood;
  // @ts-ignore
  const topWood = partType === 'body' ? partData.topWood : partData.fretboardWood;
  // @ts-ignore
  const topState = partType === 'body' ? newPartState.topWood : newPartState.fretboardWood;

  return (
    <ComponentWrapper>
      <PartDetailColumnHeader>Part Pricing</PartDetailColumnHeader>
      <ComponentInnerWrapper>
        <DropDownSection
          key="archetype-selection-dropdown"
          MenuNode={ArchetypeMenu({ partType, prompt: `Select a ${partType} type` })}
          title={`${partType === 'body' ? 'Body' : 'Neck'} BOL`}
          label={newPartState.archetype.type}
          price={newPartState.archetype.price}
        />

        <PartMiscellaneousAdjustments key="part-misc-adjustment-field" partType={partType} />
        <DropDownSection
          MenuNode={(
            <WoodGroupMenu
              partType={partType}
              woodCollection={coreWood}
              key="core-wood-dropdown"
              // @ts-ignore
              woodType={`${partType}Wood`}
              prompt="Select a core wood"
            />
          )}
          title={`${partType === 'body' ? 'Body' : 'Neck'} Wood`}
          label={coreWood.label}
          price={
          newPartState.archetype.instrument === 'S' && coreState.price[newPartState.archetype.instrument] === 0
            ? coreState.price.M : coreState.price[newPartState.archetype.instrument]
         }
        />

        {partType === 'body' && (
          <DropDownSection
            key="blank-modifier-dropdown"
            MenuNode={BodyModificationMenu()}
            title="Blank Modifier"
            label={newPartState.blankModification?.name}
            price={newPartState.blankModification?.price}
          />
        )}

        <DropDownSection
          MenuNode={(
            <WoodGroupMenu
              partType={partType}
              woodCollection={topWood}
              key="topWood-selection-dropdown"
              // @ts-ignore
              woodType={`${partType === 'body' ? 'top' : 'fretboard'}Wood`}
              prompt={`Select a ${partType === 'body' ? 'top' : 'fretboard'} wood`}
            />
          )}
          title="Top Wood"
          label={topWood.label}
          // @ts-ignore
          price={topState.price[newPartState.archetype.instrument]}
        />

        {partType === 'body' && (
        <PartUnitOptions
          key="weight-reduction-options-dropdown"
          listTitle="Weight Reduction Options"
          // @ts-ignore
          optionMasterList={_.sortBy(partData.options.weightReductionOptions.items, (o: IUnitOption) => o.name)}
          // @ts-ignore
          recordOptionSet={_.sortBy(newPartState.options.weightReductionOptions, (o: IUnitOption) => o.name)}
          partType={partType}
        />
        )}
        {partType === 'neck' && (
          <>
            <PartUnitOptions
              key="truss-rod-options-group"
              listTitle="Truss Rod Options"
          // @ts-ignore
              optionMasterList={_.sortBy(partData.options.trussRodOptions.items, (o: IUnitOption) => o.name)}
          // @ts-ignore
              recordOptionSet={_.sortBy(newPartState.options.trussRodOptions, (o: IUnitOption) => o.name)}
              partType={partType}
            />
            <PartUnitOptions
              key="fretting-options-group"
              listTitle="Fretting Options"
          // @ts-ignore
              optionMasterList={_.sortBy(partData.options.frettingOptions.items, (o: IUnitOption) => o.name)}
          // @ts-ignore
              recordOptionSet={_.sortBy(newPartState.options.frettingOptions, (o: IUnitOption) => o.name)}
              partType={partType}
            />
            <PartUnitOptions
              listTitle="Inlay Options"
              key="inlay-options-group"
          // @ts-ignore
              optionMasterList={_.sortBy(partData.options.inlayOptions.items, (o: IUnitOption) => o.name)}
          // @ts-ignore
              recordOptionSet={_.sortBy(newPartState.options.inlayOptions, (o: IUnitOption) => o.name)}
              partType={partType}
            />
            <PartUnitOptions
              listTitle="Dot Options"
              key="dot-inlay-options-group"
          // @ts-ignore
              optionMasterList={_.sortBy(partData.options.dotOptions.items, (o: IUnitOption) => o.name)}
          // @ts-ignore
              recordOptionSet={_.sortBy(newPartState.options.dotOptions, (o: IUnitOption) => o.name)}
              partType={partType}
            />
          </>
        )}
        <PartUnitOptions
          listTitle="Accessory Options"
          key="accessory-options-group"
          // @ts-ignore
          optionMasterList={_.sortBy(partData.options.accessoryOptions.items, (o: IUnitOption) => o.name)}
          recordOptionSet={_.sortBy(newPartState.options.accessoryOptions, (o: IUnitOption) => o.name)}
          partType={partType}
        />
        <PartUnitOptions
          listTitle="Construction Options"
          key="construction-options-group"
          // @ts-ignore
          optionMasterList={_.sortBy(partData.options.constructionOptions.items, (o: IUnitOption) => o.name)}
          recordOptionSet={_.sortBy(newPartState.options.constructionOptions, (o: IUnitOption) => o.name)}
          partType={partType}
        />
        <PartUnitOptions
          listTitle="Finishing Options"
          key="finishing-options-group"
          // @ts-ignore
          optionMasterList={_.sortBy(partData.options.finishingOptions.items, (o: IUnitOption) => o.name)}
          recordOptionSet={_.sortBy(newPartState.options.finishingOptions, (o: IUnitOption) => o.name)}
          partType={partType}
        />
        <PartRateOptions
          listTitle="Labor Options"
          key="labor-options-group"
          // @ts-ignore
          optionMasterList={_.sortBy(partData.options.laborOptions.items, (o: IUnitOption) => o.name)}
          recordOptionSet={_.sortBy(newPartState.options.laborOptions, (o: IUnitOption) => o.name)}
          partType={partType}
        />
      </ComponentInnerWrapper>
    </ComponentWrapper>
  );
};

export default PartPricingColumn;
