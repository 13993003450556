import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { inventoryPartEditedAtom } from 'shared/state/inventoryState';
import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeSelect,
  ViewTypeSelectOption,
  ViewTypeRadioButton, ItemDetailsLabel, ItemDetailsColumnWrapper,
} from 'shared/styledComponents/inputs';
import { FlexRow } from 'shared/containers/FlexContainer';
import SimpleModal from 'shared/components/Utility/SimpleModal';
import { noop } from 'lodash';
import HelpButton from '../../../../shared/components/Utility/HelpButton';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

interface IComponent {
  disabled: boolean;
  partId: string;
  childSku: string | null;
  callback: (ineherits: boolean) => void;
}
const PartInheritanceSelector = ({
  disabled, partId, childSku, callback,
}: IComponent) => {
  const [_childSku, setChildSku] = useState<string|null>(childSku);

  const onChangeView = (e: any) => {
    const value = (e.target?.value) || e;
    callback(value === 'true');
  };

  useEffect(() => {
    setChildSku(childSku);
  }, [childSku]);

  return (
    <ItemDetailsColumnWrapper key={`${partId}-active-selector-wrapper`}>
      <LabelWrapper>
        <ItemDetailsLabel key={`${partId}-active-selector-label`}>Child Part?</ItemDetailsLabel>
        <HelpButton helpMessage={"Select 'Yes' if this part is on, or being added to, a spec sheet that has another part already in existence."} style={{ marginBottom: 4 }} />
      </LabelWrapper>
      <NoLeftMarginViewTypeRadioGroup
        value={_childSku !== null ? 'true' : 'false'}
        buttonStyle="solid"
        onChange={onChangeView}
        disabled={disabled}
      >
        <ViewTypeRadioButton
          value="false"
        >
          No
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="true"
        >
          Yes
        </ViewTypeRadioButton>
      </NoLeftMarginViewTypeRadioGroup>
    </ItemDetailsColumnWrapper>
  );
};

export default PartInheritanceSelector;
