import axios from 'axios';
import {
  find, first, includes, omit,
} from 'lodash';
import { IQBOItem } from 'shared/types/qbo';
import { IInventoryPart } from 'shared/types/dbRecords';
import { devLog } from 'shared/util/logging';
import { qboDateString } from 'shared/data/calendar';
import QBOAuth from 'shared/data/QBO/auth';
import API_BASE_URL from './index';
import { cleanMeta } from '../../text';

// eslint-disable-next-line import/prefer-default-export
const fetchMany = (partList: string[]): Promise<IQBOItem[]> => {
  const partListString = partList.map((part: string) => `'${part}'`).join(',');
  return new Promise((resolve, reject) => axios.get(`${API_BASE_URL}/item/fetchMany?parts=${partListString}`)
    .then((response) => {
      const data = response.data.QueryResponse.Item;
      resolve(data);
    })
    .catch((e) => {
      console.log(e);
      reject(e);
    }));
};

const fetchByName = async (partName: string): Promise<IQBOItem | null> => {
  try {
    const data = await axios.get(`${API_BASE_URL}/item/fetchByName?partName=${partName}`) as { data: { QueryResponse: { Item: IQBOItem[] }}};
    return (data.data.QueryResponse.Item[0]);
  } catch (e) {
    return null;
  }
  // .then((response) => {
  //   const data = first(response.data.QueryResponse.Item);
  //   resolve(data);
  // })
  // .catch((e) => {
  //   console.log(e);
  //   resolve(e):
  // })
};

const fetchAllInventoryParts = async (): Promise<IQBOItem[] | null> => {
  try {
    const data = await axios.get(`${API_BASE_URL}/item/fetchAll/inventory`) as { data: { QueryResponse: { Item: IQBOItem[] }}};
    return (data.data.QueryResponse.Item);
  } catch (e) {
    return null;
  }
};
const create = async (itemData: any, retry = true): Promise<IQBOItem> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/item/create`, itemData);
    return response.data.json.Item as IQBOItem;
  } catch (e) {
    console.log(e);
    if (retry) return create(itemData, false);
    throw e;
  }
};

const update = async (itemData: IQBOItem, retry = true): Promise<IQBOItem|null> => {
  try {
    const fetchUrl = itemData.Id ? `${API_BASE_URL}/item/fetchById?Id=${itemData.Id}` : `${API_BASE_URL}/item/fetchByName?partName=${itemData.Name}`;
    const response = await axios.get(fetchUrl);
    if (!response) throw new Error('Part was not fetched -- error returned from QBO');

    const data = itemData.Id ? response.data.Item : find(response.data.QueryResponse.Item, (i) => i.Name === itemData.Name);
    if (!data) throw new Error(`No item found with part number ${itemData.Name}`);
    const updateRecord = { ...data };
    // always use the SyncToken from QBO
    Object.keys(itemData).filter((k) => !includes(['Id', 'SyncToken'], k))
      .forEach((k: string) => {
        // @ts-ignore - both objects are IQBOItem objects
        updateRecord[k] = itemData[k];
      });
    const postResponse = await axios.post(`${API_BASE_URL}/item/update`, updateRecord);
    return postResponse.data;
  } catch (e) {
    devLog('data/QBO/item', 62, itemData);
    devLog('data/QBO/item', 63, e);
    if (e.toString().match('No item found') || e.response.status === 404) return create(itemData);
    if (retry) return update(itemData, false);
    return null;
  }
};

interface IProcessedInventoryItem {
  Sku: string;
  success: boolean;
}
const updateBulk = async (database: any, items: IQBOItem[], processed: IProcessedInventoryItem[] = []): Promise<IProcessedInventoryItem[]> => {
  if (items.length === 0) {
    console.log(processed);
    return processed;
  }
  const item = items[0];
  let success = false;
  try {
    // await QBOAuth.refreshToken(database);
    await update(item);
    success = true;
  } catch (error) {
    console.error(`Error running async function for item ${item}: ${error}`);
  }

  return updateBulk(database, items.slice(1), [...processed, { Sku: item.Sku, success }]);
};

const fromFinishedGood = async (part: any): Promise<IQBOItem> => {
  // possible that qboPart will be undefined—if we are creating a new part, for example
  const qboPart = await fetchByName(part.Sku);
  const dateStamp = qboDateString(new Date(), true);
  const MetaData = { ...(qboPart?.MetaData || {}), LastUpdatedTime: dateStamp };
  if (!MetaData.CreateTime) {
    MetaData.CreateTime = dateStamp;
  }
  const Active = part.active === 'Y' ? true : (part.active === 'N' ? false : part.active);
  const item = {
    ...qboPart || {},
    Sku: part.Sku,
    Name: part.Sku,
    FullyQualifiedName: part.Sku,
    Description: cleanMeta(part.Description),
    Active,
    UnitPrice: part.price,
    MetaData,
    TrackQtyOnHand: false,
    AssetAccountRef: {
      name: 'Work in Process',
      value: '99',
    },
    ExpenseAccountRef: {
      name: 'COGS- Raw Materials',
      value: '82',
    },
    IncomeAccountRef: {
      value: '21',
      name: 'Sales Income',
    },
    Taxable: true,
    TaxClassificationRef: {
      value: 'EUC-09020802-V1-00120000',
      name: 'General taxable retail products - use this if nothing else fits',
    },
    Type: 'NonInventory',
    domain: 'QBO',
  };
  devLog('QBO/item/fromPart', 94, item);
  return item as IQBOItem;
};
const fromPart = (part: IInventoryPart, inventoryDate: Date = new Date()) => {
  const dateStamp = qboDateString(new Date(), true);
  const type = partType(part.ProductCode?.productCode);
  const omitKeys = [
    'criticalPart',
    'defaultAllocationQuantity',
    'notes',
    'routerStep',
    'AllocatedQuantity',
    'InvStartDate',
    'Vendor',
    'Children',
    'ConsumptionStep',
    'ProductCode',
    'PurchasingUnit',
    'Parent',
    'ParentRef',
    'purchasingGLCode',
    'reorderQty',
    // QBO Keys that I want to generate per transaction
    'PurchaseCost',
    'PurchaseDesc',
    'ExpenseAccountRef',
    'AssetAccountRef',
    'IncomeAccountRef',
    'TaxClassificationRef',
    'InvStartDate',
    'ParentRef',
    'SubItem',
  ];
  const item = omit({
    ...part,
    Description: cleanMeta(part.Description),
    Name: part.Sku,
    Type: type,
    // if it's a Parent, set the quantity on hand to zero, otherwise use quantity on hand
    QtyOnHand: (type === 'NonInventory' || part.Children) ? 0 : part.QtyOnHand,
    MetaData: { CreateTime: dateStamp, LastUpdatedTime: dateStamp },
    TrackQtyOnHand: type === 'Inventory',
    Taxable: !!part.ProductCode.IncomeAccountRef,
    // if this item has subitems, do not count its quantity on hand value—it's meant for allocation only.
  }, omitKeys);

  // if the item's product code has an income account, we *may* sell it—mark it taxable with a generic tax classification
  if (part.ProductCode.IncomeAccountRef) {
    item.IncomeAccountRef = omit(part.ProductCode.IncomeAccountRef, 'accountNumber');
    item.Taxable = true;
    item.TaxClassificationRef = {
      value: type === 'Service' ? 'EUC-99990202-V1-00020000' : 'EUC-09020802-V1-00120000',
      name: type === 'Service' ? 'Service marked taxable by its seller where seller accepts full responsibility'
        : 'General taxable retail products - use this if nothing else fits',
    };
  }

  if (part.ProductCode.ExpenseAccountRef) {
    item.PurchaseCost = part.PurchaseCost;
    item.PurchaseDesc = part.Description;
    item.ExpenseAccountRef = omit(part.ProductCode.ExpenseAccountRef, 'accountNumber');
  }

  if (part.ProductCode.AssetAccountRef) {
    item.AssetAccountRef = omit(part.ProductCode.AssetAccountRef, 'accountNumber');
  }

  if (type === 'Inventory') {
    inventoryDate.setDate(1); // always post new inventory dates to the first of the month defined in the inventory detail screen
    item.InvStartDate = qboDateString(inventoryDate);
  } else {
    item.TrackQtyOnHand = false;
  }

  /*
    For the time being, we are going to stop relating products in Quickbooks—there seems to be some interplay
    between pricing, quantities, etc, when creating new sub-items from HELM. We will keep sub-items in HELM,
    but when forwarding the part to QBO, we will just create them as stand-alone items, with their own pricing.
   */
  // if (part.Parent) {
  //   item.ParentRef = { value: part.Parent.Id, name: part.Parent.Sku };
  //   item.SubItem = true;
  // }

  devLog('QBO/item/fromPart', 125, item);
  return item as IQBOItem;
};

const partType = (partCategory: string) => {
  if (includes(['ITEMMODIFIER', 'SERVICE', 'DISCOUNT', 'SHIPCHARGES'], partCategory)) return 'Service';
  if (includes(['BODYBLANK', 'BODYTOP', 'LUMBER', 'NECKPARTS', 'NECKWOOD', 'SHIPMATLS'], partCategory)) return 'Inventory';
  return 'NonInventory';
};

export default {
  fetchMany,
  fetchByName,
  fetchAllInventoryParts,
  create,
  update,
  updateBulk,
  // helper methods
  fromFinishedGood,
  fromPart,
  partType,
};
