import { atom } from 'recoil';

export const MESSAGE_DB_PATH_ATOM = atom<string>({
  key: 'MESSAGE_DB_STRING_ATOM',
  default: 'mail',
});
export const messageDisplayTypeAtom = atom<'active'|'archive'>({
  key: 'messageDisplayTypeAtom',
  default: 'active',
});

export const messageSearchQueryAtom = atom<string>({
  key: 'messageSearchQueryAtom',
  default: sessionStorage.getItem('message.query.string') || '',
});

export const showMessageCenterAtom = atom<boolean>({
  key: 'showMessageCenterAtom',
  default: false,
});

export const showSettingsCenterAtom = atom<boolean>({
  key: 'showSettingsCenterAtom',
  default: false,
});

export const nonConformanceNotificationUsersAtom = atom<string[]>({
  key: 'nonConformanceNotificationUsers',
  default: ['keithh', 'darrans'],
});

export const orderConfirmationNotificationUsersAtom = atom<string[]>({
  key: 'orderConfirmationNotificationUsers',
  default: ['keithh', 'christineh', 'bruceh'],
});

export const costUpdateNotificationUsersAtom = atom<string[]>({
  key: 'costUpdateNotificationUsersAtom',
  default: ['bruceh'],
});

export const reorderPointNotificationUsersAtom = atom<string[]>({
  key: 'reorderPointNotificationUsersAtom',
  default: ['keithh', 'edwardl'],
});
