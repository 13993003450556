import React, { useEffect, useState } from 'react';
import { find, findIndex, sortBy } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Popover } from 'antd';
import styled from 'styled-components';
import { Info } from '@styled-icons/material';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import inventoryPartToBomItem from 'shared/data/typeConversion';
import { partEditModeAtom } from 'shared/state/pricingState';
import { bomEditedAtom, partBomItemsAtom } from 'shared/state/partViewState';
import { IBomItem, IInventoryPart } from 'shared/types/dbRecords';
import PartDetailInput from 'shared/components/Input/PartDetailInput';
import DeleteBomItemButton from 'pages/PartDetail/Components/Buttons/DeleteBomItemButton';
import BomDetailPopover from './BomDetailPopover';
import BomItemSearchSelect from './BomItemSearchSelect';
import EditBomButton from '../Buttons/EditBomButton';

interface IComponent {
  partType: 'body'|'neck';
  open: boolean;
  bomItem: IBomItem;
  changeCallback: (newBomItem: IBomItem, oldSku: string) => void;
}

const QuantityInput = styled(PartDetailInput)`
  margin-bottom: 0 !important;
`;

const BomItemWrapper = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  border-bottom: 1px solid lightgrey;
  border: none;
  cursor: default;
`;
const BomDetailsWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;
const BomItemDetailsRow = styled(FlexRow)`
  width: calc(100% - 16px);
  justify-content: flex-start;
  padding-right: 16px;
  gap: 8px;
`;
const BomItemText = styled.p`
  //font-size: 12px;
  margin: 0;
`;
const BomItemNumber = styled(BomItemText)`
  width: 80px;
  font-weight: bold;
`;
const BomItemDescription = styled(BomItemText)`
  text-align: left;
  width: 75%;
`;
const BomItemQuantity = styled(BomItemText)`
  justify-self: flex-end;
  margin: 0 0 0 auto;
  min-width: 16px;
`;
const InfoIcon = styled(Info)`
  cursor: pointer;
  width: 32px;
  color: ${theme.palette.primary.hue};
`;

const BomItem = ({
  open, bomItem, partType, changeCallback,
}: IComponent) => {
  const partEditMode = useRecoilValue(partEditModeAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const [_bomItem, setBomItem] = useState<IBomItem>(bomItem);
  const [quantity, setQuantity] = useState(bomItem.quantity);

  const onChangeQuantity = (e: any) => {
    setQuantity(e.target.value);
  };
  const onBlurQuantity = (e: any) => {
    const newQuantity = parseFloat(e.target.value);
    const newBomItem = { ..._bomItem, quantity: newQuantity };
    setQuantity(newQuantity);
    changeCallback(newBomItem, _bomItem.Sku);
  };
  const onItemNumberChange = (value: string) => {
    const _partNumber = value.slice(0, 7);
    const item = find(inventoryItems, (i: IInventoryPart) => i.Sku === _partNumber);
    if (!item) return;
    const newBomItem = inventoryPartToBomItem(item, partType);
    // setBomItem(newBomItem);
    changeCallback(newBomItem, _bomItem.Sku);
  };

  useEffect(() => {
    setBomItem(bomItem);
  }, [bomItem]);

  return (
    <BomItemWrapper>
      {!partEditMode ? (
        <BomDetailsWrapper>
          <BomItemDetailsRow>
            <BomItemNumber>{_bomItem.Sku}</BomItemNumber>
            <BomItemDescription>{_bomItem.Description}</BomItemDescription>
            <BomItemQuantity>{`${_bomItem.quantity} ${_bomItem.unit || ' EA'}`}</BomItemQuantity>
          </BomItemDetailsRow>
          <Popover
            title="Vendor/Cost Detail"
            placement="left"
            content={<BomDetailPopover bomItem={_bomItem} />}
          >
            <InfoIcon />
          </Popover>
          <EditBomButton partNumber={_bomItem.Sku} />
        </BomDetailsWrapper>
      ) : (
        <>
          <BomDetailsWrapper>
            <BomItemDetailsRow>
              <BomItemSearchSelect changeCallback={onItemNumberChange} open={open} partNumber={_bomItem.Sku} />
              <BomItemDescription>{_bomItem.Description}</BomItemDescription>
              <QuantityInput disabled={!open} blurCallback={onBlurQuantity} changeCallback={onChangeQuantity} placeholder="0" id={`${_bomItem.Sku}-quantity-input-field`} value={quantity} noMargin width="80px" />
              <BomItemQuantity>{_bomItem.unit || ' EA'}</BomItemQuantity>
            </BomItemDetailsRow>
            {open && (
              <DeleteBomItemButton bomItemId={_bomItem.uniqueID} />
            )}
          </BomDetailsWrapper>
        </>
      )}
    </BomItemWrapper>
  );
};
export default BomItem;
