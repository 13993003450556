import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { find, includes } from 'lodash';
import useFirebase from 'vendor/Firebase';
import {
  BODY_DATA_PATH,
  bodyWoodAtom, currentPartTypeAtom,
  fretboardWoodAtom, NECK_DATA_PATH,
  neckWoodAtom,
  topWoodAtom, useTestDataAtom,
} from 'shared/state/pricingState';
import { Button } from 'antd';
import { IPartWood } from 'shared/types/pricingTool';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { bodyColumns, neckColumns } from './WoodColumns';
import { SalesOrderTable } from '../../ProductionSchedule/styledComponents';
import { IInventoryPart } from '../../../shared/types/dbRecords';
import { mapInventoryData } from '../../../shared/data/pricing';
// import { mapCost } from './SyncCostButton';

interface ComponentInterface {
  woodCategory: 'bodyWood'|'topWood'|'fretboardWood'|'neckWood';
}

export default ({ woodCategory }: ComponentInterface) => {
  const { database } = useFirebase();
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const bodyDataPath = useRecoilValue(BODY_DATA_PATH);
  const neckDataPath = useRecoilValue(NECK_DATA_PATH);
  const partType = includes(['bodyWood', 'topWood'], woodCategory) ? bodyDataPath : neckDataPath;
  const categoryEditKey = partType.match(/body/i) ? 'bodyPricingPane' : 'neckPricingPane';
  const useTestData = useRecoilValue(useTestDataAtom);
  const currentPartType = useRecoilValue(currentPartTypeAtom);

  let woodTypeAtom = bodyWoodAtom;
  if (woodCategory === 'topWood') woodTypeAtom = topWoodAtom;
  if (woodCategory === 'neckWood') woodTypeAtom = neckWoodAtom;
  if (woodCategory === 'fretboardWood') woodTypeAtom = fretboardWoodAtom;
  const [listItems, setListItems] = useState<IPartWood[]>([]);

  const onNewClick = (e: any) => {
    window.location.href = `/pricing/material/edit?partType=${localStorage.getItem('pricingEditCategory')}&woodType=${localStorage.getItem(categoryEditKey)}`;
  };

  useEffect(() => {
    database.ref(`${partType}/${woodCategory}/items`).once('value', (snapshot) => {
      setListItems([]);
      // might reintroduce when we move to helm 2.0
      const data = mapInventoryData(snapshot.val(), inventoryItems.map((i: IInventoryPart) => ({
        Sku: i.Sku,
        PurchaseCost: i.PurchaseCost,
        UnitPrice: i.UnitPrice,
        Description: i.Description,
      })));
      setTimeout(() => {
        // @ts-ignore
        setListItems(Object.values(data).sort((a: IPartWood, b: IPartWood) => {
          if (a.label > b.label) return 1;
          if (a.label < b.label) return -1;
          return 0;
        }));
      }, 0);
    });
  }, [useTestData, currentPartType]);

  return (
    <>
      <Button type="primary" shape="round" onClick={onNewClick}>Add new</Button>
      <SalesOrderTable
        rowKey="recordId"
        size="small"
        // @ts-ignore
        columns={currentPartType === 'body' ? bodyColumns : neckColumns}
        dataSource={listItems}
        pagination={false}
        rowClassName="sales-order-row-bordered"
      />
    </>
  );
};
