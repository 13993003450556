import firebase from 'firebase';
import shortid from 'shortid';
import { Modal, notification } from 'antd';
import { find, includes } from 'lodash';
import {
  IBomItem, IInventoryPart, IOrderItem, IShipment, IShipmentItem,
} from 'shared/types/dbRecords';
import { firebaseShipDate } from 'shared/data/calendar';
import { devLog } from 'shared/util/logging';
import QBOInvoice from 'shared/data/QBO/invoice';
import { consumeInventoryParts } from 'shared/router/utils';
import { IRunner, IShipOrder, IShopOrder } from '../../../../Orders/types';

export const createOrUpdateQboInvoice = async (
  database: any,
  orderId: string,
  shipmentId: string,
  shipDate: Date,
  recordNumber: string,
  packingSlipNotes: string,
  shippingCost: number,
  shipOrder: IShipOrder,
  shipmentItems: IShipmentItem[],
  trackingNumber: string|null,
) => {
  // create and/or update the shipment record.
  const recordId = shipmentId || shortid.generate();
  const shipment: IShipment = {
    id: recordId,
    customer: shipOrder.customer,
    description: shipOrder.description || '',
    trackingNumber,
    purchaseOrder: shipOrder.purchaseOrder || '',
    notes: packingSlipNotes || shipOrder.notes,
    orderId,
    // @ts-ignore qboId is a valid field if this is for an update
    qboId: shipOrder.qboId || '',
    salesOrder: shipOrder.salesOrder,
    shipDate: firebase.firestore.Timestamp.fromDate(shipDate),
    shipmentNumber: recordNumber,
    shippedItems: shipmentItems,
    shippingCost: shippingCost || shipOrder.shippingCost || 0,
    value: shipmentItems.filter((i: IShipmentItem) => i.quantityShipped > 0).map((i: IShipmentItem) => i.quantityShipped * i.unitPrice).reduce((a, b) => a + b, 0),
  };

  let qboId = null;
  if (!shipmentId) {
    qboId = await QBOInvoice.create(shipment);
    // updated invoice number if relevant
    const nextInvoiceNumber = await database.ref('/recordNumbers/invoice').once('value');
    // only increment if the value fetched from the database is equivalent to the current shipment number.
    if (nextInvoiceNumber.val().toString() === shipment.shipmentNumber) await database.ref('/recordNumbers/invoice').set(parseInt(nextInvoiceNumber.val(), 10) + 1);
  } else {
    // @ts-ignore - qboId is a valid field when updating
    qboId = await QBOInvoice.update(shipment, shipOrder.qboId);
  }

  return { shipment, qboId };
};

export const updateHelmShipment = async (firestore: any, qboId: string, shipment: IShipment) => {
  devLog('shipmentFunctions', 66, { ...shipment, qboId });
  return firestore.collection('order-shipments').doc(shipment.id).set({ ...shipment, qboId });
};

export const updateOrderItems = async (firestore: any, orderId: string, shipment: IShipment) => {
  // update the order-items doc reflecting what has shipped and what hasn't.
  const orderItemsDoc = await firestore.collection('order-items').doc(orderId).get();
  const { orderItems } = orderItemsDoc.data();
  const updatedItems = orderItems.map((o: IOrderItem) => {
    const shipItem = find(shipment.shippedItems, (s: IShipmentItem) => s.id === o.id);
    if (!shipItem) return o;
    return {
      ...o,
      ...shipItem,
      bom: o.bom.map((i: IBomItem) => ({
        ...i,
        totalQuantity: shipItem.quantityOpen * i.quantity,
        quantityConsumed: shipItem.quantityOpen * i.quantity,
      })),
    };
  });
  const addedItems = shipment.shippedItems.filter((i: IShipmentItem) => !includes(orderItems.map((o: IOrderItem) => o.id), i.id));

  devLog('shipmentFunctions', 89, [...updatedItems, ...addedItems]);
  await firestore.collection('order-items').doc(orderId).update({ completed: true, orderItems: [...updatedItems, ...addedItems] });
};

// update the parts on the order runners - if all parts on the runner are shipped, mark the runner complete
export const updateSalesOrder = async (firestore: any, database: any, messageDbString: string, reorderNotificationUsers: string[], orderId: string, shipment: IShipment, inventoryItems: IInventoryPart[], progressLabelSetter) => {
  const orderDoc = await firestore.collection('orders').doc(orderId).get();
  const orderData = orderDoc.data() as IShopOrder;
  const today = new Date();
  const completedDate = firebase.firestore.Timestamp.fromDate(today);
  const shipDate = shipment.shipDate || firebase.firestore.Timestamp.fromDate(today);
  const consumedParts = {};
  const updatedRunners = orderData.runners.map((r: IRunner) => {
    const updatedParts = r.parts.map((p: IOrderItem) => {
      const bom = p.bom.filter((b: IBomItem) => b.Sku.length > 0).map((b: IBomItem) => {
        // all steps need to be consumed at this point, since we are shipping. Subtract the quantity remaining to be
        // consumed from the total quantity needed, and mark it consumed.
        const quantityConsumed = (((b.quantity || 0) * p.quantityAssigned) - (b.quantityConsumed || 0));
        if (quantityConsumed > 0) {
          if (includes(Object.keys(consumedParts), b.Sku)) {
            // @ts-ignore
            consumedParts[b.Sku] += quantityConsumed;
          } else {
            // @ts-ignore
            consumedParts[b.Sku] = quantityConsumed;
          }
        }
        return {
          ...b,
          quantityConsumed: quantityConsumed ?? 0,
        };
      });
      const shipmentItem = find(shipment.shippedItems, (s: IShipmentItem) => s.Sku === p.Sku);
      if (!shipmentItem) return p;
      return {
        ...p,
        bom,
        quantityOpen: 0,
        quantityShipped: Math.min(p.quantityAssigned, shipmentItem.quantityShipped), // can't ship more than were assigned to work order
        quantityCanceled: Math.min(p.quantityAssigned, shipmentItem.quantityCanceled), // can't cancel more than were assigned to work order
      };
    });
    return {
      ...r,
      completed: true,
      open: false,
      parts: updatedParts,
    };
  });

  devLog('shipmentFunctions', 131, updatedRunners);
  await consumeInventoryParts(
    consumedParts,
    inventoryItems,
    'inventory-items',
    firestore,
    database,
    messageDbString,
    reorderNotificationUsers,
    progressLabelSetter,
    today,
    orderData,
  );
  await firestore.collection('orders').doc(orderId).update({
    completed: true, completedDate, shipDate, runners: updatedRunners, orderValue: shipment.value,
  });
};

// update the lastSold property on anything shipped
export const updatePartLastSold = async (firestore: any, shipment: IShipment) => {
  const parts = shipment.shippedItems.filter((i: IShipmentItem) => !!i.Sku.match(/[A-Z]{5}/) && i.quantityShipped > 0).map((i: IShipmentItem) => i.Sku);
  const now = new Date();
  await Promise.all(parts.map((sku: string) => firestore.collection('part-viewer-data').doc(sku).update({ lastSold: firebase.firestore.Timestamp.fromDate(now) })));
};
export const processBackorder = async (firestore: any, orderId: string, shipmentItems: IShipmentItem[], type: 'body'|'neck', currentUser: string): Promise<[string, IShipmentItem[]]> => {
  const openItems = shipmentItems.map((i: IOrderItem) => ({
    ...i, quantityAssigned: 0, quantityOpen: (i.quantityBackordered || i.quantityOpen || 0), quantityBackordered: 0, open: (i.quantityBackordered || i.quantityOpen || 0) > 0,
  }));
  const partCount = openItems.map((i: IOrderItem) => i.quantityOpen || 0).reduce((a, b) => a + b, 0);
  const orderValue = openItems.map((i: IOrderItem) => (i.quantityOpen || 0) * i.unitPrice).reduce((a, b) => a + b, 0);
  const newId = shortid.generate();
  const oneWeekOut = new Date();
  // if this is a body order, push the backorder out one week. If neck order, two weeks
  const distance = type === 'body' ? 7 : 14;
  oneWeekOut.setDate(oneWeekOut.getDate() + distance);
  const newShipDate = firebaseShipDate(oneWeekOut);

  const shopOrderDoc = await firestore.collection('orders').doc(orderId).get();
  const currentShopOrder = shopOrderDoc.data() as IShopOrder;
  const backorderDescription = `${partCount} x ${openItems.filter((i) => i.quantityBackordered > 0).length > 1 ? 'various models' : 'one model'} - B.O.`;
  // @ts-ignore
  const newSalesOrder = {
    ...currentShopOrder,
    completed: false,
    completedDate: null,
    salesOrder: `${currentShopOrder.salesOrder.split('-')[0]}-BO`,
    partCount,
    orderValue,
    description: backorderDescription,
    dateCreated: newShipDate,
    nonConformanceHistory: null,
    releaseConfirmed: firebase.firestore.Timestamp.fromDate(new Date()),
    releaseConfirmedBy: currentUser,
    runners: null,
    shipDate: newShipDate,
    shipDateHistory: [],
    id: newId,
    recordId: newId,
    weightReduction: null,
  };

  const shippingItems = shipmentItems.map((i) => ({
    ...i,
    open: false,
    quantityAssigned: i.quantityBackordered,
    quantityOpen: 0,
    quantityBackordered: 0,
    quantityCanceled: i.quantityCanceled + i.quantityBackordered,
  }));

  await firestore.collection('orders').doc(newId).set(newSalesOrder);
  await firestore.collection('order-items').doc(newId).set({ orderItems: openItems });
  notification.open({
    message: 'Backorder created successfully!',
    description:
      'It is in the schedule one week from today.',
    placement: 'bottomRight',
    className: 'data-copy-notification',
  });

  return [newId, shippingItems];
};
