import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  find, flattenDeep, includes, sortBy,
} from 'lodash';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from '../../../../shared/state/inventoryState';
import useFirebase from '../../../../vendor/Firebase';
import { FlexColumn } from '../../../../shared/containers/FlexContainer';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from '../../../../shared/state/messageState';

const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const ConsumeMaterialsButton = () => {
  const { firestore, database } = useFirebase();
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const inventoryItemsDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const [locked, setLocked] = useState<boolean>(true);
  const [progressLabel, setProgressLabel] = useState<string>('');

  // const consumeDataItems = async (entry: [consumptionDate: string, items: { [key: string]: number }]) => {
  //   const [consumptionDate, items] = entry;
  //   const consumedOn = new Date(consumptionDate);
  //   // make sure that the date object being used is set to the date in locale timezone, not GMT.
  //   consumedOn.setHours(consumedOn.getHours() + (consumedOn.getTimezoneOffset() / 60));
  //   return consumeMaterial(items, inventoryItems, consumedOn, firestore, inventoryItemsDbString, setProgressLabel);
  // };
  const onClick = async (e: any) => {
    const adjustmentDataDoc = await database.ref('/util/adjustmentData').once('value');
    const adjustmentData = adjustmentDataDoc.val();

    const { data } = adjustmentData;
    const sortedInventoryAdjustments = sortBy(Object.keys(data), (k) => new Date(parseInt(k, 10)));
    await Promise.all(sortedInventoryAdjustments.map((k) => QBOInventoryAdjustment.create(data[k])));
    await database.ref('/util/adjustmentData').set({ lock: false });
    Modal.success({
      title: 'Complete',
      content: 'All locked inventory adjustments have been processed.',
      onOk: () => {
        setProgressLabel('');
      },
    });
  };

  useEffect(() => {
    database.ref('/util/adjustmentData').once('value').then((data) => {
      const lockedData = data.val();
      if (!lockedData) return;
      setLocked(lockedData.lock || false);
      if (Object.keys(lockedData.data || {}).length > 0) {
        const itemsToConsume = Object.keys(lockedData.data).length;
        setProgressLabel(`${itemsToConsume} item${itemsToConsume > 1 ? 's' : ''} to consume`);
      }
    });
  }, []);

  return (
    <FlexColumn align="flex-end" justify="flex-start">
      <ComponentButton type="default" onClick={onClick} disabled={locked}>Unlock Consumption & Consume Material</ComponentButton>
      {!!progressLabel.length && (
        <p>{progressLabel}</p>
      )}
    </FlexColumn>
  );
};

export default ConsumeMaterialsButton;
