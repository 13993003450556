import React, { useState, useEffect } from 'react';
import {
  Document, Font, Page, PDFViewer, StyleSheet, View, Text, Image,
} from '@react-pdf/renderer';
import { useRecoilValue } from 'recoil';
import { IShipment } from 'shared/types/dbRecords';
import {
  currentShopOrderAtom,
  orderItemsAtom, orderShipmentsAtom,
} from 'shared/state/orderState';
import useFirebase from 'vendor/Firebase';
import { OrderConfirmationItemHeader } from './Components/OrderConfirmation/OrderConfirmationItem';
import BillingShippingInfo from './Components/Invoice/BillingShippingInfo';
import InvoiceHeader from './Components/Invoice/InvoiceHeader';
import InvoiceFooter from './Components/Invoice/InvoiceFooter';
import ShipmentLogisticsDetail from './Components/Invoice/ShipmentLogisticsDetail';
import { ShipmentItem } from './Components/Invoice/ShipmentItem';
import PackingSlipNotes from './Components/PackingSlip/PackingSlipNotes';

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

const HORIZONTAL_PADDING = 18;
const VERTICAL_PADDING = 36;

const style = StyleSheet.create({
  viewer: {
    width: '75vw',
    height: '100vh',
  },
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: VERTICAL_PADDING * 1.5,
    paddingLeft: HORIZONTAL_PADDING,
    paddingRight: HORIZONTAL_PADDING * 2,
    paddingBottom: VERTICAL_PADDING,
  },
  confirmationBody: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: -4,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 24,
    left: 0,
    right: 24,
    textAlign: 'right',
    color: 'grey',
  },
  invoiceNumber: {
    position: 'absolute',
    fontSize: 20,
    top: 8,
    left: 20,
    color: 'red',
    fontFamily: 'Red Hat Display-700',
  },
  notesBuffer: {
    height: 8,
    width: '100%',
  },
  emailedStamp: {
    position: 'absolute',
    bottom: 16,
    left: 20,
    width: 126,
    height: 48,
  },
  paidStamp: {
    position: 'absolute',
    bottom: 10,
    left: '45%',
    width: 100,
    height: 55,
  },
});
interface IComponent {
  shipment: IShipment;
}

const InvoicePDF = ({ shipment }: IComponent) => {
  const orderShipments = useRecoilValue(orderShipmentsAtom);
  const [_shipment, setShipment] = useState<IShipment>({} as IShipment);
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);
  const [eastOfRockies, setEastOfRockies] = useState<boolean>(false);
  const [terms, setTerms] = useState<string>('');
  const { firestore } = useFirebase();

  useEffect(() => {
    const _shipmentRecord = shipment || orderShipments[0];
    setShipment(_shipmentRecord);
    const zip = parseInt(_shipmentRecord.customer.ShipAddr.PostalCode, 10);
    setEastOfRockies(!Number.isNaN(zip) && zip < 70000);
    firestore.collection('terms').doc(_shipmentRecord.customer.SalesTermRef.value).get().then((termsDoc) => {
      setTerms(termsDoc.data().Name);
    });
  }, [orderShipments, shipment]);

  return (
    <>
      {(!!_shipment.shippedItems && (
        <>
          <PDFViewer showToolbar style={style.viewer}>
            <Document title={`Invoice - ${_shipment.customer.id} ${_shipment.shipmentNumber}`}>
              <Page size="LETTER" style={style.page} wrap={false}>
                <Image style={style.emailedStamp} src="/images/emailed.jpg" fixed />
                <Image style={style.paidStamp} src="/images/paid.jpg" fixed />
                <Text style={style.invoiceNumber}>{_shipment.shipmentNumber}</Text>
                <InvoiceHeader shipment={_shipment} />
                <View style={style.confirmationBody}>
                  <BillingShippingInfo shipment={_shipment} />
                  <ShipmentLogisticsDetail
                    orderNumber={currentShopOrder.salesOrder}
                    packListNumber={_shipment.shipmentNumber}
                    poNumber={_shipment.purchaseOrder}
                    terms={terms}
                  />
                  <PackingSlipNotes notes={_shipment.notes} />
                  {/* <View style={style.notesBuffer} /> */}
                  <OrderConfirmationItemHeader />
                  {_shipment.shippedItems.filter((orderItem) => orderItem.quantityShipped > 0)
                    .map((orderItem) => (
                      <ShipmentItem
                        quantity={orderItem.quantityShipped}
                        partNumber={orderItem.Sku}
                        description={orderItem.Description}
                        notes={orderItem.notes}
                        unitPrice={orderItem.unitPrice}
                      />
                    ))}
                  <InvoiceFooter discountShipping={eastOfRockies} total={_shipment.value} tax={0} shipmentCost={_shipment.shippingCost} />
                </View>
                <Text
                  style={style.pageNumber}
                  render={({ pageNumber, totalPages }) => (
                    shipment.customer.DisplayName !== 'NASHG' || totalPages > 2 ? `Page ${pageNumber} of ${totalPages}` : ''
                  )}
                  fixed
                />
              </Page>
              {shipment.customer.DisplayName === 'NASHG' && (
                <Page size="LETTER" style={style.page} wrap={false}>
                  <InvoiceHeader shipment={_shipment} />
                  <View style={style.confirmationBody}>
                    <BillingShippingInfo shipment={_shipment} />
                    <ShipmentLogisticsDetail
                      orderNumber={currentShopOrder.salesOrder}
                      packListNumber={_shipment.shipmentNumber}
                      poNumber={_shipment.purchaseOrder}
                      terms={terms}
                    />
                    <PackingSlipNotes notes={_shipment.notes} />
                    {/* <View style={style.notesBuffer} /> */}
                    <OrderConfirmationItemHeader />
                    {_shipment.shippedItems.filter((orderItem) => orderItem.quantityShipped > 0)
                      .map((orderItem) => (
                        <ShipmentItem
                          quantity={orderItem.quantityShipped}
                          partNumber={orderItem.Sku}
                          description={orderItem.Description}
                          notes={orderItem.notes}
                          unitPrice={orderItem.unitPrice}
                        />
                      ))}
                    <InvoiceFooter discountShipping={eastOfRockies} total={_shipment.value} tax={0} shipmentCost={_shipment.shippingCost} />
                  </View>
                </Page>
              )}
            </Document>
          </PDFViewer>
        </>
      ))}
    </>
  );
};

export default InvoicePDF;
