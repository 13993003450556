import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import {
  useTestDataAtom,
} from 'shared/state/pricingState';
import {
  partViewerConfigDataAtom,
  partViewerStateAtom,
  partViewerTypeAtom,
} from 'shared/state/partViewState';
import { customerConfig } from 'shared/partParser/util';
import { Button, Input } from 'antd';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { adminUserEmailsAtom, customerUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import useFirebase from 'vendor/Firebase';
import { cacheExpired, expireDate, refreshPartDataCache } from 'shared/perf/caching';
import CustomerMenu from './Components/CustomerMenu';
import Uploader from './Components/CustomerPartUploader';
import {
  PartViewerRow,
  PartViewerCol, CustomerListCol, CustomerListColInnerWrapper, PartViewerDetailsCol, CustomerMenuPlaceholder,
} from './Components/styledComponents';

import PartViewerTypeSelector from './Components/PartViewerTypeSelector';
import PartAttributeSelector from './Components/PartAttributeSelector';
import { columns } from './Components/PartViewerDataColumns';
import {
  RunnerActionRow, RunnerHeaderRow,
  RunnerPageTitle, RunnerTable,
} from '../ProductionSchedule/styledComponents';
import { PartFinderWrapper } from '../styledComponents';
import { ICustomerRecord } from '../../shared/types/dbRecords';
import theme from '../../shared/theme';
import { currentCustomerAtom, customersAtom } from '../../shared/state/customerState';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';

const shortid = require('shortid');
const _ = require('lodash');

const NewFinderLink = styled.a`
  color: ${theme.palette.primary.hue};
  position: absolute;
  top: -24px;
  right: 0;
  z-index: 10000000;
`;

const PartSearchField = styled(Input.Search)`
  margin: 12px 0;
  padding-right: 16px;
  & .ant-input-affix-wrapper {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  & .ant-input-search-button {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
`;

const AttributeClearRow = styled(FlexRow)`
  width: 100%;
  padding-right: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const AllClearButton = styled(Button)`
  border-radius: 16px;
  height: 28px;
  line-height: 0px;
`;
export default () => {
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const customers = useRecoilValue(customersAtom);
  const useTestData = useRecoilValue(useTestDataAtom);
  const partViewerConfigData = useRecoilValue(partViewerConfigDataAtom);
  const partViewerType = useRecoilValue(partViewerTypeAtom);
  const partViewerState = useRecoilValue(partViewerStateAtom);
  const resetPartViewerState = useResetRecoilState(partViewerStateAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);

  const [filteredPartData, setFilteredPartData] = useState<any[]>([]);
  const [filteringAttributes, setFilteringAttributes] = useState<{ key: string, label: string, attributes: string[] }[]>([{ key: '', label: '', attributes: [''] }]);
  const [clearAllDisabled, setClearAllDisabled] = useState<boolean>(false);
  const [searchFilterTerms, setSearchFilterTerms] = useState<string[]>([]);

  const showPart = (partObject: any) => {
    if (searchFilterTerms.length > 0) return _.every(searchFilterTerms, (t: string) => partObject.description.match(new RegExp(t.trim(), 'ig')) || partObject.partNumber.match(new RegExp(t.trim())));

    const state = partViewerState[partViewerType];
    const match = Object.keys(state).map((k: string) => {
      const stateVal = state[k];
      const partVal = partObject[k];
      return (stateVal.toString() === '.*' && partVal.toString() === '') || partVal === stateVal || !!partVal?.toString().match(new RegExp(`^${stateVal}$`, 'i'));
    });

    return _.every(match, (m: any) => m);
  };

  const onSearchChange = (value: string) => {
    if (value.length === 0) setSearchFilterTerms([]);
  };
  const onSearch = (value: string) => {
    setSearchFilterTerms(value.split(','));
  };

  const onClearAllAttributes = (e: any) => {
    resetPartViewerState();
    const searchClearButton = document.querySelector('.anticon.anticon-close-circle.ant-input-clear-icon');
    if (searchClearButton) {
      // @ts-ignore
      searchClearButton.click();
    }
    setSearchFilterTerms([]);
    // onSearchChange('');
  };

  const onRefreshCache = () => {
    window.location.reload();
  };

  const canClearAll = (): void => {
    setClearAllDisabled(_.every(Object.values(partViewerState[partViewerType]), (o: string) => o === '.*') && searchFilterTerms.length === 0);
  };

  interface IFilterAttribute {
    key: string;
    label: string;
    attributes: string[];
  }

  const setFilterAttributes = (queryData: any[], customerId: string) => {
    const customerPartConfig = customerConfig(partViewerConfigData, customerId, partViewerType);
    const partAttributeData = Object.keys(customerPartConfig).map((k: string) => ({
      key: k,
      label: customerPartConfig[k].label,
      sort: customerPartConfig[k].sort,
      attributes: _.sortBy([..._.uniq(queryData.map((p: any) => p[k])).filter((t: string) => t && t.length)], (t: string) => t),
    }));

    const filteringAttrs = (_.sortBy(partAttributeData, ['sort', 'label']) as IFilterAttribute[])
      .filter((p: IFilterAttribute) => p.attributes.length > 0)
      .filter((p: IFilterAttribute) => !_.includes(['Price', 'Part Type', 'WW Part Number', 'Description'], p.label));
    setFilteringAttributes(filteringAttrs);
  };

  useEffect(() => {
    canClearAll();

    const partViewerTypes = partViewerType === 'body' ? ['BB', 'GB'] : ['BN', 'GN'];
    let customerId = currentCustomer?.id || null;
    if (!customerId) customerId = localStorage.getItem('currentCustomerId') ? localStorage.getItem('currentCustomerId') : null;
    if (customerId) {
      setCurrentCustomer(_.find(customers, (c: ICustomerRecord) => (c.id === customerId)) as ICustomerRecord);
      if (localStorage.getItem('cachePartData')) {
        const cached = localStorage.getItem(`partViewerData.${customerId}.${partViewerType}`);
        const { expire, data } = cached ? JSON.parse(cached) : { expire: false, data: null };
        if (data && !cacheExpired(expire)) {
          const filtered = data.filter((p: any) => showPart(p));

          setFilteredPartData(filtered);
          setFilterAttributes(filtered, customerId);
        }
      } else {
        const queryData = [] as any[];
        firestore.collection('part-viewer-data')
          .where('customer', '==', customerId)
          .where('type', 'in', partViewerTypes)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              const docData = doc.data();
              if (showPart(docData)) queryData.push(docData);
            });
            if (localStorage.getItem('cachePartData')) localStorage.setItem(`partViewerData.${customerId}.${partViewerType}`, JSON.stringify({ expire: expireDate(), data: queryData }));
            setFilteredPartData(queryData);
            setFilterAttributes(queryData, customerId as string);
          });
      }
    }
  }, [useTestData, currentCustomer, partViewerType, partViewerState, searchFilterTerms]);

  useEffect(() => {
    resetPartViewerState();
  }, [currentCustomer]);

  return (
    <PartFinderWrapper>
      <NewFinderLink href="/pricing">Try the improved Part Finder!</NewFinderLink>
      <RunnerHeaderRow style={{ marginBottom: 8 }} key={shortid.generate()}>
        <FlexColumn key={shortid.generate()} align="flex-start">
          <RunnerPageTitle key={shortid.generate()}>{`Part Finder${currentCustomer ? ` for ${currentCustomer.name}` : ''}`}</RunnerPageTitle>
        </FlexColumn>
        <RunnerActionRow key={shortid.generate()}>
          <FlexRow justify="flex-start">
            <PartViewerTypeSelector />
            <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
              <Uploader />
            </ScopedComponent>
          </FlexRow>
        </RunnerActionRow>
      </RunnerHeaderRow>
      <PartViewerRow>
        <PartViewerCol>
          <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
            <CustomerMenu context="finder" />
          </ScopedComponent>
          <ScopedComponent whitelist={customerEmails.emails}>
            <CustomerMenuPlaceholder />
            <CustomerListCol style={{ width: '100%', borderRight: 'unset' }}>
              <CustomerListColInnerWrapper>
                <AttributeClearRow>
                  <h3>Filters</h3>
                  {/* <PartViewerTypeSelector /> */}
                  <AllClearButton disabled={clearAllDisabled} type="primary" onClick={onClearAllAttributes}>Clear All</AllClearButton>
                </AttributeClearRow>
                <PartSearchField id="search-input" placeholder="Comma-separated terms" onSearch={onSearch} onChange={onSearchChange} allowClear />
                {filteringAttributes.map((a: { key: string, label: string, attributes: string[] }) => (
                  <PartAttributeSelector
                    key={shortid.generate()}
                    stateKey={a.key}
                    label={a.label}
                    attributeValues={a.attributes}
                  />
                ))}
              </CustomerListColInnerWrapper>
            </CustomerListCol>
          </ScopedComponent>
        </PartViewerCol>
        {filteredPartData.length > 0
    && (
    <PartViewerDetailsCol>
      <RunnerTable
        scroll={{ x: true }}
        size="middle"
          // @ts-ignore
        columns={columns}
        dataSource={filteredPartData}
        pagination={{ position: ['topRight', 'bottomRight'], defaultPageSize: 14 }}
      />
    </PartViewerDetailsCol>
    )}
      </PartViewerRow>
    </PartFinderWrapper>
  );
};
