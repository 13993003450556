import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Divider } from 'antd';
import {
  CheckboxWrapper,
  ConfigurationCol,
  ItemPrice,
  OptionCheckbox,
} from 'shared/pageElements/styledComponents';
import { formatPrice } from 'shared/data';
import {
  hideUnselectedOptionsAtom, newBodyAtom, newNeckAtom, partEditModeAtom, useTestDataAtom,
} from 'shared/state/pricingState';
import { IUnitOption } from 'shared/types/pricingTool';

const _ = require('lodash');

interface ComponentInterface {
  optionMasterList: IUnitOption[];
  recordOptionSet: IUnitOption[];
  partType: 'body'|'neck';
  listTitle: string;
}

export default ({
  optionMasterList, recordOptionSet, partType, listTitle,
}: ComponentInterface) => {
  // @ts-ignore
  const [itemState, setItemState] = useRecoilState(partType === 'body' ? newBodyAtom : newNeckAtom);
  const editMode = useRecoilValue(partEditModeAtom);
  const useTestData = useRecoilValue(useTestDataAtom);
  const [optionsSelected, setOptionsSelected] = useState(false);

  const isChecked = (item: IUnitOption) => !!_.find(recordOptionSet, (opt: IUnitOption) => opt.id === item.id);

  useEffect(() => {
    let listType = _.uniq(optionMasterList.map((o: IUnitOption) => o.type));
    listType = listType.length ? listType[0] : null;
    // @ts-ignore
    setOptionsSelected(itemState.options[listType]?.length > 0);
  }, [itemState.options]);

  const onChange = (e: any) => {
    if (!editMode) return;

    const option = e.target.value;
    // @ts-ignore
    const optionSet = itemState.options[option.type];

    let newOptions = [
      ...optionSet,
      ...[option],
    ];

    if (!e.target.checked) {
      newOptions = optionSet.filter((o: IUnitOption) => {
        if (o) return o.id !== option.id;
        return null;
      });
    }

    const newRecord = {
      ...itemState,
      options: {
        // @ts-ignore
        ...itemState.options,
        ...{ [option.type]: newOptions },
      },
    };

    // @ts-ignore
    setItemState(newRecord);
    setOptionsSelected(newOptions.length > 0);
  };

  return (
    <>
      {(optionsSelected || editMode)
      && (
      <>
        <Divider key="part-unit-options-list-divider" orientation="left">{listTitle}</Divider>
        <ConfigurationCol key="part-unit-options-column">
          { optionMasterList.map((o: IUnitOption) => (
            <>
              { (editMode || isChecked(o))
          && (
          <CheckboxWrapper key={`${o.id}-checkbox-wrapper`} align="center" justify="space-between">
            <OptionCheckbox
              key={o.id}
              id={o.id}
              value={o}
              style={{ marginLeft: 0 }}
              onChange={onChange}
              defaultChecked={isChecked(o)}
              checked={!editMode || isChecked(o)}
            >
              {o.name}
            </OptionCheckbox>
            <ItemPrice testMode={useTestData} selected={isChecked(o)}>
              {formatPrice(o.price)}
            </ItemPrice>
          </CheckboxWrapper>
          )}
            </>
          ))}
        </ConfigurationCol>
      </>
      )}
    </>
  );
};
