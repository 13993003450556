import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Divider, PageHeader } from 'antd';
import {
  materialScrapDataAtom,
} from 'shared/state/utilState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { jobScheduleDataAtom, orderScheduleDataAtom } from 'shared/state/orderState';
import { pricingExportDataAtom, useTestDataAtom } from 'shared/state/pricingState';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import theme from 'shared/theme';
import styled from 'styled-components';
import Uploader from './Components/JobScheduleUploader';
import { CustomerDownloadButton } from '../Customer/Components/styledComponents';
import OrderScheduleDataUploader from './Components/OrderScheduleDataUploader';
import ParsedPartUploader from './Components/ParsedPartDownloader';
import PricingExportButton from './Components/PricingExportButton';
import UsageUploader from './Components/UsageUploader';
import CloneMapUploader from './Components/CloneMapUploader';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import AdHocExecutor from './AdHocExecutor';
import PartNumberUploader from './Components/PartNumberUploader';
import SyncJbInventory from './Components/JobBoss/SyncJbInventory';
import SyncOpenPONumbers from './Components/JobBoss/SyncPoNumbers';
import ConsumeMaterialsButton from './Components/QBO/ConsumeMaterials';
import FlushMaterialConsumptionButton from './Components/FlushMaterialConsumption';
import NormalizePartNamesButton from './Components/NormalizePartNames';
import IDIntegrityButton from './Components/IDIntegrityButton';
import AllocatedNotConsumedButton from './Components/AllocatedNotConsumedButton';
import SyncCustomers from './Components/QBO/SyncCustomers';
import ShowRealtimeDataButton from './Components/RealtimeData/Buttons/ShowRealtimeDataButton';
import RealtimeDataDrawer from './Components/RealtimeData/RealtimeDataDrawer';
import BulkUpdateQBOItems from './Components/QBO/BulkUpdateQBOItems';
import SalesDataUploader from './Components/SalesDataUploader';
import NewPartOrderUploader from './Components/NewPartOrderUploader';
import PartsConsumedDownloader from './Components/PartsConsumedDownloader';
import BodyThicknessUploader from './Components/BodyThicknessUploader';

const Body = styled.p`
  font-size: 16px;
  font-weight: bolder;
  margin: 0;
  margin-right: 24px;
`;

const ComponentWrapper = styled(FlexColumn)`
  width: calc(100% - 100px);
  margin-left: 100px;
  justify-content: flex-start;
    @media ${theme.device.laptopL} {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
`;
const Header = styled(PageHeader)`
    width: calc(100% - 100px);
    margin-left: 100px;
`;

const ParserColumn = styled(FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
    @media ${theme.device.laptopL} {
        min-height: 100vh;
        width: 50%;
    }
`;
const ParserRow = styled(FlexRow)`
  width: 600px;
  align-items: center;
  justify-content: space-between;
  margin: 24px 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid lightgray;
`;

const DownloadButton = styled(CustomerDownloadButton)`
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
`;

export default () => {
  const [jobScheduleData, setJobScheduleData] = useRecoilState(jobScheduleDataAtom);
  const [materialScrapData, setMaterialScrapData] = useRecoilState(materialScrapDataAtom);
  const [orderScheduleData, setOrderScheduleData] = useRecoilState(orderScheduleDataAtom);
  const [pricingExportData, setPricingExportData] = useRecoilState(pricingExportDataAtom);
  const superAdminUsersEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminUsersEmails = useRecoilValue(adminUserEmailsAtom);
  const useTestData = useRecoilValue(useTestDataAtom);

  return (
    <>
      <RealtimeDataDrawer />
      <Header title="Data Utilities" />
      <ComponentWrapper>
        <ParserColumn>
          <ScopedComponent scopedFor={superAdminUsersEmails.emails}>
            <ParserRow align="center" justify="flex-end">
              <Body>Run one-time function</Body>
              <AdHocExecutor />
            </ParserRow>
          </ScopedComponent>
          {/* Job Requirements */}
          <ScopedComponent scopedFor={[...superAdminUsersEmails.emails, ...adminUsersEmails.emails]}>
            <ParserRow align="center" justify="flex-end">
              <Body>View/Edit Realtime Data</Body>
              <ShowRealtimeDataButton />
            </ParserRow>
            <Divider orientation="left" type="horizontal" />
            <ParserRow align="center" justify="flex-start">
              <Body>Body Job Requirements</Body>
              {jobScheduleData === '' ? (
                <Uploader />
              ) : (
                <DownloadButton
                  key="job-schedule-download-button"
                  filename={`Job_Schedule_Data_${new Date().toLocaleString()}.csv`}
                  data={jobScheduleData}
                  onClick={() => {
                    setTimeout(() => {
                      setJobScheduleData('');
                    }, 200);
                  }}
                >
                  Download Job Schedule Data
                </DownloadButton>
              )}
            </ParserRow>
          </ScopedComponent>
          {/* Order Requirements Dates */}
          <ScopedComponent scopedFor={[...superAdminUsersEmails.emails, ...adminUsersEmails.emails, 'edwardl@wildwoodmfg.com']}>
            <ParserRow align="center" justify="flex-start">
              <Body>Order Requirement Dates</Body>
              {orderScheduleData === '' ? (
                <OrderScheduleDataUploader />
              ) : (
                <DownloadButton
                  key="order-schedule-download-button"
                  filename={`Order_Schedule_Data_${new Date().toLocaleString()}.csv`}
                  data={orderScheduleData}
                  onClick={() => {
                    setTimeout(() => {
                      setOrderScheduleData('');
                    }, 200);
                  }}
                >
                  Download Order Schedule Data
                </DownloadButton>
              )}
            </ParserRow>
          </ScopedComponent>

          {/* Pricing Export */}
          <ParserRow align="center" justify="flex-start">
            <Body>Export Pricing Information</Body>
            {pricingExportData.length === 0 ? (
              <PricingExportButton />
            ) : (
              <DownloadButton
                key="pricing-information-download-button"
                filename={`${useTestData ? 'TEST_' : ''}Pricing_Data_${new Date().toLocaleString()}.csv`}
                data={pricingExportData}
                enclosingCharacter={"'"}
                headers={[
                  { label: 'JB Part #', key: 'jbPartNumber' },
                  { label: 'Item Category', key: 'itemCategory' },
                  { label: 'Description', key: 'description' },
                  { label: 'Price', key: 'price' },
                  { label: 'Instrument Type', key: 'instrumentType' },
                  { label: '', key: '' },
                  { label: 'PartId', key: 'id' },
                ]}
                onClick={() => {
                  setTimeout(() => {
                    setPricingExportData([]);
                  }, 200);
                }}
              >
                Export Pricing Data
              </DownloadButton>
            )}
          </ParserRow>

          {/* Order Requirements Dates */}
          <Divider orientation="left" type="horizontal">Customer Part Data</Divider>
          <ScopedComponent scopedFor={[...superAdminUsersEmails.emails, ...adminUsersEmails.emails]}>
            <ParserRow align="center" justify="flex-start">
              <Body>Parsed Part Data</Body>
              <ParsedPartUploader label="Parse Part Data" />
            </ParserRow>
            <ParserRow align="center" justify="flex-start">
              <Body>Part Name Normalization</Body>
              <NormalizePartNamesButton />
            </ParserRow>
          </ScopedComponent>
          <ScopedComponent scopedFor={['keithh@wildwoodmfg.com']}>
            <ParserRow align="center" justify="flex-start">
              <Body>New Part (Order) Uploader</Body>
              <NewPartOrderUploader />
            </ParserRow>
            <ParserRow align="center" justify="flex-start">
              <Body>Clone Uploader</Body>
              <CloneMapUploader />
            </ParserRow>
          </ScopedComponent>
          <ScopedComponent scopedFor={['keithh@wildwoodmfg.com']}>
            <ParserRow align="center" justify="flex-start">
              <Body>Sales DataUploader</Body>
              <SalesDataUploader />
            </ParserRow>
          </ScopedComponent>
          <ScopedComponent whitelist={[...superAdminUsersEmails.emails]}>
            <ParserRow align="center" justify="flex-end">
              <Body>Upload Part Numbers</Body>
              <PartNumberUploader />
            </ParserRow>
          </ScopedComponent>
          <ScopedComponent whitelist={[...superAdminUsersEmails.emails]}>
            <ParserRow align="center" justify="flex-end">
              <Body>Upload Body Thicknesses</Body>
              <BodyThicknessUploader />
            </ParserRow>
          </ScopedComponent>
        </ParserColumn>
        <ParserColumn>
          <ScopedComponent whitelist={[...superAdminUsersEmails.emails, ...adminUsersEmails.emails]}>
            <Divider orientation="left" type="horizontal">QBO Sync</Divider>
            {/* <BulkUpdateQBOItems /> */}
            <ParserRow align="center" justify="flex-start">
              <Body>Synchronize QBO Customer Data</Body>
              <SyncCustomers />
            </ParserRow>
            <Divider orientation="left" type="horizontal">BOM Utils</Divider>
            <ParserRow align="center" justify="flex-start">
              <Body>Consume open order material</Body>
              <ConsumeMaterialsButton />
            </ParserRow>
            <ParserRow align="center" justify="flex-start">
              <Body>Download consumed parts for a period</Body>
              <PartsConsumedDownloader />
            </ParserRow>
            <ParserRow align="center" justify="flex-start">
              <Body>Reset BOM consumption</Body>
              <FlushMaterialConsumptionButton />
            </ParserRow>
            <ParserRow align="center" justify="flex-start">
              <Body>Parts allocated not consumed</Body>
              <AllocatedNotConsumedButton />
              {/* <MaterialNotConsumedButton /> */}
            </ParserRow>
            <Divider orientation="left" type="horizontal">DB Sanitization</Divider>
            <ParserRow align="center" justify="flex-start">
              <Body>Ensure ID Integrity</Body>
              <IDIntegrityButton />
            </ParserRow>
          </ScopedComponent>
        </ParserColumn>
      </ComponentWrapper>
    </>
  );
};
