import React, { useContext } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { Divider } from 'antd';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { userSettingsAtom } from 'shared/state/siteState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import BooleanSetting from './Components/BooleanSetting';
import ToggleSetting from './Components/ToggleSetting';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';

const ComponentWrapper = styled(FlexColumn)`
  height: calc(100% - 24px);
  justify-content: flex-start;
  gap: 16px;
  overflow-y: scroll;
  padding-top: 24px;
`;

interface IComponent {
  reloadCallback: () => void;
}
const SettingsCenter = ({ reloadCallback }: IComponent) => {
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const { database } = useFirebase();
  const [userSettings, setUserSettings] = useRecoilState(userSettingsAtom);

  const toggleBoolean = async (key: string, value: boolean, reloadRequired: boolean) => {
    await database.ref(`/settings/${currentUser.email.split('@')[0]}/${key}`).set(value);
    // @ts-ignore
    const updatedBooleanSettings = { ...(userSettings.boolean || {}) };
    updatedBooleanSettings[key] = value;
    setUserSettings({
      ...userSettings,
      boolean: updatedBooleanSettings,
    });

    if (reloadRequired) reloadCallback();
  };

  const setToggleSetting = async (key: string, value: string, reloadRequired: boolean) => {
    await database.ref(`/settings/${currentUser.email.split('@')[0]}/${key}`).set(value);
    const updatedStringSettings = { ...(userSettings.string || {}) };
    updatedStringSettings[key.replace('string/', '')] = value;
    setUserSettings({
      ...userSettings,
      string: updatedStringSettings,
    });

    if (reloadRequired) reloadCallback();
  };

  return (
    <ComponentWrapper>
      <Divider>Production Schedule</Divider>
      <BooleanSetting
        label="Show CNC Load"
        helpMessage="Show the number of hours required on the CNC for the week's neck orders."
        storageKey="boolean/showCNCLoad"
        value={userSettings?.boolean?.showCNCLoad || false}
        callback={toggleBoolean}
        reloadRequired
      />
      <ToggleSetting
        label="Scroll to location"
        helpMessage="When changing the dates in the schedule, scroll to either the first date in the scheedule or the current week."
        options={[{ value: 'current', label: 'Current week' }, { value: 'start', label: 'Start' }]}
        value={userSettings?.string?.scrollToLocation || 'start'}
        storageKey="string/scrollToLocation"
        reloadRequired
        callback={setToggleSetting}
      />
      <Divider>Utility</Divider>
      <BooleanSetting
        label="Show Decimal Weights, Density Calculator"
        helpMessage="Show decimal equivalent to weights displayed in the density calculator"
        storageKey="boolean/showDecimalWeights"
        value={userSettings?.boolean?.showDecimalWeights || false}
        callback={toggleBoolean}
        reloadRequired
      />
      <ScopedComponent whitelist={['keithh@wildwoodmfg.com']}>
        <Divider>Experimental Features</Divider>
        <BooleanSetting
          label="Show Weighted Parts/day"
          helpMessage="Use the average part type price to show the relative number of parts per day, based on dollar amount."
          storageKey="boolean/useWeightedPartsPerDay"
          value={userSettings?.boolean?.useWeightedPartsPerDay || false}
          callback={toggleBoolean}
          reloadRequired
        />
      </ScopedComponent>
    </ComponentWrapper>
  );
};

export default SettingsCenter;
