import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { includes, some } from 'lodash';
import PartListFilter from 'shared/components/Input/PartListFilter';
import { currentShipmentPageAtom, shipmentSearchQueryAtom } from 'shared/state/shipmentState';

interface IComponent {
  width?: string;
}
const ShipmentPartFilter = ({ width = undefined }: IComponent) => {
  const [shipmentSearchQuery, setShipmentSearchQuery] = useRecoilState(shipmentSearchQueryAtom);
  const setCurrentPage = useSetRecoilState(currentShipmentPageAtom);

  const onChangeSearch = (e: any) => {
    if (some('!@#$%^&*()+=`~[]{};:><?\\'.split(''), (c: string) => includes(e.target.value.split(''), c))) return;
    setCurrentPage(1);
    setShipmentSearchQuery(e.target.value);
    sessionStorage.setItem('Shipment.query.string', e.target.value);
  };

  return (
    <PartListFilter
      id="Shipment-filter"
      prompt="Filter parts using comma-separated terms, e.g. Ash, BODYBLANK, STEMAC"
      value={shipmentSearchQuery}
      callback={onChangeSearch}
      storageKey="shipment.query.string"
      width={width}
    />
  );
};

export default ShipmentPartFilter;
