import {
  difference, find, includes, omit, sortBy,
} from 'lodash';
import { devLog } from 'shared/util/logging';
import {
  bodyDataAtom, neckDataAtom, newBodyAtom, newNeckAtom,
} from '../state/pricingState';
import { CONSTRUCTION_JASON } from '../scanner';
import { ICustomerRecord } from '../types/dbRecords';
import { IRateOption, IUnitOption } from '../types/pricingTool';
import { IShopOrder } from '../../pages/Orders/types';
import { uniqueChildPartNameSegments } from '../text';

interface IPrice {
  price: number;
  discount: number;
}

export const definedParts = (list: any) => list.filter((i: any) => !!i);

export const formatPercent = (value: number, precision: number = 2, float: boolean = true) => {
  if (float) return `${(value * 100).toFixed(precision)}%`;
  return `${value.toFixed(precision)}%`;
};

export const formatMargin = (purchaseCost: number, sellPrice: number) => {
  const margin = (sellPrice - purchaseCost) / sellPrice;
  return formatPercent(margin);
};
export const formatPrice = (price: string|number|undefined, decimalPlaces: number = 2): string => {
  if (!price) return '$0.00';
  // guard against some prices being stored as strings
  const _price = typeof (price) === 'number' ? price : parseFloat(price.replace('$', ''));
  const format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  // if (_price < 0) return `($${(_price * -1).toFixed(2)})`;
  // return `$${_price.toFixed(2)}`;
  return format.format(_price);
};

// export const partType = (part: any): string => {
//   if (_.includes(['neck', 'BN', 'GN'], part.type)) return 'neck';
//   return 'body';
// };

export const bodyTotal = (bodyState: any): IPrice => {
  const archetype = bodyState.archetype;
  // @ts-ignore
  let bodyCost = bodyState.bodyWood ? bodyState.bodyWood.price[archetype.instrument] : 0;
  // let materialCost = bomItems.map((b) => b.totalPrice).reduce((a, b) => a + b, 0);
  if (archetype.instrument === 'S' && bodyCost === 0) bodyCost = bodyState.bodyWood.price.M || 0;
  bodyCost += bodyState.blankModification?.price || 0;
  // @ts-ignore
  const topCost = bodyState.topWood && bodyState.topWood.price ? bodyState.topWood.price[archetype.instrument] : 0;

  const unitOptionsCost = [
    ...bodyState.options.weightReductionOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.constructionOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.accessoryOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.finishingOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.handlingOptions.filter((i: IUnitOption) => i.price >= 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  // Calculate discounts separately so we don't discount our deductions from the price of a part.
  const unitOptionsDiscount = [
    ...bodyState.options.weightReductionOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.constructionOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.accessoryOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.finishingOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.handlingOptions.filter((i: IUnitOption) => i.price < 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const laborOptionsCost = bodyState.options.laborOptions
    .map((o: IRateOption) => (o.quantity * o.price))
    .reduce((a: number, b: number) => a + b, 0);

  const priceAdjustment = bodyState.priceAdjustment || 0;

  // devLog('data/index', 90, `part total: ${archetype.price + bodyCost + topCost + unitOptionsCost + laborOptionsCost + priceAdjustment}`);
  // devLog('data/index', 90, `archetypeCost: ${archetype.price}`);
  // devLog('data/index', 90, `bodyCost: ${bodyCost}`);
  // devLog('data/index', 90, `topCost: ${topCost}`);
  // // console.log('bodyCost', bodyCost);
  // devLog('data/index', 90, `unitOptionsCost: ${unitOptionsCost}`);
  // devLog('data/index', 90, `laborOptionsCost: ${laborOptionsCost}`);
  // devLog('data/index', 90, `priceAdjustment: ${priceAdjustment}`);

  return {
    price: archetype.price + bodyCost + topCost + unitOptionsCost + laborOptionsCost + priceAdjustment,
    discount: unitOptionsDiscount,
  };
};

export const neckTotal = (neckState: any): IPrice => {
  const archetype = neckState.archetype;
  // @ts-ignore
  const neckCost = neckState.neckWood ? neckState.neckWood.price[archetype.instrument] : 0;
  // @ts-ignore
  const fretboardCost = neckState.fretboardWood ? neckState.fretboardWood.price[archetype.instrument] : 0;
  const unitOptionsCost = [
    ...(neckState.options.trussRodOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.frettingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.inlayOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.dotOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.constructionOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.finishingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.handlingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.accessoryOptions || []).filter((i: IUnitOption) => i.price >= 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const unitOptionsDiscount = [
    ...(neckState.options.trussRodOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.frettingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.inlayOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.dotOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.constructionOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.finishingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.handlingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.accessoryOptions || []).filter((i: IUnitOption) => i.price < 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const laborOptionsCost = neckState.options.laborOptions
    .map((o: IRateOption) => (o.quantity * o.price))
    .reduce((a: number, b: number) => a + b, 0);

  const priceAdjustment = neckState.priceAdjustment || 0;

  // const archetypePrice = includes(SPECIAL_CUSTOMERS, currentCustomer.id) ? archetype.price - ARCHETYPE_DISCOUNT : archetype.price;
  return {
    price: archetype.price + neckCost + fretboardCost + unitOptionsCost + laborOptionsCost + priceAdjustment,
    discount: unitOptionsDiscount,
  };
};

export const discountPrice = (price: any, customer: ICustomerRecord, divisor: number = 100, noFormat: boolean = false) => {
  if (!price || !price.price) return formatPrice(0);
  if (price.price < 0) return price;
  const discount = price.type.match(/[G|B]B/i) ? customer.bodyDiscount : customer.neckDiscount;
  const rawDiscount = (price.price * (1 + (discount) / 100));

  if (noFormat) return rawDiscount + (price.discount || 0);
  return formatPrice(Math.round((rawDiscount * divisor) / divisor) + (price.discount || 0));
};

export const groupItems = (collection: any) => {
  const groupedItems = collection.types.map((t: string) => ({
    type: t,
    items: collection.items.filter((i: any) => i.type === t),
  }));

  return groupedItems;
};

export const updateBodyPricing = (priceRecord: any, bodyData: any) => {
  try {
    // @ts-ignore
    priceRecord.archetype = find(
      definedParts(bodyData.archetype.items),
      (bA: any) => bA.id === priceRecord.archetype.id,
    ) || priceRecord.archetype;

    // @ts-ignore
    priceRecord.bodyWood = find(
      definedParts(bodyData.bodyWood.items),
      (tW: any) => tW.id === priceRecord.bodyWood.id,
    ) || priceRecord.bodyWood;

    priceRecord.blankModification = find(
      definedParts(bodyData.options.bodyBlankModifications.items),
      (b: any) => b.id === priceRecord.blankModification?.id,
    );

    // @ts-ignore
    priceRecord.topWood = find(
      definedParts(bodyData.topWood.items),
      (tW: any) => tW.id === priceRecord.topWood.id,
    ) || { id: '', label: 'Select a top wood (optional)', price: { L: 0, M: 0, S: 0 } };

    Object.entries(priceRecord.options).forEach((value: [string, unknown]) => {
      const [key, optionSet] = value;
      if (key !== 'laborOptions') {
        // if it's not a labor option, the value/quantity is always the same on both the
        // part record and the database, since it is a flat, binary value. In this case,
        // it's fine to stomp on the priceRecord's options.
        // @ts-ignore
        const mergedUnitOptionRecords = optionSet.map((o: IUnitOption) => find(definedParts(bodyData.options[key].items),
          (u: IUnitOption) => u.id === o.id));
        priceRecord.options[key] = mergedUnitOptionRecords.filter((o: IUnitOption) => o);
      } else {
        // in the case of Labor options, we only want to update the option value, since the quantity
        // may be different for each object.
        // @ts-ignore
        const mergedLaborOptionRecords = optionSet.map((o: IUnitOption) => {
          const dbRecord = find(definedParts(bodyData.options[key].items), (u: IUnitOption) => u.id === o.id);
          if (dbRecord) {
            return {
              ...o,
              price: dbRecord.price,
            };
          }
          return undefined;
        });
        priceRecord.options[key] = mergedLaborOptionRecords.filter((o: IUnitOption) => o);
      }
    });

    // console.log(bodyData);
    // console.log('----------');
    // console.log(priceRecord);
    return priceRecord;
  } catch (e) {
    console.log(e);
    console.log('----------------------------');
    console.log(priceRecord);
    console.log(bodyData);
    return null;
    // throw new Error('fuck dis shit');
  }
};

export const updateNeckPricing = (priceRecord: any, neckData: any) => {
  try {
  // @ts-ignore
    priceRecord.archetype = find(
      definedParts(neckData.archetype.items),
      (bA: any) => bA.id === priceRecord.archetype.id,
    ) || priceRecord.archetype;

    // @ts-ignore
    priceRecord.neckWood = find(
      definedParts(neckData.neckWood.items),
      (bW: any) => bW.id === priceRecord.neckWood.id,
    ) || priceRecord.neckWood;

    // @ts-ignore
    priceRecord.fretboardWood = find(
      definedParts(neckData.fretboardWood.items),
      (tW: any) => tW.id === priceRecord.fretboardWood.id,
    ) || priceRecord.fretboardWood;

    Object.entries(priceRecord.options).forEach((value: [string, unknown]) => {
      const [key, optionSet] = value;
      // @ts-ignore
      const mergedRecords = optionSet.map((o: IUnitOption) => find(definedParts(neckData.options[key].items),
        (u: IUnitOption) => u.id === o.id));
      priceRecord.options[key] = mergedRecords.filter((o: IUnitOption) => o);
    });

    return priceRecord;
  } catch (e) {
    console.log(e);
    console.log('----------------------------');
    console.log(priceRecord);
    console.log(neckData);
    throw new Error('fuck dis shit');
  }
};

export const updatePricing = (partRecord: any, pricingData: any): any => {
  if (!partRecord.pricing) return [partRecord, false];

  const partCategory = includes(Object.keys(partRecord.pricing), 'bodyWood') ? 'body' : 'neck';
  const [bodyData, neckData] = pricingData;

  if (!partRecord.pricing) {
    return partRecord;
  }

  let updated = {
    ...partRecord.pricing, discount: 0,
  };

  if (partCategory === 'neck') {
    updated = updateNeckPricing(updated, neckData);
  } else {
    updated = updateBodyPricing(updated, bodyData);
  }

  if (!updated) {
    devLog('shared/data/index', 274, partRecord);
    return;
  }

  return { ...partRecord, pricing: updated };
};

export const sanitizePricingRecord = (pricingRecord: any) => {
  const validKeys = [
    'archetype',
    'bodyWood',
    'options',
    'topWood',
    'weightClasses',
    'fretboardWood',
    'neckWood',
    'priceAdjustment',
    'blankModification',
  ];
  const invalidKeys = difference(Object.keys(pricingRecord), validKeys);
  return omit(pricingRecord, invalidKeys);
};
export const fetchPricing = (
  firestore: any,
  partCollectionDbString: string,
  customer: ICustomerRecord,
  pricingData: any = null,
  childViewType: 'sku'|'like' = 'sku',
): Promise<[]> =>
  firestore.collection(partCollectionDbString)
    .where('customer', '==', customer.DisplayName)
    .get()
    .then((snapshot: any) => {
      // @ts-ignore
      const prices: any[] = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc: any) => {
          const data = doc.data();
          try {
            const record = updatePricing(data, pricingData);
            if (!record.pricing) {
              prices.push(data);
            } else {
              const totalFunction = includes(['GB', 'BB'], record.type) ? bodyTotal : neckTotal;
              const rawPrice = totalFunction(record.pricing);
              prices.push({ ...data, ...rawPrice });
            }
          } catch (e) {
            console.log(data);
            console.log(pricingData);
            throw (e);
          }
        });
      }
      if (childViewType === 'sku') return new Promise((resolve: any) => resolve(sortBy(prices, (p) => p.Sku)));
      // @ts-ignore
      const childSkuPricing = prices.map((p) => {
        if (!p.childSku) return { ...p, isChild: false, sortKey: p.Sku };
        const parentSku = p.childSku.split('.')[0];
        const parent = find(prices, (price) => price.Sku === parentSku);
        if (parent) {
          const partDifferences = uniqueChildPartNameSegments(parent.Description, [p.Description], false)[0];
          return {
            ...p,
            isChild: true,
            sortKey: p.childSku,
            uniqueModifiers: partDifferences,
          };
        }
        return { ...p, isChild: false, sortKey: p.Sku };
      });
      const sortedPricing = sortBy(childSkuPricing, 'sortKey');
      console.log(sortedPricing.map((p) => p.sortKey));
      return new Promise((resolve: any) => resolve(sortedPricing));
    });
export const priceMultiplier = (value: number): string => {
  if (value < 0) return `${Math.floor((value * 100))}%`;
  if (value > 0) return `${Math.floor((value * 100))}%`;
  return '0%';
};

export const nextCustomerPart = (customer: string, customerParts: any[], partTypePattern: string) => {
  const partTypeMatcher = new RegExp(partTypePattern);
  const sameType = customerParts.filter((p: any) => p.type.match(partTypeMatcher));
  // const nextPart = customerParts
  //   .filter((p: any) => p.type.match(partTypeMatcher))
  const nextPart = sameType.map((p: any) => parseInt(p.Sku.split('_')[1], 10) + 1)
    .sort((a: number, b: number) => b - a);
  if (nextPart[0] >= 1000) return `${customer}_0${nextPart[0]}`;
  if (nextPart[0] >= 100) return `${customer}_00${nextPart[0]}`;
  if (nextPart[0] >= 10) return `${customer}_000${nextPart[0]}`;
  return `${customer}_0000${nextPart[0]}`;
  // }
  // return `${customer}_00000`;
};

export const isScannerStation = (userId: string) => {
  const stationIds = [
    'thoqTZixlrhlYxWy52GHUvNUUr42', // station-release
    'uuikiqzedJPiJ0t1zc9bJn2cDQt2', // station-assembly
    'n973nmDar7Xs7j74bmCJ8IcGlXp2', // station-construction
    CONSTRUCTION_JASON.stationId, // station-construction-jason
    'fUl6oWOQOYSHxDkI9BVYzgi8suj1', // station-dryroom
    '7l8NTrVLWFYReCmFYnbabqNXxTe2', // station-cnc
    'kF8mOZ8sIXXAOVPjFyJty8aFCfl1', // station-finishing
    'gEmUhPcMPHeCYUCA8o0dEXdBoSo1', // station-finishing-hand
    'mAmnJq0iJAUpSCZgKC5MIOEHrTD2', // station-qa-shipping
  ];

  return includes(stationIds, userId);
};

export const recordCsvData = (data: IShopOrder[]) => data.map((d: IShopOrder) => [
  d.customer.id,
  d.salesOrder,
  d.orderDate.toDate().toLocaleDateString(),
  d.type,
  d.partCount,
  d.description.replace(/,/g, ';'),
  `$${d.orderValue}`,
  d.releaseDate.toDate().toLocaleDateString(),
  d.runners?.length ? 'Released' : '',
  d.shipDate.toDate().toLocaleDateString(),
  d.completed ? 'Shipped' : '',
]);

export const PART_STATE_ATOM = {
  body: newBodyAtom,
  neck: newNeckAtom,
};

export const PART_DATA_ATOM = {
  body: bodyDataAtom,
  bodyTest: bodyDataAtom,
  neck: neckDataAtom,
  neckTest: neckDataAtom,
};

export const IS_MOBILE = /iPhone|iPad/i.test(navigator.userAgent);

export const RUNNER_BODY_INITIAL_STEP = 'ARft0FS3O';
export const RUNNER_NECK_INITIAL_STEP = 'bIKQ9ANcC';
export const ROUTER_JOB_TYPES = ['body', 'neck'];

export const BODY_HOLD_STEP = '_ic83Hl5F';
export const NECK_HOLD_STEP = 'JRgTgTROh';
export const HOLD_STEPS = ['On Hold', NECK_HOLD_STEP, BODY_HOLD_STEP];
export const READY_STEPS = [RUNNER_BODY_INITIAL_STEP, RUNNER_NECK_INITIAL_STEP, '-sWUWMT8b', 'ro2EFSo_X'];
export const NECK_READY_STEPS = [RUNNER_NECK_INITIAL_STEP, 'ro2EFSo_X'];
export const FINISHING_STEPS = ['Finishing', 'sOspM8A0Bhx', 'DsGT5gQg-dn', 'aaKxatWdehe', 'ejhdPLv2l', '99ehk9DSkpu'];
export const COMPLETE_STEPS = ['Complete', 'vTRZZbKL9', '73TxOo4eh'];
export const DRY_ROOM_STEP = '7kN0pSmXBw3';
export const BODY_WOOD_TYPES = [
  'SwAsh', 'Alder', 'Mahog', 'Pine', 'Rst. Pine', 'Other',
];
export const SLACK_NOTIFICATION_STEPS = [
  'Q9ANbIKcC',
  // 'aaKxatWdehe',
];

// takes an object array and reduces values in the array by key into a single object.
export const reduceObjectArrayValues = (objectArray: any[]) => objectArray.reduce((a: any, b: any) => {
  Object.keys(b).forEach((key) => {
    if (a[key] === undefined) {
      a[key] = b[key];
    } else {
      a[key] += b[key];
    }
  });
  return a;
}, {});

export const ALL_PART_TERMS = [
  '',
  '$218.00 ',
  '(Gibson)SDMH/S',
  '+ PH Cap',
  '.060 BIND SHELF',
  '.060 Bind Shelf F&B',
  '.060BindShelf',
  '.160',
  '.250Top',
  '.625NP',
  '.625StratNP',
  '/Ash Lite/Ven/5AFlameMap',
  '/CS',
  '0.125',
  '0.125RO',
  '0.125Special',
  '0.1875GndW',
  '0.1875RO',
  '0.375BMH',
  '0.625 StratNP',
  '0.625NP',
  '0.625NP-Tight',
  '0.795NP',
  '00.125RO',
  '00.1875 Top',
  '1 BMH',
  '1 Pc',
  '1 Pot',
  '1-3/4',
  '1-5/16',
  '1-5/8',
  '1.375',
  '1.40',
  '1.5',
  '1.50',
  '1.500',
  '1.625',
  '1.650',
  '1.670',
  '1.70',
  '1.75',
  '1.750',
  '1.785',
  '1.95',
  '1/4RO',
  '1/4TopRO',
  '1/8RO',
  '10St',
  '17.5mmBMH',
  '1930',
  '19mmNP',
  '1BMH',
  '1DeepPU',
  '1PU',
  '1Pc',
  '1PcGenMah',
  '1Vol & 1Tone',
  '2 Pc',
  '2 Post Trem',
  '2 Pots',
  '2 x 45DC',
  '2.168 NP',
  '2.25 NP',
  '2.25 Tele NP',
  '2.25AngledNP',
  '2.25NP',
  '2.25STH',
  '2.438NP',
  '20.125',
  '2020 WIDE 34',
  '2020 XLite WIDE 35',
  '21F',
  '22F',
  '24.75',
  '24F',
  '25.5',
  '2Lb 14',
  '2Lb 14..',
  '2Nord',
  '2P Bridge',
  '2P Trem',
  '2POTS',
  '2PT',
  '2PT Ash Lite',
  '2PTrem',
  '2PTrem-Small',
  '2Parts',
  '2Pc',
  '2Pc CL Paint',
  '2Pc Off',
  '2Post',
  '2Post Trem',
  '2Pots',
  '2PstTrem',
  '2lb 14',
  '3 Pots',
  '3 lbs 10',
  '3/16RO',
  '3/8RO',
  '3/8Thimble',
  '30',
  '30.5',
  '30Scale',
  '32',
  '34',
  '34 Scale',
  '35 Scale',
  '375BMH',
  '3AFlameMap',
  '3BMH',
  '3Lb  4',
  '3Lb  8',
  '3Lb 0',
  '3Lb 10',
  '3Lb 12',
  '3Lb 14',
  '3Lb 15',
  '3Lb 2',
  '3Lb 3',
  '3Lb 4',
  '3Lb 6',
  '3Lb 8',
  '3Lb 9',
  '3Lb14',
  '3Lb8',
  '3Lbs 14',
  '3POT',
  '3POTS',
  '3Pots',
  '3Way Switch',
  '4  lbs',
  '4 St',
  '4 pk',
  '4-21',
  '4/20',
  '4/20/30',
  '4/20/34',
  '4/21',
  '4/21/30',
  '4/21/32',
  '4/21/34',
  '4/21/34-A',
  '4/21/34-M',
  '4/21/34-SA',
  '4/22',
  '4/22/34',
  '4/24',
  '4/24/34',
  '4/24/34-SAMT',
  '4/30.5',
  '43MM',
  '4Lb',
  '4Lb 0',
  '4Lb 10',
  '4Lb 12',
  '4Lb 13',
  '4Lb 14',
  '4Lb 15',
  '4Lb 2',
  '4Lb 4',
  '4Lb 6',
  '4Lb 8',
  '4Lb 8 --NEEDS PRO DEV',
  '4Lb 8..',
  '4Lb 9',
  '4Lb ??',
  '4Lb12',
  '4Lb14',
  '4Lb4',
  '4Pots',
  '4ST',
  '4St',
  '4lbs 10',
  '4lbs 4',
  '5 St',
  '5/20',
  '5/20/35',
  '5/21',
  '5/21/34',
  '5/21/35',
  '5/21/35-SA',
  '5/22',
  '5/22/34.5',
  '5/24',
  '5/24 SingleCut',
  '5/24/34',
  '5/24/34-35',
  '5/24/34-SA',
  '5/24/34.5',
  '5/24/35',
  "50's",
  '50s',
  '51 Narrow 2.438NP',
  '51BMH',
  '51Ctrl',
  '51NP',
  '51P FC',
  '51P NP',
  '51PCtrl',
  '52',
  '52V',
  '54mmNP',
  '550',
  '553',
  '57',
  '5730-P',
  '5AFlameMap',
  '5Lb',
  '5Lb 0',
  '5Lb 02',
  '5Lb 2',
  '5Lb 4',
  '5Lb 8',
  '5ST',
  '5St',
  '5W Switch',
  '5Way',
  '5WaySw',
  '5st',
  '6 St',
  '6/24/34',
  '6/24/34.5',
  "60'S JJ",
  "60's",
  "60'sJJ",
  '6030-J',
  '6034-J',
  '60JJ',
  '60s',
  '60s J',
  '60s J/J',
  '60s NO PU',
  '60s P/J',
  '60s WR',
  '60sJ/J',
  '60sP/J',
  '61VinNP',
  '62',
  '64NMH',
  '672',
  '6PTrem',
  '6St',
  "70's",
  '70s',
  '70s J/J',
  '70s P/J',
  '71',
  '72T',
  '72T Custom',
  '72T DLX',
  '7825',
  '8/6St',
  '89 Classic',
  '8St',
  '96',
  'AB',
  'ACT/BAT',
  'ACT/BATT',
  'ACrv',
  'ALL Tele Routs&Layout',
  'AMSTD TeleBridge',
  'Alder',
  'Alder  XLite',
  'Alder  XXLite',
  'Alder -PG',
  'Alder 4Lb 12',
  'Alder 4Lb 14',
  'Alder Lite',
  'Alder Lite 3Lb 15',
  'Alder Lite 3Lb 4',
  'Alder Lite 4Lb 12',
  'Alder Lite 4Lb 4',
  'Alder Lite/3AFlameMap',
  'Alder Lite/3AQuiltMap',
  'Alder Lite/5AFlameMap',
  'Alder Lite/5AQuiltMap',
  'Alder Lite/5aSPLT',
  'Alder Lite/6AQuiltMap',
  'Alder Lite/Alder',
  'Alder Lite/BlackVen',
  'Alder Lite/BlackVen/Alder',
  'Alder Lite/BlackVen/CS',
  'Alder Lite/BlackVen/CS THICK',
  'Alder Lite/Burl Map',
  'Alder Lite/CS',
  'Alder Lite/CS 3Lb 4',
  'Alder Lite/CS THICK',
  'Alder Lite/CS THIN',
  'Alder Lite/CS/CS',
  'Alder Lite/Ven/5AFlameMap',
  'Alder Lite/Ven/5AQuiltMap',
  'Alder Lite/Ven/CS',
  'Alder XLite',
  'Alder XLite 4Lb 2',
  'Alder XLite/5AFlameMap',
  'Alder XLite/5AQuiltMap',
  'Alder XLite/6AFlameMap',
  'Alder XLite/Alder',
  'Alder XLite/Alder XLite',
  'Alder XLite/CS',
  'Alder XLite/Flat Map',
  'Alder XLite/SpaltMap',
  'Alder XLite/Ven/5AFlameMap',
  'Alder XXLite',
  'Alder/3AFlameMap',
  'Alder/3AFlameMap 0.125',
  'Alder/3AQuiltMap',
  'Alder/4AFlameMap',
  'Alder/5AFlameMap',
  'Alder/5AFlameMap .375',
  'Alder/5AMyrtlewood',
  'Alder/5AQuiltMap',
  'Alder/5ASpaltedMapedMap',
  'Alder/6AFlameMap',
  'Alder/Alder',
  'Alder/Ash',
  'Alder/BlackVen/Alder',
  'Alder/BlackVen/Ash',
  'Alder/BlackVen/CS',
  'Alder/CS',
  'Alder/Map',
  'Alder/Map A',
  'Alder/Ven/CS',
  'AlderCS',
  'AlderXLite',
  'AlderXLite/5AFlameMap',
  'AlderXLite/Alder',
  'AlderXLite/Alder Lite',
  'AlderXLite/BlackVen',
  'AlderXLite/BlackVen/CS',
  'AllCarves',
  'AllSTH',
  'AngeledNP',
  'AngleH',
  'Angled NP',
  'AngledH',
  'AngledNP',
  'AnyBlank/Padauk',
  'ArmB',
  'Armitage',
  'Ash',
  'Ash  Lite',
  'Ash  XLite/Ven/5AFlameMap',
  'Ash 1 Pc',
  'Ash 4Lb 14',
  'Ash 4Lb 4',
  'Ash CLT',
  'Ash Lite',
  'Ash Lite 4Lb 10',
  'Ash Lite 4Lb 2',
  'Ash Lite 4Lb 8',
  'Ash Lite/3AFlameMap',
  'Ash Lite/5AFlameMap',
  'Ash Lite/5AQuiltMap',
  'Ash Lite/Ash',
  'Ash Lite/Ash Lite',
  'Ash Lite/BlackVen/CS',
  'Ash Lite/CS',
  'Ash Lite/HVen/CS',
  'Ash Lite/SpaltMap',
  'Ash Lite/Ven/3AQuiltMap',
  'Ash Lite/Ven/5AFlameMap',
  'Ash Lite/Ven/5AQuiltMap',
  'Ash Lite/Ven/Ash',
  'Ash Lt',
  'Ash XLite',
  'Ash XLite /5AFlameMap',
  'Ash XLite 3Lb 12',
  'Ash XLite/3AFlameMap',
  'Ash XLite/5AFlameMap',
  'Ash XLite/5AQuiltMap',
  'Ash XLite/Ash',
  'Ash XLite/Ash Lite',
  'Ash XLite/Ash XLite',
  'Ash XLite/BlackVen/Ash',
  'Ash XLite/BlackVen/CS',
  'Ash XLite/CS',
  'Ash XLite/CS THICK',
  'Ash XLite/CS/CS',
  'Ash XLite/HVen/CS',
  'Ash XLite/Map Quilt 5A',
  'Ash XLite/Ven/5AQuiltMap 4Lb 8',
  'Ash XLite/Ven/5ASpaltedMapedMap',
  'Ash XLite/Ven/CS',
  'Ash XLite/Ven/MG BseMap',
  'Ash XLite/Ven/Spalt',
  'Ash XXLite',
  'Ash XXXLite',
  'Ash Xtra Lite',
  'Ash/ 5AQuiltMap',
  'Ash/3AFlameMap',
  'Ash/5AFlameMap',
  'Ash/5AQuiltMap',
  'Ash/5AQuiltMap - 4lb 0',
  'Ash/5ASpaltedMapedMap',
  'Ash/6AFlameMap',
  'Ash/Ash',
  'Ash/BlackVen/Ash',
  'Ash/BlackVen/CS',
  'Ash/CS',
  'Ash/Map',
  'Ash/Myrtlewood 5A',
  'Ash/SpaltMap',
  'Ash/SpltMap',
  'AshXLite',
  'AshXLite CLT/5AFlameMapMap',
  'AshXLite CLT/Spalted Map',
  'AshXLite/5AFlameMap',
  'AshXLite/Ash',
  'AshXLite/Ash Lite',
  'AshXLite/BlackVen/CS',
  'AshXLite/CS',
  'AshXLite/MapQuilt MG',
  'AshXXLite',
  'B Tele',
  'B-Bender',
  'B90/Dum',
  'BA BMH',
  'BADASS',
  'BAT',
  'BATT',
  'BB',
  'BBMF5',
  'BC',
  'BGSP',
  'BIGSBY',
  'BIGSBY B5',
  'BIND',
  'BMH',
  'BT',
  'BT140',
  'BT1875',
  'Babicz',
  'Baretta H',
  'Bass VI',
  'Basswood',
  'Basswood XLite',
  'Basswood/6AQuiltMap',
  'Basswood/CS',
  'Bat',
  'Batt',
  'Battery Covers',
  'BevEdge',
  'BiSonic',
  'BiSonic/B90',
  'Bigsby',
  'Bigsby B5',
  'Bigsby B5/B16',
  'Bigsby B5/TOM',
  'BigsbyB5',
  'BindShelf',
  'Bindshelf',
  'Bindshelf F&B',
  'Black Kor/5AQuiltMap',
  'Black Korina',
  'Black Walnut/5AQuiltMap',
  'Blind Faith',
  'BrawlerSC',
  'Bridge',
  'Bridge Wire Only',
  'BridgeGnd',
  'CALI-ST',
  'CC/CCS',
  'CCH/CCH',
  'CCH/CCH/S',
  'CCH/S',
  'CCHII/S',
  'CCHII/TVJ',
  'CCS',
  'CE',
  'CE RC',
  'CE RC/TopPots',
  'CE-RC',
  'CE2',
  'CHATHAM P',
  'CL',
  'CLT',
  'CNC',
  'CNC Only',
  'COMFY',
  'CP',
  'CS',
  'CS Magnets',
  'CS/1A Map',
  'CS/1AMap',
  'CS/2AFlameMap',
  'CS/3AFlameMap',
  'CS/5AFlameMap',
  'CS/5AQuiltMap',
  'CS/6AFlameMap',
  'CS/6AQuiltMap',
  'CS/BlackVen/CS',
  'CS/BlackVen/CS/BlackVen/CS',
  'CS/CS',
  'CS/CS THICK',
  'CS/CS THIN',
  'CS/FSMap',
  'CS/FlatMap',
  'CS/MGQuiltMap',
  'CS/Spalt&Flm Map',
  'CS/Spalted Map',
  'CSWhiteKor/CSWhiteKor',
  'CT',
  'CUST/CUST',
  'CUSTOM MOD 2.062',
  'Cabronita',
  'Calavera',
  'Callaham Ferrules',
  'Chambered',
  'Chop T-Luxx',
  'Chubby',
  'Coda 4 DLX J',
  'Coda 4 J',
  'Coda 4 P',
  'Coda 5',
  'Coda 5 DLX J',
  'Coda 5 J',
  'Coda 5XLite DLX J',
  'Coda P DLX',
  'Comfy',
  'Contour Heel',
  'Cornerstone',
  'CrvHeel',
  'CrvTop',
  'Cust CtrlRout',
  'CustNP',
  'Custom S/S/S',
  'Custom3/8STH',
  'DBL CUT',
  'DC',
  'DC-1',
  'DEFINITION',
  'DINKY',
  'DLX',
  'DLX J',
  'DMH/DMH',
  'DMH/S',
  'DS1',
  'DT',
  'DarkStar/DarkStar',
  'Darkstar/Darkstar',
  'DblBender',
  'DblCutaway',
  'DeArmTVJ/DeArmTVJ',
  'DeArmTVJ/S',
  'Deep PUs',
  'DeepS',
  'Del Mar',
  'Dinky',
  'DkStar/DkStar',
  'DmS/S',
  'DoubleCut',
  'DoubleNeck',
  'Drop Wing',
  'DuoSonic',
  'EANJ HCrv',
  'ELL',
  'EMG-35/EMG-35',
  'EMG-40/EMG-40',
  'EMG-40/EMG-40-SST',
  'EMG-40/EMG-MM5',
  'EMG-40s',
  'EMG-45/EMG-45',
  'EMG-LJV/EMG-SJV',
  'EMG-MM',
  'EMG-SJV/EMG-MM',
  'EMG35/EMG35',
  'EMG40/EMG40',
  'EMGMM',
  'EMGS/EMGS/EMGS',
  'EWPine',
  'EWPine Lite',
  'EWPine Lite 4Lb 6',
  'EWPine/EWPine',
  'EWPine/Map',
  'EWhitePine/EWhitePine',
  'EXPLORER',
  'EdgeBevels',
  'Entry',
  'Esquire',
  'F&RC',
  'F-Hole',
  'F-Jack',
  'FAS HT',
  'FAmPro Bridge',
  'FB/FB',
  'FC',
  'FC TL',
  'FC&RC',
  'FC/RC',
  'FHT',
  'FHole',
  'FJ',
  'FJack',
  'FLAT',
  'FLOYD',
  'FMH/H',
  'FMH/S',
  'FMH/S/S',
  'FWD.20BMH/STH',
  'FWRH/S',
  'FX',
  'Fender HT',
  'Filtertrons',
  'Firebird',
  'Flat',
  'Flat NP',
  'Flat Neck Pocket',
  'Flat Top',
  'FltTop',
  'FrontJack',
  'FtJack',
  'G5',
  'GB',
  'GB DoublCut',
  'GB SC',
  'GB-TFV-TAL',
  'GF/GF',
  'GF/GF/GF',
  'GF/GF/S',
  'GF/H',
  'GF/S',
  'GRESHAM J',
  'GRESHAM J5',
  'GTO',
  'GTX-TAS-TL',
  'Gen-5',
  'Gen-5 RevC',
  'GenMah',
  'GenMah Lite',
  'GenMah/3AFlameMap',
  'GenMah/5AFlameMap',
  'GenMah/5AFlameMap .375',
  'GenMah/5AQuiltMap',
  'GenMah/CS',
  'GenMah/GenMah',
  'GenMah/PlainMap',
  'GenMah/VinFlame',
  'Geronimo',
  'GibsonH',
  'GlnDale',
  'GndWire',
  'Gotoh HT',
  'GotohDlx',
  'GotohHT',
  'GotohTeleBR',
  'GotohTrem',
  'Grand',
  'Graphite Rods',
  'Grove ST',
  'H',
  'H/DMH',
  'H/DeepS',
  'H/H',
  'H/H Direct',
  'H/H/H',
  'H/H/S',
  'H/P90/H',
  'H/RevS',
  'H/S',
  'H/S/H',
  'H/S/HCrvHorn',
  'H/S/S',
  'H/SS/S',
  'H/SS/TS',
  'H/TVJ',
  'HB Bridge',
  'HBB',
  'HBridge',
  'HCrv',
  'HDm/HDm',
  'HDm/S',
  'HDm/S/HDm',
  'HH',
  'HS',
  'HS A Bridge',
  'HS Bridge',
  'HS TREM',
  'HS-A',
  'HS-ABMH',
  'HS-B',
  'HSBMH',
  'HSH/UNI',
  'HSHT',
  'HSTrem',
  'HT',
  'HT CalVint',
  'HT F',
  'HT FAS',
  'HT FAS??',
  'HT GoToh',
  'HT Gotoh',
  'HT Gotoh 5S/Mod2.062',
  'HT Gotoh5S',
  'HT Gotoh5S/Mod2.062',
  'HT Gotoh5S??',
  'HT HS',
  'HT MJT2.167',
  'HT MJT2.187',
  'HT Mod2.062',
  'HT Schaller',
  'HT-FAS',
  'HT-HS',
  'HTF',
  'Hd/Hd',
  'Hf',
  'Hf/Hf',
  'Hf/S/Hf',
  'HipShot 2P Trem',
  'HornCrv',
  'HotRod Tele',
  'J',
  'J 5-22',
  'J 60s',
  'J 70s',
  'J AMENT',
  'J NP',
  'J-4',
  'J-4 WLE',
  'J-421',
  'J-5',
  'J-5 WLE',
  'J-5/24/34-35',
  'J/J',
  'J/MM',
  'J/MM/J',
  'J/MM5',
  'J/P/J',
  'J4',
  'J430',
  'J5',
  'J5-20-35',
  'JBAO',
  'JBAO-L',
  'JBHeel',
  'JBO',
  'JBU',
  'JBU5',
  'JD',
  'JJ',
  "JJ PU's",
  'JM BMH',
  'JM Bridge',
  'JM Bridge/Trem',
  'JM TREM',
  'JM Trem',
  'JM Trem/BMH',
  'JM-BMH',
  'JM/JM',
  'JM/S',
  'JM/S/S',
  'JM/WRH',
  'JMBMH',
  'JMBMH.375/NoTrem',
  'JMBMH/NoTrem',
  'JMBMH/Trem',
  'JMBridge',
  'JMTrem',
  'JMTrem/JMBMH',
  'JNoSTH',
  'JT4',
  'JT5',
  'JX',
  'JZASP-B',
  'JZJG',
  'Jack',
  'JagNP',
  'Jaguar',
  'JazzB',
  'JazzCaster',
  'JazzM',
  'JazzMaster',
  'JazzMaster2PTrem',
  'JazzMasterJZMAO',
  'JazzMasterJZMO',
  'JazzMasterLEFTY',
  'JazzMasterNPO',
  'JazzMasterNoTrem',
  'JazzMasterStlTop',
  'JazzMasterTOM',
  'JazzMasterTrem',
  'Jim D',
  'JimD',
  'JimD 0.125',
  'JimD TOM',
  'JoaquinD6',
  'JoaquinD8',
  'JoaquinD8/6',
  'Junction J',
  'KH',
  'KhyMah',
  'KhyMah Lite',
  'KhyMah Lite 4Lb 8',
  'KhyMah Lite/3AFlameMap',
  'KhyMah Lite/3AQuiltMap',
  'KhyMah Lite/5AFlameMap',
  'KhyMah Lite/5AQuiltMap',
  'KhyMah Lite/6AQuiltMap',
  'KhyMah Lite/Ash',
  'KhyMah Lite/CS',
  'KhyMah Lite/KhyMah',
  'KhyMah Lite/PlainMap',
  'KhyMah Lite/SpaltMap',
  'KhyMah Lite/Ven/5AFlameMap',
  'KhyMah Lite/Ven/5AFlameMap +PH Cap',
  'KhyMah Lite/Ven/5AQuiltMap',
  'KhyMah XLite',
  'KhyMah/3AFlameMap',
  'KhyMah/5AFlameMap',
  'KhyMah/5ANatFlameMap',
  'KhyMah/5AQuiltMap',
  'KhyMah/6AFlameMap',
  'KhyMah/Alder',
  'KhyMah/Ash',
  'KhyMah/BlackVen/CS',
  'KhyMah/CS',
  'KhyMah/CS-Buckeye',
  'KhyMah/KhyMah',
  'KhyMah/Map',
  'KhyMah/MapVen/KhyMah',
  'KhyMah/PlainMap',
  'L-Bolt',
  'L6',
  'LB-Pots',
  'LCC/LCCS',
  'LCC/P90',
  'LCCS',
  'LEFTY',
  'LF S',
  'LFT',
  'LG',
  'LG FLAT',
  'LKSpecial',
  'LP',
  'LP Jr',
  'LP Jr. DC',
  'LP-Switch',
  'LPHCap',
  'LPJr',
  'LS-13',
  'LS-13 LR',
  'Lap Steel',
  'Large',
  'Large P90/S',
  'LeftPHCap',
  'LeftWoodCover',
  'Lefty',
  'Lincoln',
  'Lion',
  'LollarGF/S',
  'LollarH',
  'LollarH/S',
  'LrgFerrules',
  'Luxx Custom',
  'Lydia Custom',
  'Lynx',
  'M/J',
  'M4',
  'M4V',
  'M4X',
  'M4XLite',
  'M5',
  'M5V',
  'M6',
  'MBrdge/NoTrem',
  'MG',
  'MH',
  'MH/H',
  'MH/MH',
  'MH/MH/H',
  'MH/MH/MH',
  'MH/MH/S',
  'MH/S',
  'MH/S/S',
  'MH/SS/S',
  'MHB',
  'MHB/MHB',
  'MHB/S',
  'MJT2.187',
  'MLRD4',
  'MOD',
  'MP4',
  'MPJ4',
  'MST',
  'MUSTANG',
  'Mach II',
  'Mach II-JZ',
  'Macon JR',
  'Macon Jr DC',
  'Macon SC',
  'Macon Vintage',
  'Mann 2P Trem',
  'Map/Map',
  'Match Body Wood',
  'Melody Maker',
  'MelodyM',
  'Midwestern',
  'Midwestern2',
  'Mirror',
  'Model-T Tele',
  'Morningstar',
  'Mustang',
  'NAsh',
  'NAshVILLE',
  'NAshVILLE S/S/H',
  'NAshville',
  'NAshvilleTOM',
  'NBMH',
  'NBatt',
  'NEEDS DEFINITION',
  'NFS',
  'NMH',
  'NO Controls Holes',
  'NO F-HOLE',
  'NO F-Hole',
  'NO FC',
  'NO FHOLE',
  'NO PU',
  "NO PU's",
  'NO PUs',
  'NO R/O',
  'NO RO',
  'NO STH',
  'NO Step',
  'NO WCrv',
  'NP & FC Only',
  'NP & FHole ONLY',
  'NPBO',
  'NPO',
  'NPU',
  'NR',
  'NS 4/5St',
  'NS 6St',
  'NS Wide 5',
  'NYC',
  'NYC-G1',
  'NYC-G2',
  'NYLN-BRDWY',
  'NYLN-TELE',
  'NarNP',
  'NarrowNP',
  'Nashville',
  'Neck P90/NP ONLY',
  'Neck Plate',
  'NeckS',
  'Need Definition',
  'No B',
  'No Batt',
  'No CR',
  'No F-Hole',
  'No Ferrule Recess',
  'No Neck Holes',
  'No NeckPocket',
  'No PU',
  "No PU's",
  'No RO',
  'No SideJack',
  'No Stop Tail Holes',
  'NoACrv',
  'NoArmB',
  'NoBAT',
  'NoBMH',
  'NoBatt',
  'NoBridge',
  'NoC',
  'NoCarves',
  'NoControl',
  'NoCtrl',
  'NoCtrlR',
  'NoCtrls',
  'NoF hole',
  'NoFC',
  'NoFrontJack',
  'NoGndWire',
  'NoJack',
  'NoJackCup',
  'NoLowB',
  'NoNH',
  'NoNMH',
  'NoNP',
  'NoNPU/60s J',
  'NoNPU/S',
  'NoPG',
  'NoPOTS',
  'NoPU',
  'NoPU/P90',
  'NoPUs',
  'NoPickGuard',
  'NoPotHples',
  'NoPots',
  'NoPotsAlderXLite/CS',
  'NoPu',
  'NoR/O',
  'NoRO',
  'NoRouts',
  'NoSTH',
  'NoShoulderCtrl',
  'NoSideJack',
  'NoSwitch',
  'NoTRA',
  'NoTopCtrl',
  'NoTopR/O',
  'NoTopRO',
  'NoTrem',
  'NoWCrv',
  'NoWIreR',
  'NoWireR',
  'NonTrem',
  'NovakMH/S',
  'OFFSET',
  'OGRW',
  'OGRWW',
  'OGRWW Lite',
  'OGRWW/CS',
  'OGRWW/CS/CS',
  'OGRWW/OGRWW',
  'OGRWWW',
  'OGRWWWW',
  'OGRWWWW/BSE RDW',
  'OGRWWWW/OGRWWWW',
  'OGRWWWWW',
  'OGRWWWWedwood',
  'OGRWWWWedwood/5AQuiltMap',
  'OGRWWWWedwood/Am Sycamoreamoreamoreamoreamore',
  'OGRWWWWedwood/CS',
  'OGRWWWWedwood/Sycamoreamoreamoreamore',
  'OS',
  'Off Standard',
  'OffSet',
  'Okou',
  'Okoume',
  'Okoume/BlackVen/CS',
  'Okoume/BlackVen/Okoume',
  'Okoume/CS',
  'Okoume/Okoume',
  'Okume/CS',
  'Olinto',
  'Original MJ',
  'Oz',
  'Oz Cabronita',
  'P',
  'P Bass',
  'P Bridge',
  'P NP',
  'P-4',
  'P-5',
  'P-5/24/34-35',
  'P-5734',
  'P-ACrv',
  'P/DC',
  'P/DualCoil',
  'P/EMG-LJV',
  'P/J',
  'P/NDC',
  'P/SB',
  'P/T',
  'P/TVJ',
  'P4',
  'P5',
  'P51',
  'P52',
  'P52 TeleBass',
  'P54',
  'P55',
  'P90',
  'P90/DMH',
  'P90/FmH',
  'P90/H',
  'P90/Hf',
  'P90/JM',
  'P90/MH',
  'P90/MHB',
  'P90/NearBridgeP90',
  'P90/P90',
  'P90/P90/P90',
  'P90/P90/S',
  'P90/RevS',
  'P90/S',
  'P90/S/S',
  'P90/SS/S',
  'P90/TVJ',
  'P90/TightP90',
  'P90HX/P90HX',
  'P90Metal/H',
  'P90Plastic/H',
  'PBAO',
  'PBMH',
  'PBO',
  'PBU',
  'PBU5',
  'PBVU',
  'PDC',
  'PG',
  'PHC',
  'PJ',
  'PJ4',
  'PJ5',
  'PJBAO',
  'PJBU',
  'POTS',
  'PT4',
  'Paint',
  'Paulownia',
  'Peg Head Cap',
  'Pine Lite',
  'Pots',
  'Premier',
  'R Bat',
  'RC',
  'RC Cover',
  'RCrv',
  'Rainmaker',
  'Rainmaker II',
  'Rainmaker II-5St',
  'Rear Ctrl',
  'Rec Floyd',
  'RecFloyd',
  'RecessFloyd',
  'RecessFloyd NF',
  'Recessed Floyd',
  'RecessedFloyd',
  'RevP',
  'RndJack',
  'Rosewood Tele',
  'RoundJack',
  'RoundedJack',
  'Router Bump',
  'RouterBump',
  'Rst. Alder',
  'RstAlder',
  'RstAlder Lite/BlackVen/CS',
  'RstAlder/BlackVen/CS',
  'RstAlder/RstAlder',
  'RstAlderCS',
  'RstAsh',
  'RstAsh /5AFlameMap',
  'RstAsh Lite',
  'RstAsh Lite/BlackVen/CS',
  'RstAsh Lite/RstAsh',
  'RstAsh Lite/Ven/CS',
  'RstAsh XLite/5AFlameMap',
  'RstAsh XLite/CS',
  'RstAsh/4AMapFlm',
  'RstAsh/5AFlameMap',
  'RstAsh/5AMyrtlewood',
  'RstAsh/5AQuiltMap',
  'RstAsh/6AFlameMap',
  'RstAsh/6AFlmMap',
  'RstAsh/BlackVen/CS',
  'RstAsh/BlackVen/RstAsh',
  'RstAsh/CS',
  'RstAsh/Map FS-4lb 0',
  'RstAsh/Myrtlewood 5A',
  'RstAsh/RstAsh',
  'RstAsh/Ven/CS',
  'RstAshXLite /5AFlameMap',
  'RstAshXLite /6AFlameMap',
  'RstEWPine',
  'RstEWPine 4Lb 2',
  'RstEWPine/3AFlameMap',
  'RstEWPine/CS',
  'RstEWPine/CS/CS',
  'RstEWPine/RstEWPine',
  'RvrsP/J',
  'S',
  'S/AngledP90',
  'S/BGS',
  'S/DeepS',
  'S/GotohH',
  'S/H',
  'S/H/H',
  'S/HDm',
  'S/Hf',
  'S/J',
  'S/MH',
  'S/P90',
  'S/Rev S',
  'S/RevS',
  'S/S',
  'S/S/AngleH',
  'S/S/H',
  'S/S/HDm',
  'S/S/Hd',
  'S/S/Hf',
  'S/S/RevS',
  'S/S/S',
  'S/S/TeleS',
  'S/S/U',
  'S/S/UNI',
  'S/TVJ',
  'S/WRH',
  'SB-4',
  'SB-JazzMasterKM6 NP',
  'SB/SB',
  'SBAO',
  'SBAO-1H',
  'SBAO-HT',
  'SBAO-L',
  'SBAO-NPO',
  'SBAO-TL',
  'SBAO-U',
  'SBO',
  'SBO-PN',
  'SC',
  'SD1',
  'SD2',
  'SDMH/S',
  'SDMH/S/S',
  'SDm/SDm/HDm',
  'SEMI-Hollow',
  'SF/SF/SF',
  'SG - Speed Guide',
  'SG V5',
  'SHOP RATE',
  'SIDEJACK',
  'SIDEJack',
  'SJ',
  'SJ/LJ',
  'SJack',
  'SKY-HAS',
  'SKY-TAL',
  'SM',
  'SM14',
  'SOLID',
  'SRV Trem',
  'SRVTrem',
  'SS/H',
  'SS/S',
  'SS/SS/H',
  'SS/SS/S',
  'SS/SS/SS',
  'SS/SS/TS',
  'SSB',
  'SSH',
  'SSS',
  'ST',
  'STBBridge',
  'STEPPED',
  'STH',
  'STP',
  'STRAT',
  'STRAT VIN',
  'STRATHAM 57',
  'STU',
  'STU-LH',
  'STU-NT',
  'SV',
  'SWITCH',
  'SWS',
  'SX',
  'SanB1 KM-6',
  'SanB2 KM-6',
  'Sanded',
  'Sassafras',
  'Semi-Hollow',
  'SemiComfy',
  'SetNeck',
  'ShNP',
  'ShallowNP',
  'ShortCtrl',
  'Side Jack',
  'SideJack',
  'Silvertone/Silvertone',
  'SingleCut',
  'Slanted H',
  'Small',
  'Soapbar',
  'Solar Arrow',
  'Solar Flight',
  'Solar Sonic',
  'SolarArrow-NP',
  'Solid',
  'Special',
  'Spoke',
  'SpokeTRA',
  'Springfield Strat',
  'Square',
  'Std',
  'Stepped',
  'StlGuard',
  'StlTop',
  'StlTop Deluxe',
  'StlTop TL',
  'Strat',
  'Strat 2PTrem',
  'Strat 50s',
  'Strat 57',
  'Strat 60s',
  'Strat 62',
  'Strat Heel',
  'Strat NP',
  'Strat Trem',
  'Strat VTrem',
  'Strat Vin',
  'StratArmB',
  'StratBMH',
  'StratNP',
  'StratS/S',
  'StratTrem',
  'StratWCrv',
  'SugarPine',
  'SugarPine Lite',
  'SugarPine XLite',
  'SugarPine/5AQuiltMap',
  'SugarPine/SugarPine',
  'Super Strat',
  'Switch',
  'T-BMH',
  'T-Rod',
  'T-Strat',
  'T4',
  'T4/21/34-M',
  'T4/T4',
  'T4/T4T',
  'T5',
  'T5/T5',
  'T69',
  'T72',
  'T72 DLX',
  'T72 Deluxe',
  'TB',
  'TB/TB',
  'TBAO',
  'TBAO-C',
  'TBAO-CN',
  'TBAO-L',
  'TBAO-NPO',
  'TBAO-TL',
  'TBATT',
  'TBBAO',
  'TBO',
  'TBO-BW',
  'TBO-PN',
  'TBO-TLFM',
  'TBatt',
  'TC4',
  'TC5',
  'TC63',
  'TC72',
  'TEASP-B',
  'TELE',
  'TELE 60s',
  'TELHAM 52T',
  'TEU',
  'TEU Ash XLiteTE',
  'TEU BB',
  'TEU-LH',
  'TFC',
  'TKS/S',
  'TKSFlat/TKSFlat',
  'TL',
  'TL 70s',
  'TL 70s Hybrid',
  'TL Ctrl',
  'TL DLX FH',
  'TL DLX NoFH',
  'TL FH',
  'TL Hybrid70s',
  'TL NoFH',
  'TL Strat',
  'TL Tele',
  'TL-Clamshell',
  'TLCtrl',
  'TLF Tele',
  'TOM',
  'TOM HT',
  'TOP',
  'TOP & BACK .060 BIND SHELF',
  'TOP & BACK .060 Bindshelf',
  'TOPPED',
  'TOPPED/Black Veneer',
  'TPSIG',
  'TPTOM',
  'TRA',
  'TREM',
  'TT',
  'TVJ',
  'TVJ/H',
  'TVJ/RevS',
  'TVJ/S',
  'TVJ/TVJ',
  'TVJ/TVJ/TVJ',
  'TVJ/WRH',
  'TX',
  'TXB4',
  'TXB5',
  'Tele',
  'Tele 3DEG',
  'Tele 60s',
  'Tele 70s Hybrid',
  'Tele BMH',
  'Tele Comfy',
  'Tele FC',
  'Tele NP',
  'Tele Paul',
  'Tele TL',
  'Tele Vin',
  'TeleBMH',
  'TeleBMH/STH',
  'TeleBR',
  'TeleBass',
  'TeleBridge',
  'TeleCtrl',
  'TeleJack',
  'TeleM',
  'TeleMaster',
  'TeleMstr',
  'TeleNP',
  'TeleS/H',
  'TeleS/NoPu',
  'TeleSTH',
  'TestBass',
  'TestBody',
  'Texola TeleM',
  'ThickTop',
  'ThinlineFC',
  'Toggle',
  'Top',
  'Top & Back',
  'Top&Ven',
  'TopCaster',
  'TopCrv',
  'TopFloyd',
  'Topped',
  'Trem',
  'Trem2.062',
  'Truxton',
  'Truxton Tele',
  'Truxton-B Tele',
  'Truxton-L Tele',
  'Truxton-SP Tele',
  'Truxton-TL Tele',
  'U/S',
  'U/SS/S',
  'UNI',
  'UNI/H',
  'UNI/J',
  'UNI/S',
  'UNI/S/S',
  'UNI/S/UNI',
  'UNI/TVJ',
  'UNISm/S',
  'UNIWRH/WRH',
  'USA',
  'USU',
  'Underdog',
  'Uni-Sm',
  'Uni/S',
  'UniLg',
  'UniMd',
  'UniMd/S',
  'UniSm/S',
  'UniSm/S/S',
  'V-Trem',
  'V4',
  'V5',
  'V51',
  'V52',
  'V52 Snakehead',
  'V57',
  'VIN',
  'VTG',
  'VTrem',
  'Verdine',
  'Vin',
  'Vin Tele STH',
  'Vin Trem',
  'VinTrem',
  'Vintage',
  'VolPotOnly',
  'W/C',
  'W/R',
  'WCrv',
  'WR',
  'WR/BT',
  'WRH',
  'WRH/P90',
  'WRH/S',
  'WRH/S/S',
  'WRH/S/WRH',
  'WRH/SS/S',
  'WRH/WRH',
  'WRHB/WRHB',
  'WRHC',
  'WRSH',
  'WebCaster',
  'Webcaster',
  'White Korina',
  'White Korina/White Korina',
  'White Limba',
  'Will Lee',
  'WireR',
  'WireRout',
  'WireRout TremToCtrl',
  'Wood',
  'Wood Cover',
  'WoodCover',
  'XJ4',
  'XJ5',
  'XS DINKY',
  'XSC',
  'XSC DINKY',
  'XSS',
  'XTC',
  'XXLite',
  'Xtra Top Ctrl',
  'YES Jack',
  'ZK30-DC',
  'v2',
];
