import {
  Button, Dropdown, Menu,
  Space,
} from 'antd';
import type { MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons/lib';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  hideUnselectedOptionsAtom, newBodyAtom, newNeckAtom, partEditModeAtom,
  useTestDataAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { ICustomerRecord } from 'shared/types/dbRecords';
import theme from 'shared/theme';
import { ItemDetailsLabel } from './PartDetailFields/styledComponents';
import { currentCustomerAtom, customersAtom } from '../../../shared/state/customerState';

const _ = require('lodash');
const shortid = require('shortid');

interface ComponentInterface {
  partType: 'body'|'neck';
  partId: any;
  copy: boolean;
}

const CustomerPartWrapper = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 32px);
  margin-left: 32px;
  gap: 32px;
  &:first-of-type {
    margin-bottom: 8px;
  }
`;

const CustomerDropdown = styled(Dropdown)`
  &:disabled {
    padding-right: 0;
    background-color: ${theme.palette.neutral.white};
    color: ${theme.palette.neutral.black};
    & svg {
      opacity: 0;
    }
    &:hover {
      background-color: ${theme.palette.neutral.white};
      color: ${theme.palette.neutral.black};
    }
  }
`;

const CustomerDetailsCol = styled(FlexColumn)`
  width: auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

interface IComponent {
  changeCallback: (e: any) => void;
  disabled: boolean;
  id: string;
  label: string;
  placeholder: string;
  value?: any;
  valueOptions: any[];
}

interface IValueOption {
  label: string;
  keycode: string;
  shade: boolean;
}

const DetailDropdown = styled(Dropdown)`
  width: auto;
  &:disabled {
    color: ${theme.palette.neutral.black};
  }
`;
const DropdownSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
`;

const PartDetailDropdownItemRow = styled(FlexRow)`
  justify-content: flex-start;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.015)' : 'transparent')};
  padding: 0 12px;
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      text-align: right;
      font-weight: bold;
      width: 80px;
    }
  }
  & span {
    width: 1px;
    height: 32px;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;

const DropdownMenuItem = ({ label, keycode, shade }: IValueOption) => (
  <PartDetailDropdownItemRow shade={shade} key={`${keycode}-value-option`}>
    <p>{keycode}</p>
    <span />
    <p>{label}</p>
  </PartDetailDropdownItemRow>
);
export default ({ partType, partId, copy }: ComponentInterface) => {
  const customers = useRecoilValue(customersAtom);
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const editMode = useRecoilValue(partEditModeAtom);
  const partStateAtom = partType.match(/body/i) ? newBodyAtom : newNeckAtom;
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(partStateAtom);

  const onCustomerSelect = (e: any) => {
    const cust = _.find(customers, (c: ICustomerRecord) => c.id === e.key);
    setCurrentCustomer(cust);
    setPartState({ ...partState, customerId: cust.id });
  };

  const menuItems: MenuProps['items'] = customers.map((c: ICustomerRecord, index) => ({
    label: <DropdownMenuItem label={c.CompanyName} keycode={c.id} shade={index % 2 === 1} />,
    key: c.id,
  }));

  const menuProps = {
    items: menuItems,
    onClick: onCustomerSelect,
  };

  return (
    <>
      <CustomerDetailsCol>
        <ItemDetailsLabel>Customer</ItemDetailsLabel>
        <DetailDropdown
          menu={menuProps}
          key="customer-dropdown"
          trigger={['click']}
          placement="bottomLeft"
          disabled={!editMode}
        >
          <Button disabled={!copy && !!partId}>
            <DropdownSpace>
              {currentCustomer.CompanyName}
              <DownOutlined />
            </DropdownSpace>
          </Button>
        </DetailDropdown>
      </CustomerDetailsCol>
    </>
  );
};
