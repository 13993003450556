import React, { useEffect, useState } from 'react';
import { find, noop, sortBy } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PartDetailDropdown from 'shared/components/Input/PartDetailDropdown';
import { vendorRecordsAtom } from 'shared/state/vendorState';
import { IInventoryVendor, IVendor } from 'shared/types/vendor';
import styled from 'styled-components';
import QBOItem from 'shared/data/QBO/item';
import { currentInventoryPartAtom, inventoryPartEditedAtom } from '../../../../shared/state/inventoryState';
import { FlexRow } from '../../../../shared/containers/FlexContainer';
import DetailInputWithCallback from '../../../../shared/components/Input/DetailInputWIthCallback';
import { IQBOVendor } from '../../../../shared/types/qbo';

const ComponentWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 12px;
`;

interface IComponent {
  productCode: string;
  disabled: boolean;
}
const InventoryVendorName = ({ disabled, productCode }: IComponent) => {
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const [_disabled, setDisabled] = useState<boolean>(disabled || QBOItem.partType(productCode) !== 'Inventory');
  const setEdited = useSetRecoilState(inventoryPartEditedAtom);
  // @ts-ignore
  const vendors = useRecoilValue(vendorRecordsAtom);
  const [vendor, setVendor] = useState<IQBOVendor>({} as IQBOVendor);
  const vendorOptions = sortBy(vendors.map((v: IVendor) => (
    { label: v.CompanyName, key: v.DisplayName }
  )), (v: { label: string, key: string }) => v.key);

  const onChange = (e: any) => {
    setEdited(true);
    const _vendorCode = e.split('-')[0];
    const _vendor = find(vendors, (v: IVendor) => v.DisplayName === _vendorCode);
    if (_vendor) setPartState({ ...partState, PrefVendorRef: { name: _vendor.DisplayName, value: _vendor.Id } });
    setVendor(_vendor);
  };

  useEffect(() => {
    const _vendor = find(vendors, (v: IVendor) => v.DisplayName === partState.PrefVendorRef?.name);
    if (_vendor) {
      setPartState({ ...partState, PrefVendorRef: { name: _vendor.DisplayName, value: _vendor.Id } });
      setVendor(_vendor);
    }
  }, [partState.Id]);

  useEffect(() => {
    const itemType = QBOItem.partType(productCode);
    if (disabled || itemType !== 'Inventory') {
      setPartState({ ...partState, PrefVendorRef: null });
      setDisabled(true);
    } else {
      setDisabled(disabled || false);
    }
  }, [productCode, disabled]);

  return (
    <>
      {!_disabled && (
      <ComponentWrapper>
        <PartDetailDropdown
          changeCallback={onChange}
          disabled={_disabled}
          id="inventory-vendor-dropdown"
          label="Vendor"
          placeholder="Select a vendor"
      // @ts-ignore
          value={partState.PrefVendorRef?.name}
          valueOptions={vendorOptions}
        />
        {!!partState.PrefVendorRef?.name && (
        <DetailInputWithCallback width="300px" isLabel id="inventory-vendor-name" label="Vendor Name" placeholder="" value={vendor.CompanyName} callback={noop} disabled />
        )}
      </ComponentWrapper>
      )}
    </>
  );
};

export default InventoryVendorName;
