import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { orderItemsAtom } from 'shared/state/orderState';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWIthCallback';

interface IComponent {
  orderValue: number;
  shipped: boolean;
  callback: (newValue: number) => void;
  placeholder: boolean;
}
const OrderValue = ({
  callback, orderValue, shipped = false, placeholder = false,
}: IComponent) => {
  const orderItems = useRecoilValue(orderItemsAtom);
  const [value, setValue] = useState<number>(orderValue || 0);

  const onBlur = (newValue: number) => {
    // if we have a sales order and the order items field is being calculated just return
    if (!placeholder && orderItems.filter((o) => o).length > 0) return;
    callback(newValue);
  };

  useEffect(() => {
    // if we are looking at a placeholder order, or there are no order items on this order,
    // use the field value for "orderValue" that is stored in the order record
    if (placeholder || orderItems.filter((o) => o).length === 0) {
      setValue(orderValue);
    } else {
      // if the order is not a placeholder and has items, filter order items down to those that have a quantity on order
      // and sum up the quantityShipped with the quantityOpen to get the count, then multiply by the unit price.
      const newValue = orderItems
        .filter((o) => o)
        .filter((o) => o.open)
        .map((o) => (o.quantityShipped + (shipped ? 0 : o.quantityOpen)) * o.unitPrice)
        .reduce((a, b) => a + b, 0);
      setValue(newValue);
      callback(newValue);
    }
  }, [placeholder, orderValue, orderItems]);

  return (
    <DetailNumberInputWithCallback
      key="order-value-input"
      label="Order Value"
      placeholder="0"
      value={value}
      callback={onBlur}
      disabled={!placeholder && orderItems.filter((o) => o).length > 0}
      isCurrency
    />
  );
};

export default OrderValue;
