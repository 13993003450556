import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as _ from 'lodash';
import qs from 'qs';
import { Table } from 'antd';
import styled from 'styled-components';
import { Body } from 'shared/typography';
import { activePartDisplayAtom } from 'shared/state/partViewState';
import { inventoryItemsAtom, inventorySearchQueryAtom } from 'shared/state/inventoryState';
import { EmptyPriceItem } from 'pages/Customer/Components/styledComponents';
import { rgba as hexRgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import { IInventoryPart } from 'shared/types/dbRecords';
import { listFilterQuery, stageRedirect } from 'shared/util';
import { CustomerPricingWrapper } from '../../styledComponents';
import {
  SKU_COLUMN, DESCRIPTION_COLUMN, COST_COLUMN, SELL_COLUMN, VENDOR_COLUMN, ACTION_COLUMN,
} from './InventoryListColumns';
import { scrollOptions, scrollToHash } from '../../../shared/scroll';

const ItemTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;

interface IComponent {
  renderItems: IInventoryPart[];
  currentPage: number;
  onPageChange: (newPage: number) => void;
}

const PartList = ({ renderItems, currentPage, onPageChange }: IComponent) => {
  const currentPageString = sessionStorage.getItem('currentInventoryPage');
  const inventory = useRecoilValue(inventoryItemsAtom);
  const pricingSearchQuery = useRecoilValue(inventorySearchQueryAtom);
  const activeDisplayType = useRecoilValue(activePartDisplayAtom);
  const [_currentPage, setCurrentPage] = useState(currentPageString ? parseInt(currentPageString, 10) : 1);
  const [_renderItems, setRenderItems] = useState<IInventoryPart[]>([]);
  const { partId } = qs.parse(window.location.search.replace('?', ''));
  const tableColumns = [SKU_COLUMN, DESCRIPTION_COLUMN, VENDOR_COLUMN, COST_COLUMN, SELL_COLUMN, ACTION_COLUMN];

  // const updatePageData = (): void => {
  //   const query = listFilterQuery(pricingSearchQuery);
  //   // @ts-ignore
  //   const items = _.sortBy(inventory, (i: IInventoryPart) => i.partNumber)
  //     .filter((i: IInventoryPart) => {
  //       if (activeDisplayType === 'active') return i.Active;
  //       if (activeDisplayType === 'inactive') return !i.Active;
  //       return i;
  //     })
  //     .filter((i: IInventoryPart) => {
  //       const matchString = `${i.Description}${i.Sku}${(i.UnitPrice || 0).toString()}${i.ProductCode?.productCode || ''}${i.PrefVendorRef?.name || ''}`;
  //       const matches = query.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
  //       return _.every(matches, Boolean);
  //     });
  //   setRenderItems(items);
  // };
  // const onPageChange = (newPage: number) => {
  //   sessionStorage.setItem('currentInventoryPage', newPage.toString());
  //   setCurrentPage(newPage);
  //   updatePageData();
  //   scrollToHash('#inventory-filter', {
  //     ...scrollOptions,
  //     time: 1000,
  //   });
  // };
  const onRowClick = (record: IInventoryPart) => (e: any) => {
    stageRedirect(`/inventory?partId=${record.Sku}`);
    window.location.href = `/inventory/edit?partId=${record.Sku}`;
  };

  useEffect(() => {
    setRenderItems(renderItems);
    setTimeout(() => {
      const el = document.getElementById(`${partId}-inventory-item`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
  }, [renderItems]);

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);

  return (
    <>
      {(_renderItems.length === 0)
        ? (
          <EmptyPriceItem>
            <Body>No matches available.</Body>
          </EmptyPriceItem>
        ) : (
          <CustomerPricingWrapper>
            <ItemTable
              dataSource={_renderItems.filter((r: IInventoryPart) => !!r.Sku)}
              rowKey="Sku"
              size="large"
              rowClassName="sales-order-row clickable-row"
              columns={tableColumns}
              pagination={{ pageSize: 100, current: _currentPage, onChange: onPageChange }}
              scroll={{ y: window.innerHeight * 0.8 }}
              onRow={(record: IInventoryPart) => ({
                onClick: onRowClick(record),
              })}
            />
          </CustomerPricingWrapper>
        )}
    </>
  );
};

export default PartList;
