import React from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Input } from 'antd';
import { newBodyAtom, newNeckAtom, partEditModeAtom } from 'shared/state/pricingState';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';

const PartNumberInput = styled(Input)`
  width: 144px; 
`;

interface IComponent {
  partType: string;
  partId: string;
}
const PartNumber = ({ partType, partId }: IComponent) => {
  const partStateAtom = partType.match(/body/i) ? newBodyAtom : newNeckAtom;
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(partStateAtom);
  const editMode = useRecoilValue(partEditModeAtom);

  const onPartNumberChange = (e: any) => {
    e.preventDefault();
    const partNumber = e.target.value.toUpperCase();
    setPartState({ ...partState, Sku: partNumber });
  };

  return (
    <ItemDetailsColumnWrapper key="part-number-item-details-wrapper">
      <ItemDetailsLabel key="part-number-item-details-label">Part Number</ItemDetailsLabel>
      <PartNumberInput
        key="part-number-input"
        id="part-number-input"
        placeholder="Part Number"
        value={partState.Sku || partId}
        onChange={onPartNumberChange}
        disabled={!editMode}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default PartNumber;
