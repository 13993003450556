import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Edit, DoneAll } from '@styled-icons/material-twotone';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PART_BOM_COLLECTION, partBomItemsAtom, showBomEditorAtom } from 'shared/state/partViewState';
import theme from 'shared/theme';
import useFirebase from '../../../../vendor/Firebase';
import { partEditModeAtom } from '../../../../shared/state/pricingState';
import { showQuickEditDrawerAtom } from '../../../../shared/state/inventoryState';
import { stageRedirect } from '../../../../shared/util';

const EditIcon = styled(Edit)`
  width: 24px; 
  height: 24px;
  color: ${theme.palette.primary.hue};
`;
const DoneAllIcon = styled(DoneAll)`
  width: 24px;
  height: 24px;
  color: ${theme.palette.success.hue};
`;
const BomButton = styled(Button)`
  width: 32px;
  height: 32px;
  border: none;
  position: relative;
  bottom: 3px;
  margin-left: 4px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${theme.palette.primary[200]};
  }
`;

interface IComponent {
  partNumber: string;
}
const EditBomButton = ({ partNumber }: IComponent) => {
  const { firestore } = useFirebase();
  const [showBomEditor, setShowBomEditor] = useRecoilState(showBomEditorAtom);
  const bomItems = useRecoilValue(partBomItemsAtom);
  const bomDbString = useRecoilValue(PART_BOM_COLLECTION);
  const partEditMode = useRecoilValue(partEditModeAtom);
  const setShowEditDrawer = useSetRecoilState(showQuickEditDrawerAtom);
  const onClick = (e: any) => {
    // localStorage.setItem('inventory.quick.edit.partNumber', partNumber);
    // setShowEditDrawer(true);
    stageRedirect();
    window.location.href = `/inventory/edit?partId=${partNumber}`;
  };

  useEffect(() => {
    console.log(partEditMode);
  }, [partEditMode]);
  return (
    <>
      {bomItems.length > 0 && (
      <BomButton
        type="default"
        shape="circle"
        onClick={onClick}
        icon={<EditIcon />}
      />
      )}
    </>
  );
};

export default EditBomButton;
