import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import PartDetailInput from 'shared/components/Input/PartDetailInput';
import { currentInventoryPartAtom, inventoryPartEditedAtom } from 'shared/state/inventoryState';
import DetailInputWithCallback from '../../../../shared/components/Input/DetailInputWIthCallback';

interface IComponent {
  disabled: boolean;
}
const SubItemQtyPerParentInput = ({ disabled }: IComponent) => {
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const setEdited = useSetRecoilState(inventoryPartEditedAtom);
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');

  const onBlur = (e: any) => {
    e.preventDefault();
    setEdited(true);
    const defaultQuantity = parseFloat(e.target.value);
    if (partState.Parent) {
      setPartState({ ...partState, Parent: { ...partState.Parent, ParentPerChild: defaultQuantity } });
    } else {
      setPartState({ ...partState, AllocatedQuantity: defaultQuantity });
    }
  };

  const onChange = (fieldValue: string) => {
    setValue(fieldValue);
  };

  useEffect(() => {
    if (partState) {
      if (partState.Parent) {
        setLabel('Units / Parent');
        setValue(partState.Parent?.ParentPerChild);
      } else {
        setLabel('Allocated Qty.');
        setValue((partState.AllocatedQuantity || 1).toString());
      }
    }
  }, [partState.Parent?.Sku]);
  return (
    <DetailInputWithCallback
      callback={onChange}
      id="inventory-part-allocated-quantity"
      label={label}
      placeholder="0"
      disabled={disabled}
      // @ts-ignore
      value={value}
    />
  );
};

export default SubItemQtyPerParentInput;
