import React from 'react';
import { Button } from 'antd';
import useFirebase from 'vendor/Firebase';
import styled from 'styled-components';
import _, { includes, pad, update } from 'lodash';
import QBOItem from 'shared/data/QBO/item';
import {
  IBomItem, ICustomerRecord,
  IInventoryPart, IOrderItem, IProductCode, IQATicket, IShipment, IShipmentItem,
} from 'shared/types/dbRecords';
import axios from 'axios';
import firebase from 'firebase';
import shortid from 'shortid';
import { isSamePart, sanitizePartDescription } from '../../shared/partParser/util';
import neckParser from '../../shared/partParser/neckParser';
import { partBom } from '../../shared/data/jb_api';
import { IRunner, IShopOrder } from '../Orders/types';
import bodyParser, { parseBodiesFromPartData } from '../../shared/partParser/bodyParser';
import { updateOrderItemBomConsumption } from '../../shared/data/order';

const AdHocButton = styled(Button)`
  border-radius: 8px;
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
  margin-right: 8px;
`;

export default () => {
  const { functions, firestore, database } = useFirebase();

  const main = async () => {
    /*
    -------------------- START -------------------------
     */
    // const shipmentDocs = await firestore.collection('order-shipments').get();
    // const shipments = shipmentDocs.docs.map((d) => d.data() as IShipment);

    const partDocs = await firestore.collection('part-viewer-data').where('active', '==', true).get();
    const partData = _.uniq(_.flattenDeep(partDocs.docs.map((d) => d.data().Description).map((description: string) => description.split('_'))));

    console.log(_.sortBy(partData, (s) => s).join('\r'));
    // const partSkus = parts.map((p) => p.Sku);
    // const partBomDocs = await firestore.collection('part-bom-data').get();
    // const partBoms = partBomDocs.docs.filter((doc) => _.includes(partSkus, doc.id)).map((d) => {
    //   const sku = d.id;
    //   const data = d.data().bom.filter((b) => b.Description.match(/BBK/));
    //   return { Sku: sku, bom: data };
    // });
    //
    // partBoms.forEach((p) => {
    //   if (!p.bom[0]) partBlankList.push(`${p.Sku}`);
    //   else partBlankList.push(`${p.Sku},${p.bom[0].Sku},${p.bom[0].Description},${p.bom[0].unitCost},${p.bom[0].unitPrice}`);
    // });
    //
    // console.log(partBlankList.join('\r'));
    // const partWoodList = [];
    // parts.forEach((p) => {
    //   const sku = p.Sku;
    //   const size = p.pricing?.archetype?.instrument;
    //   if (!size) {
    //     console.log('no size');
    //     console.log(p.Sku);
    //     console.log('-----------------');
    //     return;
    //   }
    //
    //   if (p.pricing.archetype.type === 'Tele' && size === 'M') {
    //     firestore.collection('part-viewer-data').doc(sku).update({ pricing: { ...p.pricing, archetype: { ...p.pricing.archetype, instrument: 'S' } } });
    //   }
    //   const bodyWood = p.pricing?.bodyWood;
    //   if (!bodyWood) {
    //     console.log('no body wood');
    //     console.log(p.Sku);
    //     console.log('-----------------');
    //     return;
    //   }
    //   const label = bodyWood?.label;
    //   const partNumberObject = bodyWood.partNumber || null;
    //   if (!partNumberObject) {
    //     console.log('no part Number');
    //     console.log(p.Sku);
    //     console.log('-----------------');
    //     return;
    //   }
    //   const partNumber = partNumberObject[size];
    //   partWoodList.push(`${p.Sku}\t${size}\t${label}\t${partNumber}`);
    // });
    //
    // console.log(partWoodList.join('\r'));
    //
    // const parentNecks = parts.filter((p) => _.includes(['GN', 'BN'], p.type)).filter((p) => !!p.childParts?.length);
    //
    // const newParents = parentNecks.map((p, i) => {
    //   const newSku = `${p.Sku.split('_')[0]}_1${(i + 1).toString().padStart(3, '0')}`;
    //   return { ...p, Sku: newSku };
    // });
    //
    // const newChildren = _.flatten(newParents.map((p: any) => {
    //   const children = parts.filter((c) => _.includes(p.childParts, c.Sku));
    //   return children.map((c, i) => ({ ...c, Sku: `${p.Sku}.${(i + 1).toString().padStart(2, '0')}` }));
    // }));
    //
    // console.log(newChildren.map((p) => p.Sku));

    //   if (active === 'N') return false;
    //   if (active === 'Y') return true;
    //   return active;
    // };
    // partData.forEach((p) => {
    //   const active = resolveActive(p.active);
    //   firestore.collection('part-viewer-data').doc(p.Sku).update({ active }).then(() => {
    //     console.log(p.Sku);
    //   });
    // });
    //   if (neck.tilt === 'Y') return 'TILT';
    //   if (neck.vintage === 'Y') return 'VIN';
    //   return '2Pc';
    // };
    //
    // console.log(partData.map((p) => constructionType(p)));
    //

    // const orderItemsDoc = await firestore.collection('order-items').doc('GEUuPJXjSp').get();
    // const orderItems = orderItemsDoc.data() as { orderItems: IOrderItem[] };
    // const updatedOrderItems = [...orderItems.orderItems];
    //
    // orderItems.orderItems.forEach((part, index) => {
    //   firestore.collection('part-viewer-data').doc(part.Sku).get().then((doc) => {
    //     const partData = doc.data();
    //     if (partData.parent) {
    //       updatedOrderItems[index] = { ...part, parent: partData.parent };
    //     }
    //   });
    // });
    // const orderDocs = await firestore.collection('orders').where('completed', '==', false).get();
    // const orders = orderDocs.docs.map((o) => o.data() as IShopOrder).filter((o) => o.runners && o.runners.length > 0 && !o.completed);
    // orders.forEach((o: IShopOrder) => {
    //   console.log(o);
    // });

    // shipments.forEach((s: IShipment) => {
    //   const items = s.shippedItems.map((i: IShipmentItem) => {
    //     const updatedBom = _.find(partBomData, (p: { id: string, bom: IBomItem[] }) => p.Sku === i.Sku);
    //     const updatedBomItems = i.bom.map((b: IBomItem) => {
    // @ts-ignore
    // const bomItem = _.find(updatedBom.bom, (bb: IBomItem) => bb.Sku === b.Sku);
    // return bomItem || b;
    // });
    // return { ...i, bom: updatedBom?.bom || i.bom || [] };
    // });
    // if (s.shipmentNumber === '15029') {
    //   console.log(s);
    //   console.log({ ...s, shippedItems: items });
    // }
    // firestore.collection('order-shipments').doc(s.id).update({ shippedItems: items }).then(() => {
    //   console.log(s.id);
    // });
    // });
    // // console.log(data.join('\r'));
    // const partSkus = [
    //   'ALLPA_00002',
    //   'ALLPA_00032',
    //   'ALLPA_00108',
    //   'BLOOM_00004',
    //   'BLOOM_00006',
    //   'BLOOM_00026',
    //   'BLOOM_00027',
    //   'BLOOM_00045',
    //   'BLOOM_00123',
    //   'EGGLE_00038',
    //   'EGGLE_00139',
    //   'LULLG_00051',
    //   'LULLG_00173',
    //   'LULLG_00208',
    //   'LULLG_00370',
    //   'LULLG_00376',
    //   'LULLG_00379',
    //   'LULLG_00382',
    //   'LULLG_00384',
    //   'MJTEL_00011',
    //   'MJTEL_00056',
    //   'MJTEL_00139',
    //   'MJTEL_00144',
    //   'MJTEL_00316',
    //   'MJTEL_00491',
    //   'MJTEL_00761',
    //   'MJTEL_01490',
    //   'NASHG_00026',
    //   'NASHG_00163',
    //   'NASHG_00167',
    //   'NASHG_00201',
    //   'NASHG_00239',
    //   'NASHG_00327',
    //   'PROSO_00094',
    //   'PROSO_00150',
    //   'PROSO_00151',
    //   'PROSO_00151',
    //   'XOTIC_00074',
    // ];
    //
    // const partDocs = await firestore.collection('part-bom-data').get();
    // const parts = partDocs.docs.map((d) => {
    //   console.log(d.id);
    //   if (_.includes(partSkus, d.id)) return { id: d.id, data: d.data() } as { id: string, data: { Description: string, bom: IBomItem[] }};
    //   return null;
    // }).filter((d) => d);
    //
    // console.log(parts);
    //
    // const bomCosts = parts.map((p) => {
    //   if (!p.data.bom) {
    //     console.log(p.id);
    //     return null;
    //   }
    //   const cost = p.data.bom.map((b: IBomItem) => b.totalCost).reduce((a, b) => a + b, 0);
    //   return `${p.id},${cost}`;
    // });
    //
    // console.log(bomCosts.filter((c) => c).join('\r'));
    /*
    --------------------- END ----------------------------------------------------------
     */
    // console.log(data);
    // inventoryItems.forEach((i) => {
    //   firestore.collection('inventory-items').doc(i.Sku).update({ ProductCode: data }).then(() => {
    //     console.log(i.Sku);
    //   });
    // });
    // partData.filter((part) => !_.includes(['KALAB', 'MJTEL'], part.customer)).forEach((part) => {
    //   const childParts = [];
    //   if (part.childParts) {
    //     part.childParts.forEach((sku, index) => {
    //       const child = _.find(partData, (p) => p.Sku === sku);
    //       if (!child) return;
    //       childParts.push(child);
    //     });
    //   }
    //   childParts.forEach((child, index) => {
    //     const childSku = `${part.Sku}.${(index + 1).toString().padStart(2, '0')}`;
    //     firestore.collection('part-viewer-data').doc(child.Sku).update({ childSku }).then(() => {
    //       console.log(part.Sku, 'child', child.Sku, 'childSku', childSku);
    //     });
    //   });
    // });
    // const assetAccounts = {
    //   250: {
    //     accountNumber: '15001',
    //     name: 'Inventory Asset',
    //     value: '250',
    //   },
    //   264: {
    //     accountNumber: '15002',
    //     name: 'Shipping Materials',
    //     value: '264',
    //   },
    //   99: {
    //     accountNumber: '15500',
    //     name: 'Work in Process',
    //     value: '99',
    //   },
    //   83: {
    //     accountNumber: '17000',
    //     name: 'Tools and Equipment',
    //     value: '83',
    //   },
    //   92: {
    //     accountNumber: '17200',
    //     name: 'Office Equipment, Fixed',
    //     value: '92',
    //   },
    // };
    // const expenseAccounts = {
    //   82: {
    //     accountNumber: '50000',
    //     name: 'COGS- Raw Materials',
    //     value: '82',
    //   },
    //   57: {
    //     accountNumber: '50600',
    //     name: 'Shop Supplies & Small Tools',
    //     value: '57',
    //   },
    //   90: {
    //     accountNumber: '50620',
    //     name: 'Shop Equipment EXP',
    //     value: '90',
    //   },
    //   46: {
    //     accountNumber: '62600',
    //     name: 'Office Supplies',
    //     value: '46',
    //   },
    //   72: {
    //     accountNumber: '70100',
    //     name: 'Shipping Out- EXP',
    //     value: '72',
    //   },
    // };
    // const incomeAccounts = {
    //   21: {
    //     accountNumber: '40000',
    //     name: 'Sales Income',
    //     value: '21',
    //   },
    //   22: {
    //     accountNumber: '42000',
    //     name: 'Service Income',
    //     value: '22',
    //   },
    //   102: {
    //     accountNumber: '49000',
    //     name: 'Sales Discounts',
    //     value: '102',
    //   },
    //   23: {
    //     accountNumber: '70110',
    //     name: 'Shipping Reimbursed',
    //     value: '23',
    //   },
    // };
    //
    // interface IQBOAcctRef {
    //   name: string;
    //   value: string;
    // }
    //
    // interface IHelmAcctRef extends IQBOAcctRef {
    //   accountNumber: string;
    // }
    //
    // const rawMaterialAccounts = {
    //   AssetAccountRef: assetAccounts[250],
    //   ExpenseAccountRef: expenseAccounts[82],
    //   IncomeAccountRef: incomeAccounts[21],
    // };
    // const productCodeAccountMap = {
    //   BODYBLANK: rawMaterialAccounts,
    //   BODYTOP: rawMaterialAccounts,
    //   NECKWOOD: rawMaterialAccounts,
    //   NECKPARTS: rawMaterialAccounts,
    //   LUMBER: rawMaterialAccounts,
    //   SHIPMATLS: {
    //     AssetAccountRef: assetAccounts[264],
    //     ExpenseAccountRef: expenseAccounts[82],
    //     IncomeAccountRef: null,
    //   },
    //   SHOPSUP: {
    //     AssetAccountRef: null,
    //     expernse: expenseAccounts[90],
    //     IncomeAccountRef: null,
    //   },
    //   SHOPEQUIP: {
    //     AssetAccountRef: assetAccounts[83],
    //     ExpenseAccountRef: expenseAccounts[90],
    //     IncomeAccountRef: null,
    //   },
    //   ITEMMODIFIER: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: expenseAccounts[82],
    //     IncomeAccountRef: incomeAccounts[21],
    //   },
    //   CNCEQUIP: {
    //     AssetAccountRef: assetAccounts[83],
    //     ExpenseAccountRef: expenseAccounts[90],
    //     IncomeAccountRef: null,
    //   },
    //   CNCSUP: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: expenseAccounts[57],
    //     IncomeAccountRef: null,
    //   },
    //   DISCOUNT: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: null,
    //     IncomeAccountRef: incomeAccounts[102],
    //   },
    //   FINISHGOOD: {
    //     AssetAccountRef: assetAccounts[99],
    //     ExpenseAccountRef: expenseAccounts[82],
    //     IncomeAccountRef: incomeAccounts[21],
    //   },
    //   GLUES: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: expenseAccounts[82],
    //     IncomeAccountRef: null,
    //   },
    //   OFFICEEQUIP: {
    //     AssetAccountRef: assetAccounts[92],
    //     ExpenseAccountRef: expenseAccounts[46],
    //     IncomeAccountRef: null,
    //   },
    //   OFFICESUP: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: expenseAccounts[46],
    //     IncomeAccountRef: null,
    //   },
    //   SANDSUP: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: expenseAccounts[57],
    //     IncomeAccountRef: null,
    //   },
    //   SHIPCHGS: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: expenseAccounts[72],
    //     IncomeAccountRef: incomeAccounts[23],
    //   },
    //   SHOPTIME: {
    //     AssetAccountRef: null,
    //     ExpenseAccountRef: null,
    //     IncomeAccountRef: incomeAccounts[22],
    //   },
    // };

    // const productCodeDocs = await firestore.collection('product-codes').get();
    // const productCodes = productCodeDocs.docs.map((d) => d.data() as IProductCode);
    // const shopSup = _.find(productCodes, (p) => p.productCode === 'SHOPSUP');
    // Object.keys(productCodeAccountMap).forEach((k) => {
    //   const productCode = _.find(productCodes, (p) => p.productCode === k);
    //   if (!productCode) console.log(k);
    //   const accounts = productCodeAccountMap[k] as any;
    //   firestore.collection('product-codes').doc(k).set({ ...(_.omit(productCode, 'accounts')), ...accounts }).then(() => {
    //     console.log(k);
    //   });
    // });
    // const inventoryDocs = await firestore.collection('inventory-items').where('ProductCode.productCode', '==', 'SHOPSUP').get();
    // const inventoryData = inventoryDocs.docs.map((d) => d.data() as IInventoryPart);
    // inventoryData.forEach((d) => {
    //   firestore.collection('inventory-items').doc(d.Sku).update({ ProductCode: shopSup }).then(() => {
    //     console.log(d.Sku);
    //   });
    // });
    // inventoryData.filter((i) => i.ProductCode.productCode === 'SHOPSUP').forEach((i: IInventoryPart) => {
    //   const productCode = _.find(productCodes, (p) => p.productCode === i.ProductCode.productCode);
    //   if (!productCode) {
    //     console.log(i.ProductCode.productCode);
    //     return;
    //   }
    //   const updatedDoc = {
    //     ..._.omit(i, ['AssetAccountRef', 'ExpenseAccountRef', 'IncomeAccountRef']),
    //     ProductCode: productCode,
    //     Taxable: !!productCode.IncomeAccountRef,
    //     FullyQualifiedName: i.Sku,
    //   };
    //
    //   firestore.collection('inventory-items').doc(i.Sku).set(updatedDoc).then(() => {
    //     console.log(updatedDoc);
    //     console.log(i.Sku);
    //   });
    // });
  };
  const onClick = (e: any) => {
    e.preventDefault();
    if (process.env.NODE_ENV !== 'development') return;
    console.log('Beginning ad hoc routine');

    main().then(() => {
      console.log('Ad hoc routine complete');
    });
  };

  return (
    // @ts-ignore
    <AdHocButton disabled={window.location.pathname.match('wildwood')} type="default" onClick={onClick}>Execute Ad-hoc function</AdHocButton>
  );
};
