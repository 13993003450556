import { Button, Tooltip } from 'antd';
import React from 'react';
import { FlexRow } from 'shared/containers/FlexContainer';
import {
  RouterInfoIcon,
  RouterTableInfoButton,
  RouterTDCopy,
} from 'pages/ProductionSchedule/styledComponents';
import styled from 'styled-components';
import { Edit } from '@styled-icons/material';
import theme from '../../../shared/theme';
import { IPartWood } from '../../../shared/types/pricingTool';
import { stageRedirect } from '../../../shared/util';
import { formatPrice } from '../../../shared/data';

const shortid = require('shortid');
const _ = require('lodash');

const EditIcon = styled(Edit)`
  width: 24px;
  height: 24px;
  color: ${theme.palette.neutral[500]};
    opacity: 0;
`;
const WoodColumn = styled(Button)`
  width: 100%;
  border: none;
  background-color: transparent;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 24px 8px;
  &:hover {
    background-color: ${theme.palette.primary[200]};
    color: ${theme.palette.primary.hue};
    font-weight: bold;
     & ${EditIcon} {
       opacity: 1;
     }
  }
`;
const EmptyCell = styled.div`
    padding: 12px 8px;
`;

const PartCopy = styled(RouterTDCopy)`
    padding: 4px 12px;
`;

const onEditClick = (sku: number) => (e: any) => {
  e.preventDefault();
  // const partType = localStorage.getItem('pricingEditCategory');
  // const woodType = partType?.match(/neck/i) ? localStorage.getItem('neckPricingPane') : localStorage.getItem('bodyPricingPane');
  stageRedirect(window.location.href);
  window.location.href = `/inventory/edit?partId=${sku}`;
};

export const WOOD_COLUMN = {
  title: 'Wood Type',
  dataIndex: shortid.generate(),
  align: 'left',
  sorter: {
    compare: (a: IPartWood, b: IPartWood) => {
      if (a.label > b.label) return 1;
      if (b.label > a.label) return -1;
      return 0;
    },
  },
  render: (text: string, record: any) => (
    <PartCopy key={shortid.generate()}>{record.label}</PartCopy>

  ),
  width: 300,
};
export const TELE_PART_NUMBER_COLUMN = {
  title: 'Part No.',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => (
    <>
      {(record.partNumber?.S && record.partNumber.S > 0) ? (
        <WoodColumn id={record.id} key={shortid.generate()} onClick={onEditClick(record.partNumber.S)}>
          <PartCopy key={`${record.id}-medium-part-number`}>{record.partNumber.S}</PartCopy>
          <EditIcon />
        </WoodColumn>
      ) : (
        <EmptyCell>
          <PartCopy key={`${record.id}-small-part-number`} style={{ color: 'lightgrey' }}>-</PartCopy>
        </EmptyCell>
      )}
    </>
  ),
  width: 50,
};
export const TELE_PRICE_COLUMN = {
  title: 'Sell',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    if (record.price?.S && record.price.S > 0) return <PartCopy key={shortid.generate()}>{`$${record.price?.S}`}</PartCopy>;
    return <PartCopy key={`${record.id}-small-price`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  },
  width: 75,
};
export const TELE_MATERIAL_COLUMN = {
  title: 'Cost',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    if (record.materialCost?.S && record.materialCost.S > 0) return <PartCopy key={shortid.generate()}>{`${formatPrice(record.materialCost?.S, 0)}`}</PartCopy>;
    return <PartCopy key={`${record.id}-small-cost`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  },
  width: 75,
};

export const TELE_MARGIN_COLUMN = {
  title: 'Margin',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    let margin = (!!record.price?.S && record.materialCost?.S) ? ((1 - (record.materialCost.S / record.price.S)) * 100) : '-';
    let color = 'lightgrey';
    let fontWeight = 'normal';
    let backgroundColor = 'transparent';
    if (margin !== '-') {
      color = margin < 20 ? theme.palette.neutral.white : margin >= 30 ? theme.palette.success.D100 : theme.palette.warning.D100;
      fontWeight = margin < 20 ? 'bold' : 'normal';
      backgroundColor = margin < 20 ? theme.palette.error.hue : 'transparent';
      margin = `${(margin as number).toFixed(2)}%`;
    }
    return (
      <PartCopy key={`${record.id}-small-margin`} style={{ color, fontWeight, backgroundColor }}>{`${margin}`}</PartCopy>
    );
  },
  width: 75,
};
export const GUITAR_PART_NUMBER_COLUMN = {
  title: 'Part No.',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => (
    <>
      {(record.partNumber?.M && record.partNumber.M > 0) ? (
        <WoodColumn id={record.id} key={shortid.generate()} onClick={onEditClick(record.partNumber.M)}>
          <PartCopy key={`${record.id}-medium-part-number`}>{record.partNumber.M}</PartCopy>
          <EditIcon />
        </WoodColumn>
      ) : (
        <EmptyCell>
          <PartCopy key={`${record.id}-small-part-number`} style={{ color: 'lightgrey' }}>-</PartCopy>
        </EmptyCell>
      )}
    </>
    // if (record.partNumber?.M && record.partNumber.M > 0) return <PartCopy key={`${record.id}-medium-part-number`}>{record.partNumber.M}</PartCopy>;
    // return <PartCopy key={`${record.id}-medium-part-number`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  ),
  width: 50,
};
export const GUITAR_PRICE_COLUMN = {
  title: 'Sell',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    if (record.price?.M && record.price.M > 0) return <PartCopy key={shortid.generate()}>{`$${record.price?.M}`}</PartCopy>;
    return <PartCopy key={`${record.id}-medium-price`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  },
  width: 75,
};
export const GUITAR_MATERIAL_COLUMN = {
  title: 'Cost',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    if (record.materialCost?.M && record.materialCost.M > 0) return <PartCopy key={shortid.generate()}>{`${formatPrice(record.materialCost?.M, 0)}`}</PartCopy>;
    return <PartCopy key={`${record.id}-medium-cost`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  },
  width: 75,
};

export const GUITAR_MARGIN_COLUMN = {
  title: 'Margin',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    let margin = (!!record.price?.M && record.materialCost?.M) ? ((1 - (record.materialCost.M / record.price.M)) * 100) : '-';
    let color = 'lightgrey';
    let fontWeight = 'normal';
    let backgroundColor = 'transparent';
    if (margin !== '-') {
      color = margin < 20 ? theme.palette.neutral.white : margin >= 30 ? theme.palette.success.D100 : theme.palette.warning.D100;
      fontWeight = margin < 20 ? 'bold' : 'normal';
      backgroundColor = margin < 20 ? theme.palette.error.hue : 'transparent';
      margin = `${(margin as number).toFixed(2)}%`;
    }
    return (
      <PartCopy key={`${record.id}-medium-margin`} style={{ color, fontWeight, backgroundColor }}>{`${margin}`}</PartCopy>
    );
  },
  width: 75,
};

export const BASS_PART_NUMBER_COLUMN = {
  title: 'Part No.',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => (
    <>
      {(record.partNumber?.L && record.partNumber.L > 0) ? (
        <WoodColumn id={record.id} key={shortid.generate()} onClick={onEditClick(record.partNumber.L)}>
          <PartCopy key={`${record.id}-medium-part-number`}>{record.partNumber.L}</PartCopy>
          <EditIcon />
        </WoodColumn>
      ) : (
        <EmptyCell>
          <PartCopy key={`${record.id}-small-part-number`} style={{ color: 'lightgrey' }}>-</PartCopy>
        </EmptyCell>
      )}
    </>
    // if (record.partNumber?.L && record.partNumber.L > 0) return <PartCopy key={shortid.generate()}>{record.partNumber.L}</PartCopy>;
    // return <PartCopy key={`${record.id}-large-part-number`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  ),
  width: 50,
};
export const BASS_PRICE_COLUMN = {
  title: 'Sell',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    if (record.price?.L && record.price.L > 0) return <PartCopy key={shortid.generate()}>{`$${record.price?.L}`}</PartCopy>;
    return <PartCopy key={`${record.id}-large-price`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  },
  width: 75,
};
export const BASS_MATERIAL_COLUMN = {
  title: 'Cost',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    if (record.materialCost?.L && record.materialCost.L > 0) return <PartCopy key={shortid.generate()}>{`${formatPrice(record.materialCost?.L, 0)}`}</PartCopy>;
    return <PartCopy key={`${record.id}-large-cost`} style={{ color: 'lightgrey' }}>-</PartCopy>;
  },
  width: 75,
};
export const BASS_MARGIN_COLUMN = {
  title: 'Margin',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    let margin = (!!record.price?.L && record.materialCost?.L) ? ((1 - (record.materialCost.L / record.price.L)) * 100) : '-';
    let color = 'lightgrey';
    let fontWeight = 'normal';
    let backgroundColor = 'transparent';
    if (margin !== '-') {
      color = margin < 20 ? theme.palette.neutral.white : margin >= 30 ? theme.palette.success.D100 : theme.palette.warning.D100;
      fontWeight = margin < 20 ? 'bold' : 'normal';
      backgroundColor = margin < 20 ? theme.palette.error.hue : 'transparent';
      margin = `${(margin as number).toFixed(2)}%`;
    }
    return (
      <PartCopy key={`${record.id}-margin`} style={{ color, fontWeight, backgroundColor }}>{`${margin}`}</PartCopy>
    );
  },
  width: 75,
};

export const ACTIONS_COLUMN = {
  title: '',
  key: 'actions',
  render: (text: string, record: any) => (
    <FlexRow justify="flex-start" style={{ paddingRight: 24 }} key={shortid.generate()}>
      <Tooltip placement="top" title="View/Edit">
        <RouterTableInfoButton key={shortid.generate()} onClick={onEditClick(record)}><RouterInfoIcon /></RouterTableInfoButton>
      </Tooltip>
    </FlexRow>
  ),
};

const SpacerDiv = styled.div`
  width: 25%;
  height: 100%;
  margin: 0 auto;
  background-color: lightgrey;
`;
export const SPACER_COLUMN = {
  title: '',
  key: 'spacer',
  render: (text: string, record: any) => (
    <SpacerDiv />
  ),
  width: 8,
};

export const bodyColumns = [
  WOOD_COLUMN,
  {
    title: 'Guitar (Small)',
    children: [
      TELE_PART_NUMBER_COLUMN,
      TELE_MATERIAL_COLUMN,
      TELE_PRICE_COLUMN,
      TELE_MARGIN_COLUMN,
    ],
  },
  SPACER_COLUMN,
  {
    title: 'Guitar (Medium)',
    children: [
      GUITAR_PART_NUMBER_COLUMN,
      GUITAR_MATERIAL_COLUMN,
      GUITAR_PRICE_COLUMN,
      GUITAR_MARGIN_COLUMN,
    ],
  },
  SPACER_COLUMN,
  {
    title: 'Bass (Large)',
    children: [
      BASS_PART_NUMBER_COLUMN,
      BASS_MATERIAL_COLUMN,
      BASS_PRICE_COLUMN,
      BASS_MARGIN_COLUMN,
    ],
  },
  // SPACER_COLUMN,
  // ACTIONS_COLUMN,
];
export const neckColumns = [
  WOOD_COLUMN,
  {
    title: 'Guitar (Medium)',
    children: [
      GUITAR_PART_NUMBER_COLUMN,
      GUITAR_MATERIAL_COLUMN,
      GUITAR_PRICE_COLUMN,
      GUITAR_MARGIN_COLUMN,
    ],
  },
  SPACER_COLUMN,
  {
    title: 'Bass (Large)',
    children: [
      BASS_PART_NUMBER_COLUMN,
      BASS_MATERIAL_COLUMN,
      BASS_PRICE_COLUMN,
      BASS_MARGIN_COLUMN,
    ],
  },
  // SPACER_COLUMN,
  // ACTIONS_COLUMN,
];
