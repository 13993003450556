import React, { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import styled from 'styled-components';
import qs from 'qs';
import {
  currentCustomerAtom,
  CUSTOMERS_DB_COLLECTION,
} from 'shared/state/customerState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { DetailRow } from 'shared/styledComponents/containers';
import { ICustomerRecord } from 'shared/types/dbRecords';
import CustomerID from './Components/CustomerDetailFields/CustomerID';
import { PartDetailColumnHeader } from '../../shared/styledComponents/typographicElements';
import CustomerDetailHeader from './Components/CustomerDetailHeader';
import CustomerActiveSelector from './Components/CustomerDetailFields/CustomerActiveSelector';
import ContactDetail from './Components/Contacts/ContactDetail';
import useFirebase from '../../vendor/Firebase';
import CustomerDiscounts from './Components/CustomerDetailFields/CustomerDiscounts';
import ShippingAddressDetail from './Components/ShippingAddresses/ShippingAddressDetail';
import { urlQueryString } from '../../shared/util';
import BillingAddressDetail from './Components/BillingAddress/BillingAddressDetail';
import DetailInputWithCallback from '../../shared/components/Input/DetailInputWIthCallback';

const DetailWrapper = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: flex-start;
    width: calc(100% - 100px);
    margin-left: 100px;
`;

const InnerDetailWrapper = styled(DetailWrapper)`
    width: 100%;
    margin-left: 0;
`;
const CustomerDetailRow = styled(FlexRow)`
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
`;
const CustomerDetailColumn = styled(FlexColumn)`
  align-items: flex-start;
  width: 50%;
  margin-bottom: 24px;
`;

const CustomerDetail = () => {
  const { firestore, database } = useFirebase();
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  // @ts-ignore
  const { customer, qboId } = qs.parse(urlQueryString(window.location.href)) as string;

  useEffect(() => {
    firestore.collection(customerDbString).doc(customer).get().then((doc) => {
      const customerData = doc.data() as ICustomerRecord;
      setCurrentCustomer(customerData);
    });
  }, [customer]);

  const onChangeCompanyName = (CompanyName: string) => {
    setCurrentCustomer({ ...currentCustomer, CompanyName });
  };

  const onChangeDisplayName = (DisplayName: string) => {
    setCurrentCustomer({ ...currentCustomer, DisplayName });
  };

  const onChangeStringProperty = (propertyName: string) => (value: string) => {
    const updatedCustomer = { ...currentCustomer };
    updatedCustomer[propertyName] = value;
    setCurrentCustomer(updatedCustomer);
  };

  return (
    <>
      {!!currentCustomer.id.length && (
      <>
        <CustomerDetailHeader />
        <DetailWrapper>
          {/* <PartDetailColumn> */}
          <CustomerDetailRow>
            <InnerDetailWrapper>
              <PartDetailColumnHeader>Customer Details</PartDetailColumnHeader>
              <CustomerDetailColumn>
                <DetailRow>
                  <CustomerID />
                  <CustomerActiveSelector />
                </DetailRow>
                <DetailInputWithCallback id="company-name" label="Company Name" placeholder="e.g., Nash Guitars" value={currentCustomer.CompanyName} callback={onChangeStringProperty('CustomerName')} extend isLabel={false} />
                <DetailInputWithCallback id="company-short-name" label="Display Name" placeholder="e.g., NASHG" value={currentCustomer.DisplayName} callback={onChangeStringProperty('DisplayName')} extend isLabel={false} />
                <DetailInputWithCallback id="company-site-login" label="HELM Login Name" placeholder="e.g., nash" value={currentCustomer.login} callback={onChangeStringProperty('login')} extend isLabel={false} />
                <CustomerDiscounts />
              </CustomerDetailColumn>
            </InnerDetailWrapper>
            <InnerDetailWrapper>
              <PartDetailColumnHeader>Contacts</PartDetailColumnHeader>
              <ContactDetail />
            </InnerDetailWrapper>
            {/* <PartDetailColumnHeader>Pricing Factors</PartDetailColumnHeader> */}
            {/* </DetailRow> */}
          </CustomerDetailRow>
          {/* </PartDetailColumn> */}
          {/* <PartDetailColumn> */}
          <CustomerDetailRow>
            <InnerDetailWrapper>
              <PartDetailColumnHeader>Billing Addresses</PartDetailColumnHeader>
              <BillingAddressDetail />
            </InnerDetailWrapper>
            <InnerDetailWrapper>
              <PartDetailColumnHeader>Shipping Addresses</PartDetailColumnHeader>
              <ShippingAddressDetail />
            </InnerDetailWrapper>
          </CustomerDetailRow>
          {/* </PartDetailColumn> */}
        </DetailWrapper>
      </>
      )}
    </>
  );
};

export default CustomerDetail;
