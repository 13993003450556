import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import qs from 'qs';
import firebase from 'firebase';
import {
  find, findIndex, flatten, includes, noop, uniq,
} from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import shortid from 'shortid';
import {
  PartDetailColumn, DetailRow,
} from 'shared/styledComponents/containers';
import useFirebase from 'vendor/Firebase';
import { Button, Image, Modal } from 'antd';
import { partTypeFromDesc, redirect } from 'shared/util';
import {
  IBomItem, ICustomerRecord, IOrderItem, IQACorrectiveStep, IQATicket,
} from 'shared/types/dbRecords';
import {
  adminUserEmailsAtom,
  routerStepsAtom,
  superAdminUserEmailAtom,
  supervisorEmailsAtom,
} from 'shared/state/routingState';
import {
  currentQaTicketAtom,
  QA_TICKETS_COLLECTION,
  qaInvestigationUsers, qaTicketEdited,
  qaTicketImageEditModeAtom,
} from 'shared/state/qualityAssuranceState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWIthCallback';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWIthCallback';
import { FlexRow, FlexColumn } from 'shared/containers/FlexContainer/styledComponents';
import theme from 'shared/theme';
import HelpButton from 'shared/components/Utility/HelpButton';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWIthCallback';
import { PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import { currentShopOrderAtom } from 'shared/state/orderState';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWIthCallback';
import { formatPrice } from 'shared/data';
import DetailActiveSelectorWithCallback from 'shared/components/Input/DetailActiveSelectorWithCallback';
import { customersAtom } from 'shared/state/customerState';
import NewElementButton from 'shared/buttons/NewElementButton';
import { mapOpenedBy } from './Components/QATicketColumns';
import QATicketDetailHeader from './Components/QATicketDetailHeader';
import QATicketImageUploader from './Components/QATicketImageUploader';
import QAImageThumbnailPreview from './Components/QAImageThumbnailPreview';
import QATicketDepartmentDropdown from './Components/QATicketDepartmentDropdown';
import QATicketReasonCodeDropdown from './Components/QATicketReasonActionDropdowns';
import QATicketPartSelectorDropdown from './Components/QATicketPartSelectorDropdown';
import QACorrectiveStep from './Components/QACorrectiveStep';
import { IRouterStep } from '../../ProductionSchedule/types';
import { ISalesOrder, IShopOrder, IWorkOrder } from '../../Orders/types';
import ActiveSelector from '../../Customer/Components/PriceListInputs/ActiveSelector';

const HeaderBackdrop = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 88px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    z-index: 100;
`;

const ImageSectionWrapper = styled.div`
    width: 100%;
    display: ${(props: { mobile: boolean }) => (props.mobile ? 'block' : 'none')};
    @media ${theme.device.laptopL} {
      display: ${(props: { mobile: boolean }) => (props.mobile ? 'none' : 'block')};
    }
`;
const DetailColumn = styled(PartDetailColumn)`
    width: 100%;
    padding-left: 0;
    flex-grow: 0;
    @media ${theme.device.laptopL} {
        width: calc(50% - 60px);
        padding-left: 32px;
    }
`;

const DetailRightColumn = styled(DetailColumn)`
    padding-top: 0;
    @media ${theme.device.laptopL} {
        padding-top: 40px;
    }
`;

const MobileDetailColumn = styled(DetailColumn)`
    @media ${theme.device.laptopL} {
      display: none;
    }
`;
const QADetailRow = styled(DetailRow)`
    flex-wrap: wrap;
    @media ${theme.device.laptop} {
        flex-wrap: unset;
    }
`;

const ImageButtonRow = styled(FlexRow)`
    justify-content: flex-start;
`;
const ComponentWrapper = styled(FlexColumn)`
  width: calc(100vw - 32px);
  margin: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
    
    @media ${theme.device.laptop} {
        width: calc(100vw - 40px);
        margin-left: 20px;
    }
    
    @media ${theme.device.laptopL} {
        width: calc(100vw - 140px);
        margin-left: 68px; 
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

const ThumbnailWrapper = styled(FlexRow)`
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
`;

const InventoryDetail = () => {
  const { currentUser } = useContext(AuthContext);
  // @ts-ignore;
  const { qaTicketId, orderId, runnerId } = qs.parse(window.location.search.replace('?', ''));
  const { database, firestore } = useFirebase();
  const customers = useRecoilValue(customersAtom);
  const qaTicketDbString = useRecoilValue(QA_TICKETS_COLLECTION);
  const [qaTicket, setQATicket] = useRecoilState(currentQaTicketAtom);
  const setQATicketEdited = useSetRecoilState(qaTicketEdited);
  const investigationUsers = useRecoilValue(qaInvestigationUsers);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const supervisorUsers = useRecoilValue(supervisorEmailsAtom);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const routerSteps = useRecoilValue(routerStepsAtom);
  const isShopUser = !includes([...superAdminUsers.emails, ...adminUsers.emails, ...supervisorUsers.emails], currentUser.email);
  const [workOrderStep, setWorkOrderStep] = useState<string>('');
  const [currentWorkOrder, setCurrentWorkOrder] = useState<IWorkOrder>({} as IWorkOrder);

  const onChangeCreatedBy = (value: string, update: boolean) => {
    setQATicketEdited(true);
    setQATicket({ ...qaTicket, createdBy: value });
  };
  const onChangeDescription = (key: string) => (description: string) => {
    setQATicketEdited(true);
    const updatedTicket = { ...qaTicket };
    updatedTicket[key] = description;
    setQATicket(updatedTicket);
  };

  const onUploadImages = (_imageData: string[]) => {
    setQATicketEdited(true);
    // setImageData(_imageData);
    const updatedImageUrls = uniq([..._imageData, ...qaTicket.nonConformanceImageUrls]);
    setQATicket({ ...qaTicket, nonConformanceImageUrls: updatedImageUrls });
  };

  const onDeleteImage = async (imageUrl: string) => {
    const updatedImages = qaTicket.nonConformanceImageUrls.filter((u: string) => u !== imageUrl);
    await firestore.collection(qaTicketDbString).doc(qaTicketId).update({ nonConformanceImageUrls: updatedImages });
    setQATicket({ ...qaTicket, nonConformanceImageUrls: updatedImages });
    return true;
  };

  const onChangePartQuantity = (value: number) => {
    setQATicketEdited(true);
    setQATicket({ ...qaTicket, partQuantity: value, estimatedCost: calculatePartValue(value) });
  };

  const onChangePartNumber = async (value: string, blur: boolean) => {
    setQATicketEdited(true);
    if (blur) {
      if (value.length) {
        if (runnerId) {
          const workOrder = find(currentShopOrder?.runners, (r: IWorkOrder) => r.id === runnerId);
          if (workOrder) {
            const partOnWorkOrder = find(workOrder.parts, (p: IOrderItem) => p.Sku === value);
            if (!partOnWorkOrder) {
              Modal.confirm({
                title: 'Part not found on work order',
                content: 'The part number you entered was not found on the scanned work order. You can still use this part, but it is best to scan the correct work order that corresponds to the part in question.',
                cancelText: 'Use another part',
                onCancel: () => {
                  setQATicket({ ...qaTicket, partNumber: '' });
                  const el = document.getElementById('qa-ticket-part-number-inputfield');
                  if (el) el.focus();
                },
              });
            } else {
              const customer = find(customers, (c: ICustomerRecord) => c.DisplayName === partOnWorkOrder.Sku.split('_')[0]);
              setQATicket({
                ...qaTicket, partNumber: value, partDescription: partOnWorkOrder.Description, type: partTypeFromDesc(partOnWorkOrder.Description), customer: customer?.shortName,
              });
            }
          }
        } else {
          const doc = await firestore.collection(partViewerDbString).doc(value).get();
          if (doc.exists) {
            const data = doc.data();
            const customer = find(customers, (c: ICustomerRecord) => c.DisplayName === data.Sku.split('_')[0]);
            setQATicket({
            // @ts-ignore
              ...qaTicket, partNumber: value, partDescription: data.Description, type: partTypeFromDesc(data.Description), customer: customer?.shortName,
            });
          } else {
            Modal.error({
              title: 'Part not found!',
              content: 'The part number you entered was not found in the database. Please check the number and try again.',
            });
          }
        }
      } else {
        setQATicket({ ...qaTicket, partNumber: '' });
      }
    }
  };
  const onChangePartNumberFromDropdown = (value: string) => {
    setQATicketEdited(true);
    const partOnWorkOrder = find(currentWorkOrder.parts, (p: IOrderItem) => p.Sku === value);
    const customer = find(customers, (c: ICustomerRecord) => c.DisplayName === partOnWorkOrder?.Sku.split('_')[0]);
    setQATicket({
      ...qaTicket, partNumber: value, partDescription: partOnWorkOrder?.Description, type: partTypeFromDesc(partOnWorkOrder?.Description), customer: customer?.shortName,
    });
  };
  const onChangeSalesOrder = async (value: string, blur: boolean) => {
    setQATicketEdited(true);
    if (blur && value.length > 0) {
      const docSnap = await firestore.collection('orders').where('salesOrder', '==', value).get();
      const docs = docSnap.docs.map((d) => d.data() as ISalesOrder);
      if (docs.length > 0) {
        const data = docs[0];
        setQATicket({ ...qaTicket, salesOrder: data.salesOrder });
        setCurrentShopOrder(data);
      } else {
        Modal.error({
          title: 'Order not found!',
          content: 'The order number you entered was not found in the database. Please check the number and try again.',
        });
      }
    } else {
      setQATicket({ ...qaTicket, salesOrder: value, partNumber: '' });
      setCurrentShopOrder(null);
    }
  };
  const onChangeDate = (key: string) => (date: Date) => {
    setQATicketEdited(true);
    const updated = { ...qaTicket };
    updated[key] = firebase.firestore.Timestamp.fromDate(date);
    setQATicket(updated);
  };

  const onChangeDepartment = (department: string) => {
    setQATicketEdited(true);
    setQATicket({ ...qaTicket, department });
  };

  const onChangeReasonCode = (reasonCode: string) => {
    setQATicketEdited(true);
    setQATicket({ ...qaTicket, nonConformanceExceptionCode: reasonCode });
  };

  const onChangeCorrectiveAction = (correctiveAction: string) => {
    setQATicketEdited(true);
    setQATicket({ ...qaTicket, nonConformanceCorrectiveAction: correctiveAction });
  };

  const onChangeInvestigationStatus = (underInvestigation: boolean) => {
    setQATicketEdited(true);
    setQATicket({ ...qaTicket, underInvestigation });
  };

  const onAddCorrectiveStep = (e: any) => {
    e.preventDefault();
    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const id = shortid.generate();
    const updatedCorrectiveSteps = [
      {
        createdDate: now,
        modifiedDate: now,
        type: 'immediate',
        description: '',
        id,
        personResponsible: '',
        implementationDate: null,
        result: '',
        resultDate: null,
      },
      ...(qaTicket.correctiveSteps || []),
    ];
    setQATicket({ ...qaTicket, correctiveSteps: updatedCorrectiveSteps });
  };

  const onUpdateCorrectiveStep = (correctiveStep: IQACorrectiveStep) => {
    setQATicketEdited(true);
    const updated = [...qaTicket.correctiveSteps];
    const index = findIndex(qaTicket.correctiveSteps, (c: IQACorrectiveStep) => c.id === correctiveStep.id);
    updated[index] = correctiveStep;
    setQATicket({ ...qaTicket, correctiveSteps: updated });
  };
  const calculatePartValue = (quantity: number = qaTicket.partQuantity) => {
    if (currentWorkOrder) {
      const routerStep = find(routerSteps, (r: IRouterStep) => r.id === currentWorkOrder.step);
      const boms = flatten((currentWorkOrder.parts || []).map((p: IOrderItem) => p.bom)).filter((b: IBomItem) => {
        const partClass = parseInt(b.Sku.substring(0, 3), 10);
        return includes(routerStep?.validPartClasses, partClass);
      });
      const bomValue = boms.map((b: IBomItem) => b.unitCost * b.quantity * quantity).reduce((a, b) => a + b, 0);
      return formatPrice(bomValue.toFixed(2).toString());
    }
  };

  useEffect(() => {
    if (qaTicketId) {
      firestore.collection(qaTicketDbString).doc(qaTicketId).get().then((doc) => {
        if (!doc.exists) {
          Modal.error({
            title: 'Uh-oh',
            content: "Couldn't find that ticket in our database! Click OK to go back.",
            onOk: () => {
              redirect();
            },
          });
        } else {
          const ticket = doc.data() as IQATicket;
          setQATicket(ticket);
          if (ticket.salesOrder?.length) {
            firestore.collection('orders').where('salesOrder', '==', ticket.salesOrder).get().then((snap) => {
              const docs = snap.docs.map((d) => d.data() as IShopOrder).filter((d) => d);
              if (docs.length) {
                const salesOrder = docs[0];
                setCurrentShopOrder(salesOrder);
                setCurrentWorkOrder(find(salesOrder.runners, (r: IWorkOrder) => r.id === ticket.runnerId));
              }
            });
          }
        }
      });
    } else {
      database.ref('/recordNumbers/qaTicket').once('value').then((res) => {
        const ticket = {
          id: res.val().toString(),
          createdDate: firebase.firestore.Timestamp.fromDate(new Date()),
          createdBy: mapOpenedBy(currentUser.email.split('@')[0]),
          incidentDate: firebase.firestore.Timestamp.fromDate(new Date()),
          correctiveSteps: [],
          customer: '',
          department: '',
          partNumber: '',
          partDescription: '',
          partQuantity: 1,
          estimatedCost: 1,
          otherModelsAffected: '',
          nonConformanceExceptionCode: '',
          nonConformanceCorrectiveAction: '',
          nonConformanceDescription: '',
          nonConformanceImageUrls: [],
          perceivedCauseDescription: '',
          immediateCorrectionDescription: '',
          permanentCorrectionDescription: '',
          implementationEmployee: '',
          implementationDate: null,
          reportType: 'non-conformance',
          results: '',
          resolutionDate: null,
          salesOrder: '',
          title: '',
          underInvestigation: false,
        } as unknown as IQATicket;
        if (orderId) {
          firestore.collection('orders').doc(orderId).get().then((orderDoc) => {
            if (orderDoc.exists) {
              const _order = orderDoc.data() as IShopOrder;
              setCurrentShopOrder(_order);
              ticket.salesOrder = _order.salesOrder;
              setQATicket(ticket);
              if (runnerId) {
                const workOrder = find(_order.runners, (r: IWorkOrder) => r.id === runnerId);
                if (!workOrder) return;
                setCurrentWorkOrder(workOrder);
                const routerStep = find(routerSteps, (r: IRouterStep) => r.id === workOrder.step as string);
                setWorkOrderStep(workOrder.step as string);
                setQATicket({ ...ticket, department: routerStep?.department as string, runnerId });
              }
            } else {
              setQATicket(ticket);
            }
          });
        } else {
          setQATicket(ticket);
        }
      });
    }
  }, []);
  return (
    <>
      <HeaderBackdrop />
      <QATicketDetailHeader id={qaTicket.id} />
      <ComponentWrapper>
        <DetailColumn expand={!qaTicket.underInvestigation}>
          <PartDetailColumnHeader>Report Details</PartDetailColumnHeader>
          {qaTicket && (
          <>
            {/* General Info */}
            <QADetailRow width="100%" gap="20px">
              <DetailInputWithCallback
                id="qa-ticket-reported-by"
                label="Person Reporting"
                placeholder={mapOpenedBy(currentUser.email.split('@')[0])}
                value={qaTicket.createdBy}
                callback={onChangeCreatedBy}
                extend={false}
                isLabel={false}
              />
              <DetailDateWithCallback
                id="qa-ticket-opened-date"
                label="Report Date"
                LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Date that this report was created." />}
                value={qaTicket.createdDate?.toDate() || new Date()}
                callback={onChangeDate('createdDate')}
              />
              <DetailDateWithCallback
                id="qa-ticket-incident-date"
                label="Incident Date"
                LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Date that the incident that caused this report happened." />}
                value={qaTicket.incidentDate?.toDate() || new Date()}
                callback={onChangeDate('incidentDate')}
              />
              <DetailInputWithCallback
                id="qa-ticket-sales-order"
                label="Sales Order"
                LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="If this report is attached to a sales order, enter the number here." />}
                placeholder="e.g,. 1234-1"
                value={qaTicket.salesOrder}
                callback={onChangeSalesOrder}
                extend={false}
                isLabel={false}
              />
            </QADetailRow>
            <QADetailRow>
              <DetailInputWithCallback
                id="qa-ticket-title"
                label="Report Name"
                LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="A short name describing the non-conformance" />}
                placeholder="e.g., Flawed wood chipped out"
                value={qaTicket.title}
                callback={onChangeDescription('title')}
                width="500px"
                isLabel={false}
              />
              <ActiveSelector
                key="qa-ticket-report-type-selector"
                activeType={qaTicket.reportType}
                callback={onChangeDescription('reportType', true)}
                allValue="Non-Conformance"
                activeValue="Quality"
                inactiveValue={undefined}
                label="Report type"
                showLabel
                margin="0 0 18px 0"
              />
            </QADetailRow>
            {includes(investigationUsers, currentUser.email) && (
              <>
                <PartDetailColumnHeader>Issue Discussion</PartDetailColumnHeader>
                <QADetailRow>
                  <DetailActiveSelectorWithCallback
                    initialState={qaTicket.underInvestigation || false}
                    callback={onChangeInvestigationStatus}
                    disabled={!includes(investigationUsers, currentUser.email) || qaTicket.resolutionDate !== null}
                    componentLabel="Needs Discussion?"
                    componentMargin="0 0 12px 0"
                    checkedLabel="YES"
                    uncheckedLabel="NO"
                  />
                  <DetailDateWithCallback
                    value={qaTicket.resolutionDate?.toDate()}
                    placeholder="Date of resolution"
                    id="qa-ticket-resolutionDate"
                    label="Resolution (Closed) Date"
                    callback={onChangeDate('resolutionDate')}
                    disabled={false}
                    disableDates={null}
                  />
                </QADetailRow>
              </>
            )}
            <PartDetailColumnHeader>Part Details</PartDetailColumnHeader>
            <QADetailRow width="100%" gap="20px">
              {(currentShopOrder && currentWorkOrder && currentWorkOrder.parts) ? (
                <QATicketPartSelectorDropdown workOrder={currentWorkOrder} callback={onChangePartNumberFromDropdown} value={qaTicket.partNumber} />
              ) : (
                <DetailInputWithCallback
                  id="qa-ticket-part-number"
                  label="Part Number"
                  width="160px"
                  placeholder="e.g., NASHG_00590"
                  value={qaTicket.partNumber}
                  callback={onChangePartNumber}
                  extend={false}
                  isLabel={false}
                />
              )}
              <DetailInputWithCallback
                id="qa-ticket-customer"
                label="Customer"
                placeholder="e.g., Nash"
                value={qaTicket.customer}
                callback={onChangeDescription('customer')}
                extend={false}
                isLabel={false}
              />
              {qaTicket.partNumber.length > 0 && (
                <QADetailRow gap="20px">
                  <DetailNumberInputWithCallback
                    id="qa-ticket-part-quantity"
                    label="Qty."
                    placeholder="e.g., 1"
                    value={qaTicket.partQuantity}
                    callback={onChangePartQuantity}
                    width="80px"
                  />
                  {(currentShopOrder && currentWorkOrder && currentWorkOrder.parts) ? (
                    <DetailInputWithCallback
                      id="qa-ticket-est-cost"
                      label="Estimated Cost (Material)"
                      width="160px"
                      LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Value of material consumed at point of non-conformance" />}
                      value={calculatePartValue()}
                      disabled
                      callback={noop}
                      extend={false}
                      isLabel
                    />
                  ) : (
                    <DetailInputWithCallback
                      id="qa-ticket-est-cost"
                      label="Estimated Cost"
                      LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Value of material consumed at point of non-conformance" />}
                      width="160px"
                      placeholder="e.g., $100"
                      value={qaTicket.estimatedCost}
                      callback={onChangeDescription('estimatedCost')}
                      extend={false}
                      isLabel={false}
                    />
                  )}
                </QADetailRow>
              )}
            </QADetailRow>
            <DetailTextAreaWithCallback
              id="qa-ticket-part-description"
              label="Part Description"
              placeholder="Enter part number in above field to add description"
              value={qaTicket.partDescription}
              changeCallback={onChangeDescription('partDescription')}
              blurCallback={onChangeDescription('partDescription')}
              disabled={currentWorkOrder && currentShopOrder && currentWorkOrder.parts}
              height="36px"
            />
            <DetailInputWithCallback
              id="qa-ticket-other-models"
              label="Other Models Possibly Affected"
              placeholder="e.g., ANY"
              value={qaTicket.otherModelsAffected}
              callback={onChangeDescription('otherModelsAffected')}
              extend
              isLabel={false}
            />

            <PartDetailColumnHeader>{qaTicket.reportType === 'non-conformance' ? 'Non-Conformance' : 'Quality Details'}</PartDetailColumnHeader>
            {qaTicket.reportType === 'non-conformance' && (
              <QADetailRow width="100%" gap="20px">
                <QATicketDepartmentDropdown workOrderStep={workOrderStep} callback={onChangeDepartment} value={qaTicket.department} />
                <QATicketReasonCodeDropdown
                  department={qaTicket.department}
                  reasonCodeCallback={onChangeReasonCode}
                  correctiveActionCallback={onChangeCorrectiveAction}
                  exceptionCode={qaTicket.nonConformanceExceptionCode}
                  correctiveActionCode={qaTicket.nonConformanceCorrectiveAction}
                />
              </QADetailRow>
            )}
            <DetailTextAreaWithCallback
              id="qa-ticket-nc-description"
              label={qaTicket.reportType === 'non-conformance' ? 'Non-Conformance Description' : 'Ticket Description'}
              placeholder="Describe the non-conformance that generated this ticket"
              value={qaTicket.nonConformanceDescription}
              changeCallback={onChangeDescription('nonConformanceDescription')}
              blurCallback={onChangeDescription('nonConformanceDescription')}
              disabled={false}
            />

            {(qaTicket.underInvestigation && qaTicket.reportType === 'non-conformance') && (
              <>
                {/* Perceived cause of problem */}
                <DetailTextAreaWithCallback
                  id="qa-ticket-perceived-cause"
                  label="Perceived Cause of Problem"
                  placeholder="Describe the surface-level issue that cause this QA problem"
                  value={qaTicket.perceivedCauseDescription}
                  changeCallback={onChangeDescription('perceivedCauseDescription')}
                  blurCallback={onChangeDescription('perceivedCauseDescription')}
                  disabled={isShopUser}
                />
              </>
            )}
          </>
          )}
        </DetailColumn>
        <DetailRightColumn>
          {qaTicket.underInvestigation && (
          <>
            <PartDetailColumnHeader>{qaTicket.reportType === 'non-conformance' ? 'Corrective Steps' : 'Implementation Steps'}</PartDetailColumnHeader>
            {/* Suggestions for immediate correction */}
            <NewElementButton margin="0 0 24px 0" callback={onAddCorrectiveStep} label={qaTicket.reportType === 'non-conformance' ? 'New Corrective Step' : 'New Implementation Step'} />
            {(qaTicket.correctiveSteps || []).map((c: IQACorrectiveStep) => (<QACorrectiveStep correctiveStep={c} callback={onUpdateCorrectiveStep} isShopUser={isShopUser} reportType={qaTicket.reportType} />))}
          </>
          )}
          <ImageSectionWrapper>
            <PartDetailColumnHeader>Images</PartDetailColumnHeader>
            <ImageButtonRow>
              <QATicketImageUploader callback={onUploadImages} />
            </ImageButtonRow>
            <ThumbnailWrapper>
              {qaTicket.nonConformanceImageUrls.length ? (
                <Image.PreviewGroup>
                  {qaTicket.nonConformanceImageUrls.map((url: string) => (
                    <QAImageThumbnailPreview deleteCallback={onDeleteImage} imageUrl={url} />
                  ))}
                </Image.PreviewGroup>
              ) : (
                <div style={{ width: '50%', height: 52, padding: 4 }} />
              )}
            </ThumbnailWrapper>
          </ImageSectionWrapper>
        </DetailRightColumn>
        {/* Mobile section for images (should always be at bottom of screen if mobile) */}
        <MobileDetailColumn>
          <ImageSectionWrapper mobile>
            <PartDetailColumnHeader>Images</PartDetailColumnHeader>
            <ImageButtonRow>
              <QATicketImageUploader callback={onUploadImages} />
            </ImageButtonRow>
            <ThumbnailWrapper>
              {qaTicket.nonConformanceImageUrls.length ? (
                <Image.PreviewGroup>
                  {qaTicket.nonConformanceImageUrls.map((url: string) => (
                    <QAImageThumbnailPreview deleteCallback={onDeleteImage} imageUrl={url} />
                  ))}
                </Image.PreviewGroup>
              ) : (
                <div style={{ width: '50%', height: 52, padding: 4 }} />
              )}
            </ThumbnailWrapper>
          </ImageSectionWrapper>
        </MobileDetailColumn>
      </ComponentWrapper>
    </>
  );
};

export default InventoryDetail;
