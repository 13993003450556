import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find, findIndex } from 'lodash';
import styled from 'styled-components';
import {
  currentCustomerShippingAddressesAtom,
  customerShippingAddressesEditedAtom,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import NotFound from 'shared/components/Utility/NotFound';
import ShippingAddressDefaultSwitch from './ShippingAddressDetailFields/ShippingAddressDefaultSwitch';
import NewShippingAddressButton from './Buttons/NewShippingAddressButton';
import SaveShippingAddressButton from './Buttons/SaveShippingAddressButton';
import DeleteContactButton from './Buttons/DeleteShippingAddressButton';
import DiscardShippingAddressButton from './Buttons/DiscardShippingAddressButton';
import ShippingAddressSelector from './ShippingAddressDetailFields/ShippingAddressSelector';
import DetailInputWithCallback from '../../../../shared/components/Input/DetailInputWIthCallback';
import {
  ItemDetailsLabel,
  RadioGroupWrapper,
  ViewTypeRadioButton, ViewTypeRadioGroup,
  ViewTypeSelect,
  ViewTypeSelectOption,
} from '../../../../shared/styledComponents/inputs';
import { ViewTypeSelectWrapper } from '../../../../shared/styledComponents/containers';

const ComponentWrapper = styled(FlexColumn)`
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
`;

const AddressSelectorRow = styled(FlexRow)`
  width: calc(100% - 12px);
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
`;

const AddressActionButtonWrapper = styled(FlexRow)`
    margin-top: 18px;
    gap: 12px;
`;

const DetailRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 32px;
`;
const SearchDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgrey;
  margin: 16px 0;
`;
const ShippingAddressDetail = () => {
  const [currentCustomerShippingAddresses, setCurrentCustomerShippingAddressess] = useRecoilState(currentCustomerShippingAddressesAtom);
  const [selectedShippingAddress, setSelectedShippingAddress] = useRecoilState(selectedCustomerShippingAddressAtom);
  const edited = useRecoilValue(customerShippingAddressesEditedAtom);
  const setEdited = useSetRecoilState(customerShippingAddressesEditedAtom);
  // @ts-ignore

  const onChangeShippingAccount = (value: string) => {
    setSelectedShippingAddress({ ...selectedShippingAddress, shippingAccount: value });
    setEdited(true);
  };

  const onChangeShipVendor = (e: any) => {
    const typeValue = e.target?.value || e;
    setSelectedShippingAddress({ ...selectedShippingAddress, shippingVendor: typeValue });
    setEdited(true);
  };

  const onChangeAddressDetail = (key: string) => (value: string) => {
    const updated = { ...selectedShippingAddress };
    // @ts-ignore
    updated[key] = value;
    setSelectedShippingAddress(updated);
    setEdited(true);
  };

  return (
    <>
      {currentCustomerShippingAddresses.length ? (
        <ComponentWrapper>
          <AddressSelectorRow>
            <ShippingAddressSelector label="Addresses" />
            {edited ? (
              <AddressActionButtonWrapper>
                <SaveShippingAddressButton />
                {find(currentCustomerShippingAddresses, (c) => c.Id === selectedShippingAddress.Id) ? (
                  <DeleteContactButton />
                ) : (
                  <DiscardShippingAddressButton />
                )}
              </AddressActionButtonWrapper>
            ) : (
              <AddressActionButtonWrapper>
                <NewShippingAddressButton />
                <DeleteContactButton />
              </AddressActionButtonWrapper>
            )}
          </AddressSelectorRow>
          <SearchDivider />
          <DetailRow key="address-name-row">
            <DetailInputWithCallback
              id="address-name-input"
              label="Address Name"
              placeholder="A short name to remember this address"
              value={selectedShippingAddress.name}
              callback={onChangeAddressDetail('name')}
              extend={false}
              isLabel={false}
              wrapperWidth="40%"
              width="100%"
            />
            {/* <ShippingAddressName key="address-name-input" /> */}
            <ShippingAddressDefaultSwitch key="address-default-switch" />
          </DetailRow>
          <DetailRow>
            <DetailInputWithCallback
              id="address-shipping-contact-input"
              label="Shipping Contact"
              placeholder="e.g., James Brown"
              value={selectedShippingAddress.Line1}
              callback={onChangeAddressDetail('Line1')}
              extend={false}
              isLabel={false}
              wrapperWidth="40%"
              width="100%"
            />
            <FlexColumn align="flex-start">
              <ItemDetailsLabel key="shipping-vendor-selector-details-label">Shipping Vendor</ItemDetailsLabel>
              <RadioGroupWrapper>
                <ViewTypeSelectWrapper>
                  <ViewTypeSelect
                    size="default"
                    value={selectedShippingAddress.shippingVendor}
                    onChange={onChangeShipVendor}
                    marginLeft="0px"
                  >
                    <ViewTypeSelectOption value="UPS">UPS</ViewTypeSelectOption>
                    <ViewTypeSelectOption value="FedEx">FedEx</ViewTypeSelectOption>
                    <ViewTypeSelectOption value="Customer">Customer</ViewTypeSelectOption>
                  </ViewTypeSelect>
                </ViewTypeSelectWrapper>
                <ViewTypeRadioGroup value={selectedShippingAddress.shippingVendor} buttonStyle="solid" onChange={onChangeShipVendor} marginLeft="0px">
                  <ViewTypeRadioButton
                    value="UPS"
                  >
                    UPS
                  </ViewTypeRadioButton>
                  <ViewTypeRadioButton
                    value="FedEx"
                  >
                    FedEx
                  </ViewTypeRadioButton>
                  <ViewTypeRadioButton
                    value="Customer"
                  >
                    Customer
                  </ViewTypeRadioButton>
                </ViewTypeRadioGroup>
              </RadioGroupWrapper>
            </FlexColumn>
          </DetailRow>
          <DetailRow>
            <DetailInputWithCallback
              id="address-shipping-line-2-input"
              label="Address Line 1"
              placeholder="e.g., 1234 Any St."
              value={selectedShippingAddress.Line2}
              callback={onChangeAddressDetail('Line2')}
              extend={false}
              isLabel={false}
              wrapperWidth="40%"
              width="100%"
            />
            {/* <ShippingAddressLine2 key="address-line-2-input" /> */}
            <DetailInputWithCallback key="shipping-account-number" label="Shipping Account #" placeholder="Shipping account used for this address" value={selectedShippingAddress.shippingAccount || ''} callback={onChangeShippingAccount} extend={false} isLabel={false} />
          </DetailRow>
          <DetailInputWithCallback
            id="address-shipping-line-3-input"
            label="Address Line 2"
            placeholder="e.g., Building A"
            value={selectedShippingAddress.Line3}
            callback={onChangeAddressDetail('Line3')}
            extend={false}
            isLabel={false}
            wrapperWidth="40%"
            width="100%"
          />
          <DetailInputWithCallback
            id="address-shipping-line-4-input"
            label="Address Line 3"
            placeholder="e.g., Ste. 3"
            value={selectedShippingAddress.Line4}
            callback={onChangeAddressDetail('Line4')}
            extend={false}
            isLabel={false}
            wrapperWidth="40%"
            width="100%"
          />
          <DetailRow key="address-city-state-zip-row">
            <DetailInputWithCallback
              id="address-shipping-city-input"
              label="City"
              placeholder="e.g., Busytown"
              value={selectedShippingAddress.City}
              callback={onChangeAddressDetail('City')}
              extend={false}
              isLabel={false}
              wrapperWidth="40%"
              width="100%"
            />
            {/* <ShippingCity key="address-city-input" /> */}
            <DetailInputWithCallback
              id="address-shipping-state-input"
              label="State"
              placeholder="e.g., CA"
              value={selectedShippingAddress.CountrySubDivisionCode}
              callback={onChangeAddressDetail('CountrySubDivisionCode')}
              extend={false}
              isLabel={false}
              wrapperWidth="15%"
              width="100%"
            />
            <DetailInputWithCallback
              id="address-shipping-zip-input"
              label="Postal Code"
              placeholder="e.g., 99999"
              value={selectedShippingAddress.PostalCode}
              callback={onChangeAddressDetail('PostalCode')}
              extend={false}
              isLabel={false}
              wrapperWidth="15%"
              width="100%"
            />
          </DetailRow>
          <DetailInputWithCallback
            id="address-shipping-country-input"
            label="Country"
            placeholder="e.g., USA"
            value={selectedShippingAddress.Line4}
            callback={onChangeAddressDetail('Country')}
            extend={false}
            isLabel={false}
            wrapperWidth="40%"
            width="100%"
          />
        </ComponentWrapper>
      ) : (
        <NotFound compact title="No addresses found" key="addresses-not-found-placeholder" />
      )}
    </>
  );
};

export default ShippingAddressDetail;
