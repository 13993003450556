import React, { useEffect, useState } from 'react';
import { find, findIndex, update } from 'lodash';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IOrderItem } from 'shared/types/dbRecords';
import { orderItemsAtom } from 'shared/state/orderState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import PartNotes from '../OrderItems/OrderItemDetail/PartNotes';
import OrderShipItemNotes from './OrderShipItemNotes';
import DetailNumberInputWithCallback from '../../../../shared/components/Input/DetailNumberInputWIthCallback';

interface IComponent {
  orderItem: IOrderItem;
  shipCallback: (partId: string, value: number) => void;
  cancelCallback: (partId: string, value: number) => void;
  notesCallback: (partId: string, value: string) => void;
}

const ComponentWrapper = styled(FlexRow)`
  width: 100%;
  padding: 8px 0;
  justify-content: space-between;
  gap: 12px;
  border-bottom: 1px solid lightgrey;
`;
const PartDescriptionWrapper = styled(FlexRow)`
  width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

const DetailNotesWrapper = styled(FlexColumn)`
  //width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`;

const ShipQuantityWrapper = styled(FlexRow)`
  justify-content: space-between;
  gap: 24px;
`;

const PartNumber = styled.p`
  width: 116px;
  font-weight: bold;
  margin: 0;
`;
const PartDescription = styled.p`
  margin: 0;
`;

const OrderShipItem = ({
  orderItem, shipCallback, cancelCallback, notesCallback,
}:IComponent) => {
  const onChangeShipQuantity = (value: number) => {
    shipCallback(orderItem.id, value);
  };
  const onChangeCancelQuantity = (value: number) => {
    cancelCallback(orderItem.id, value);
  };

  const onChangeNotes = (value: string) => {
    notesCallback(orderItem.id, value);
  };

  return (
    <ComponentWrapper>
      {orderItem && (
        <>
          <PartDescriptionWrapper>
            <PartNumber>{orderItem.Sku}</PartNumber>
            <DetailNotesWrapper>
              <PartDescription>{orderItem.Description}</PartDescription>
              <OrderShipItemNotes orderShipItemNotes={orderItem.notes} callback={onChangeNotes} />
            </DetailNotesWrapper>
          </PartDescriptionWrapper>
          <ShipQuantityWrapper>
            <DetailNumberInputWithCallback
              key={`order-item-to-ship-quantity-${orderItem.Sku}`}
              label="Ship"
              placeholder={orderItem.quantityOpen.toString()}
              value={orderItem.quantityShipped}
              callback={onChangeShipQuantity}
              min={0}
            />
            <DetailNumberInputWithCallback
              key={`order-item-to-ship-quantity-${orderItem.Sku}`}
              label="Cancel"
              placeholder="0"
              value={orderItem.quantityCanceled}
              callback={onChangeShipQuantity}
              min={0}
              max={orderItem.quantityOpen - orderItem.quantityShipped}
            />
          </ShipQuantityWrapper>
        </>
      )}
    </ComponentWrapper>
  );
};

export default OrderShipItem;
