import React, {
  useEffect,
} from 'react';
import {
  Button,
  Input, InputNumber, Modal,
} from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentNcRecordAtom,
  ncAdditionalNotesAtom,
  ncCorrectiveActionAtom, ncCorrectiveActionsObjectAtom,
  ncCurrentCorrectiveActionsListAtom, ncCurrentDepartmentAtom, ncCurrentDepartmentListAtom,
  ncCurrentReasonAtom,
  ncCurrentReasonsListAtom, ncDepartmentObjectAtom,
  ncPartNumberAtom,
  ncPartNumberQuantityAtom,
  ncReasonsObjectAtom,
  ncScanDepartmentAtom,
} from 'shared/state/utilState';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { Caption } from 'shared/typography';
import { superAdminUserEmailAtom } from 'shared/state/routingState';
import { shopOperatorsObjectAtom, currentShopOperatorAtom, currentShopOperatorsListAtom } from 'shared/state/siteState';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { currentShopOrderAtom } from 'shared/state/orderState';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWIthCallback';
import { find, noop } from 'lodash';
import { partNumberWithZeroes } from 'shared/util';
import NCDataList from './NCDataList';
import MaxPartClassList from './MaxPartClassList';
import NCImageUploader from './NCImageUploader';

const ReportFormWrapper = styled(FlexColumn)`
    justify-content: space-around;
    width: 90%;
    margin: 90px auto 24px auto;
  
  @media ${theme.device.laptopL} {
    align-items: flex-start;
    width: 40%;
    margin: 24px auto;
  }
  `;
const FormHeaderRow = styled(FlexRow)`
    width: 100%;
    justify-content: space-between;
    
    border-bottom: 1px solid lightgray;
    margin-bottom: 24px;
    & * {
      margin: 0;
    }
  `;
const FormFieldColumn = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    margin-bottom: ${theme.spacing(2)};
  `;
const FormFieldGroup = styled(FlexColumn)`
    width: 100%;
    justify-content: flex-start;
    gap: 9%;
  
  @media ${theme.device.laptopL} {
    flex-direction: row;
    align-items: flex-start;
  }
  `;
const FormInputLabelWrapper = styled(FlexRow)`
  justify-content: flex-start;
`;
const FormInputLabel = styled(Caption)`
    margin: 0 0 ${theme.spacing(0.5)} 0;
  `;
const FormInput = styled(Input)`
    font-size: 18px;
  `;
const FormInputNumber = styled(InputNumber)`
    font-size: 18px;
    padding: 1.5px 11px;
  `;
const FormTextArea = styled(Input.TextArea)`
  width: 100%;
  height: 120px;
  & textarea {
    height: 100%;
  }
`;
const FormFieldRequired = styled.span`
  color: ${theme.palette.error.hue};
  position: relative;
  bottom: 2px;
`;

const ImageUploadButton = styled(Button)`
  border-radius: 8px;
`;
const ImageUploadInput = styled.input`
    visibility: hidden;
`;

interface IComponent {
  runnerId: string;
}
export default ({ runnerId }: IComponent) => {
  const shopOrder = useRecoilValue(currentShopOrderAtom);
  const runner = find(shopOrder.runners, (r: IRunner) => r.id === runnerId);
  const [ncRecord, setNcRecord] = useRecoilState(currentNcRecordAtom);
  const departmentList = useRecoilValue(ncDepartmentObjectAtom);
  const ncCorrectiveAction = useRecoilValue(ncCorrectiveActionAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);

  const onPartNumberChange = (e: any) => {
    setNcRecord({
      ...ncRecord,
      partNumber: e.target.value,
    });
  };
  const onPartNumberBlur = (e: any) => {
    let ncPartNumber = e.target.value.match(/[a-z]{5}_[0-9]{5}/i) ? e.target.value : null;
    if (!ncPartNumber) {
      const fullPartNumber = partNumberWithZeroes(e.target.value || '0');
      ncPartNumber = `${ncRecord.customerId}_${fullPartNumber}`;
    }

    const part = find(runner.parts, (p: any) => p.Sku === ncPartNumber);
    if (!part) {
      setNcRecord({
        ...ncRecord,
        partNumber: '',
      });
      return Modal.error({
        title: 'Part not found',
        content: 'The part you entered was not found on this work order. Please double check your entry and let Keith know if you are getting this message in error.',
      });
    }

    setNcRecord({
      ...ncRecord,
      partNumber: ncPartNumber,
    });
  };

  const onDepartmentChange = (e: string) => {
    const d = _.find(departmentList, (i: { departments: string[], type: string, label: string, id: string}) => i.label === e);
    setNcRecord({
      ...ncRecord,
      department: d.label,
    });
  };
  const onShopOperatorChange = (e: string) => {
    setNcRecord({
      ...ncRecord,
      shopOperator: e,
    });
  };
  const onNonConformanceQtyChange = (e: any) => {
    setNcRecord({
      ...ncRecord,
      quantity: e,
    });
  };

  const onNotesChange = (value: string) => {
    setNcRecord({
      ...ncRecord,
      notes: value,
    });
  };

  const onMaxPartClassChange = (e: any) => {
    setNcRecord({
      ...ncRecord,
      maxPartClass: e,
    });
  };

  useEffect(() => {
  }, [ncRecord.department]);

  return (
    <ReportFormWrapper key="non-conformance-form">
      <FormHeaderRow key="sales-order-row">
        <FormInputLabel key="sales-order-label">Sales Order</FormInputLabel>
        <h2 key="sales-order-field">{`${ncRecord.customerId} - ${ncRecord.salesOrder.orderNumber} ${ncRecord.workOrder}`}</h2>
      </FormHeaderRow>
      <FormFieldGroup key="part-details">
        <FormFieldColumn>
          <FlexRow justify="flex-start">
            <FormInputLabel>Department</FormInputLabel>
            <FormFieldRequired>*</FormFieldRequired>
          </FlexRow>
          <NCDataList
            ncField="department"
            listTitle="Department"
            listPrompt="Select Department"
            ncOptionsAtom={ncDepartmentObjectAtom}
            ncSelectedOptionAtom={ncCurrentDepartmentAtom}
            ncOptionsList={ncCurrentDepartmentListAtom}
            onChange={onDepartmentChange}
            defaultValue={ncRecord.department}
          />
          <ScopedComponent whitelist={[...superAdminEmails.emails]}>
            <FlexRow justify="flex-start">
              <FormInputLabel>Max. Part Class</FormInputLabel>
            </FlexRow>
            <MaxPartClassList
              ncField="maxPartClass"
              listPrompt="Select the max part class for this ticket"
              onChange={onMaxPartClassChange}
            />
          </ScopedComponent>
        </FormFieldColumn>
        <FormFieldColumn>
          <FlexRow justify="flex-start">
            <FormInputLabel>Operator</FormInputLabel>
            <FormFieldRequired>*</FormFieldRequired>
          </FlexRow>
          <NCDataList
            ncField="shopOperator"
            defaultValue={ncRecord.shopOperator}
            listTitle="Operator"
            listPrompt="Select who's working on this"
            ncOptionsAtom={shopOperatorsObjectAtom}
            ncSelectedOptionAtom={currentShopOperatorAtom}
            ncOptionsList={currentShopOperatorsListAtom}
            onChange={onShopOperatorChange}
          />
        </FormFieldColumn>
      </FormFieldGroup>

      <FormFieldGroup key="part-number-row">
        <FormFieldColumn key="part-number-column">
          <FlexRow justify="flex-start">
            <FormInputLabel>Part Number</FormInputLabel>
            <FormFieldRequired>*</FormFieldRequired>
          </FlexRow>
          <FormInput
            size="large"
            placeholder={`e.g., ${shopOrder.customer.id}_00001, 001`}
            value={ncRecord.partNumber}
            onChange={onPartNumberChange}
            onBlur={onPartNumberBlur}
            key="part-number-field"
            type="tel"
          />
        </FormFieldColumn>
        <FormFieldColumn key="part-quantity-column">
          <FormInputLabel>Total Qty</FormInputLabel>
          <FormInputNumber
            type="number"
            size="large"
            defaultValue={1}
            value={ncRecord.quantity}
            min={1}
            onChange={onNonConformanceQtyChange}
            key="part-quantity-field"
          />
        </FormFieldColumn>
      </FormFieldGroup>
      <FormFieldGroup>
        <FormFieldColumn>
          <FlexRow justify="flex-start">
            <FormInputLabel>Reason Code</FormInputLabel>
            <FormFieldRequired>*</FormFieldRequired>
          </FlexRow>
          <NCDataList
            ncField="reasonCode"
            defaultValue={ncRecord.reasonCode}
            listTitle="Reason Code"
            listPrompt="Select Reason Code"
            ncOptionsAtom={ncReasonsObjectAtom}
            ncSelectedOptionAtom={ncCurrentReasonAtom}
            ncOptionsList={ncCurrentReasonsListAtom}
          />
        </FormFieldColumn>
        <FormFieldColumn>
          <FlexRow justify="flex-start">
            <FormInputLabel>Corrective Action</FormInputLabel>
            <FormFieldRequired>*</FormFieldRequired>
          </FlexRow>
          <NCDataList
            ncField="correctiveAction"
            defaultValue={ncRecord.correctiveAction}
            listTitle="Corrective Action"
            listPrompt="Select Corrective Action"
            ncOptionsAtom={ncCorrectiveActionsObjectAtom}
            ncSelectedOptionAtom={ncCorrectiveActionAtom}
            ncOptionsList={ncCurrentCorrectiveActionsListAtom}
          />
        </FormFieldColumn>
      </FormFieldGroup>
      <FormFieldColumn>
        <FormInputLabel>Non-conformance Photo(s)</FormInputLabel>
        <FlexRow justify="flex-start" style={{ gap: 16 }}>
          <NCImageUploader />
        </FlexRow>
      </FormFieldColumn>
      <FormFieldColumn>
        <FlexRow justify="flex-start">
          {/* <FormInputLabel>Additional Notes</FormInputLabel> */}
          {ncCorrectiveAction === 'Other' && (
          <FormFieldRequired>*</FormFieldRequired>
          )}
        </FlexRow>
        <DetailTextAreaWithCallback id="nc-form-notes" label="Additional notes" placeholder="Add context with a note" value={ncRecord.notes} changeCallback={onNotesChange} blurCallback={noop} />
        {/* <FormTextArea */}
        {/*  showCount */}
        {/*  maxLength={280} */}
        {/*  value={ncRecord.notes} */}
        {/*  onChange={onNotesChange} */}
        {/*  placeholder="Add context with a note" */}
        {/* /> */}
      </FormFieldColumn>
    </ReportFormWrapper>
  );
};
