import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { newBodyAtom, newNeckAtom } from 'shared/state/pricingState';
import useFirebase from 'vendor/Firebase';
import { PART_VIEWER_COLLECTION } from '../../../../shared/state/partViewState';

const ActiveSwitch = styled(Switch)`
  margin: 4px 0 6px 0;
`;

interface IComponent {
  partType: 'neck'|'body';
  disabled: boolean;
  callback: (isActive: boolean) => void;
}
const PartActiveSelector = ({ partType, disabled, callback }: IComponent) => {
  const partStateAtom = partType.match(/body/i) ? newBodyAtom : newNeckAtom;
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(partStateAtom);
  const { firestore } = useFirebase();
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const isDev = localStorage.getItem('useTestData') === 'true';
  const [loading, setLoading] = useState<boolean>(false);
  const [_disabled, setDisabled] = useState<boolean>(false);

  const onChange = (toggleState: boolean) => {
    setLoading(true);
    firestore.collection(partViewerDbString).doc(partState.id).update({ active: toggleState }).then(() => {
      if (!isDev) {
        firestore.collection(`${partViewerDbString}-test`).doc(partState.id).update({ active: toggleState }).then(() => {
          setLoading(false);
          setPartState({ ...partState, active: toggleState });
          callback(toggleState);
        });
      } else {
        setLoading(false);
        setPartState({ ...partState, active: toggleState });
        callback(toggleState);
      }
    });
  };

  useEffect(() => {
    setDisabled(disabled);
  }, [disabled]);

  return (
    <ItemDetailsColumnWrapper key="part-number-item-details-wrapper">
      <ItemDetailsLabel key="part-number-item-details-label">Status</ItemDetailsLabel>
      <ActiveSwitch
        loading={loading}
        checked={partState.active}
        checkedChildren="ACTIVE"
        unCheckedChildren="INACTIVE"
        onChange={onChange}
        disabled={_disabled}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default PartActiveSelector;
