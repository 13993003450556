import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { Tooltip, notification } from 'antd';
import { ContentCopy } from '@styled-icons/material';
import { partViewerConfigDataAtom, partViewerTypeAtom } from 'shared/state/partViewState';

import { customerConfig } from 'shared/partParser/util';
import { CopyRecordIcon, RouterTableCopyButton, RouterTableCopyButtonWrapper } from '../../../../../ProductionSchedule/styledComponents';
import theme from '../../../../../../shared/theme';
import { currentCustomerAtom } from '../../../../../../shared/state/customerState';
import { discountPrice } from '../../../../../../shared/data';

const shortid = require('shortid');
const _ = require('lodash');

interface IComponentInterface {
  id: string;
  record: any;
  hidden: boolean;
}

const CopyButton = styled(RouterTableCopyButton)`
  opacity: ${(props: any) => (props.hidden ? 0 : 1)};
`;
const CopyIcon = styled(ContentCopy)`
  color: ${theme.palette.primary.hue};
  width: 20px;
`;

export default ({ id, record, hidden }: IComponentInterface) => {
  const partViewerConfigData = useRecoilValue(partViewerConfigDataAtom);
  const partViewerType = useRecoilValue(partViewerTypeAtom);
  const currentCustomer = useRecoilValue(currentCustomerAtom);

  const onCopyPartRecordData = (dataRecord: any) => (e: any) => {
    if (!currentCustomer.id) {
      notification.error({
        message: 'No Customer Specified',
        description:
                  'Please pick a customer from the dropdown on the left so we know what attributes to copy!',
        placement: 'bottomRight',
        className: 'data-copy-error',
      });
      return;
    }
    const customerPartConfig = _.sortBy(
      customerConfig(partViewerConfigData, currentCustomer.id, record.type, true),
      (o: any) => o.sort,
    );

    let modelData = ['1', ...customerPartConfig
      .map((o: any) => o.objectKey)
      .map((k: string) => {
        if (!dataRecord) return '';
        if (dataRecord[k] === 'No') return 'N';
        if (dataRecord[k] === 'Yes') return 'Y';
        return dataRecord[k];
      })];

    const discountedPrice = discountPrice(dataRecord, currentCustomer, 1);
    if (_.includes(['EGGLE', 'SADOW'], currentCustomer.id)) {
      modelData = [
        dataRecord.active ? 'Y' : 'N',
        '',
        dataRecord.Sku,
        dataRecord.Description,
        '',
        '2',
        discountedPrice,
      ];
    } else {
      modelData = [
        dataRecord.active ? 'Y' : 'N',
        dataRecord.Sku,
        dataRecord.Description,
        '',
        '2',
        discountedPrice,
      ];
    }
    const addedData = _.includes(['SADOW', 'LUXXT', 'BLOOM'], currentCustomer.id) ? '\r\t' : '';

    navigator.clipboard.writeText(`${modelData.join('\t')}${addedData}`)
      .then(() => {
        notification.open({
          message: 'Part data copied!',
          description:
                  'You can now paste this data directly into Excel to help you compose an order',
          placement: 'bottomRight',
          className: 'data-copy-notification',
        });
        navigator.clipboard.readText();
      });
  };

  return (
    <RouterTableCopyButtonWrapper hidden={hidden} key={shortid.generate()}>
      <Tooltip placement="top" title="Copy to clipboard">
        <CopyButton
          hidden={hidden}
          id={id}
          disabled={!currentCustomer.id}
          key={shortid.generate()}
          onClick={onCopyPartRecordData(record)}
          icon={<CopyIcon />}
        />
      </Tooltip>
    </RouterTableCopyButtonWrapper>
  );
};
