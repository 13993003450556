import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import { CSVLink } from 'react-csv';
import theme from 'shared/theme';
import { CloudArrowDownIcon } from '@heroicons/react/24/solid';
import {
  CUST_CELLS, generateWorkbook, rowValueAtCol, workbookStyles,
} from 'shared/reports/workbook';
import { dateString } from '../../../../shared/data/calendar';
import { IShopOrder } from '../../../Orders/types';

const _ = require('lodash');
const shortid = require('shortid');
const xl = require('excel4node');

interface IComponent {
  customerId: string;
  context: 'customerPage'|'ordersPage';
}

const ComponentButton = styled(Button)`
  display: flex;
  width: auto;
  height: auto;
  padding: 4px 12px;
  margin-left: 8px;
  margin-bottom: unset;
  background-color: ${theme.palette.primary.hue};
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);

  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: ${theme.palette.success.hue};
  }
`;

const DownloadIcon = styled(CloudArrowDownIcon)`
  width: 24px;
  padding: 0;
  color: ${theme.palette.neutral.white};
`;

export const DownloadButton = styled(CSVLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  width: auto;
  height: auto;
  padding: 4px 12px;
    
  border: none;
  border-radius: 8px;
  background-color: ${theme.palette.success.hue};
  color: ${theme.palette.neutral.white};
  font-size: 14px;
    
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  margin-left: 8px;
  margin-bottom: unset;
    
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
      color: ${theme.palette.neutral.white};
      background-color: ${theme.palette.success[900]};
  }
  
  & * {
    margin-bottom: 0;
  }
`;

export default ({ customerId, context }: IComponent) => {
  const { firestore } = useFirebase();
  const [csvData, setCsvData] = useState<string>('');
  const downloadButtonKey = shortid.generate();

  const storedStartDate = localStorage.getItem('salesOrderStartDate');
  const storedEndDate = localStorage.getItem('salesOrderEndDate');
  const now = new Date();
  const startDate = storedStartDate ? new Date(parseInt(storedStartDate, 10)) : new Date(now.getFullYear(), 0, 1);
  const endDate = storedEndDate ? new Date(parseInt(storedEndDate, 10)) : new Date(now.getFullYear(), 11, 31);

  const autoDownload = () => {
    const downloadButton = document.getElementById('orders-download-button');
    if (downloadButton) {
      downloadButton.click();
      notification.open({
        message: 'Download complete!',
        description:
                'Your order data is in your Downloads folder.',
        placement: 'bottomRight',
        className: 'data-download-notification',
      });
      setCsvData('');
    }
  };
  const onClickDownload = (e: any) => {
    firestore.collection('orders')
      .where('customer.id', '==', customerId)
      .where('shipDate', '>=', startDate)
      .where('shipDate', '<=', endDate)
      .get()
      .then((snapshot) => {
        const bodyData: any[] = [];
        const neckData: any[] = [];
        const dataSets = [];
        snapshot.forEach((record) => {
          const recordData = record.data();
          if (recordData.type.match(/body/i)) bodyData.push(recordData);
          else neckData.push(recordData);
        });

        if (bodyData.length > 0) dataSets.push(bodyData);
        if (neckData.length > 0) dataSets.push(neckData);

        const workbook = generateWorkbook();
        const wbStyle = workbookStyles(workbook);

        const reportSheet = workbook.addWorksheet('Customer Orders', wbStyle.sheetOptions);
        reportSheet.column(CUST_CELLS.SALES_ORDER).setWidth(12);
        reportSheet.column(CUST_CELLS.DESCRIPTION).setWidth(68);
        reportSheet.column(CUST_CELLS.UNITS).setWidth(8);
        reportSheet.column(CUST_CELLS.VALUE).setWidth(10);
        reportSheet.column(CUST_CELLS.RELEASE_DATE).setWidth(12);
        reportSheet.column(CUST_CELLS.ORDER_DATE).setWidth(12);
        reportSheet.column(CUST_CELLS.SHIP_DATE).setWidth(12);

        let currentRow = 1;
        dataSets.forEach((data) => {
          const type = data[0].type.match(/body/i) ? 'Bodies' : 'Necks';

          reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string(customerId).style(wbStyle.heroStyle);
          reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string('Orders').style(wbStyle.sectionDataStyle);
          reportSheet.cell(currentRow, CUST_CELLS.VALUE).string('Value').style(wbStyle.sectionDataStyle);
          reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string('Invoiced').style(wbStyle.sectionDataStyle);

          currentRow += 1;
          const totalsRow = currentRow;
          currentRow += 2;

          reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string('Order').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.DESCRIPTION).string('Description').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string('Order Date').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, CUST_CELLS.UNITS).string('Units').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.VALUE).string('Value').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.RELEASE_DATE).string('Release\nDate').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string('Ship Date').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, CUST_CELLS.STATUS).string('Status').style(wbStyle.headerStyle);

          currentRow += 1;
          const firstDataRow = currentRow;
          _.sortBy(data, (d: IShopOrder) => d.shipDate.toDate()).forEach((record: IShopOrder) => {
            let status = 'In Production';
            if (!record.runners?.length) status = 'Not Released';
            else if (record.completed) status = 'Shipped';

            reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string(record.salesOrder).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.DESCRIPTION).string(record.description).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string(dateString(record.orderDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.UNITS).number(record.partCount).style(wbStyle.rowNumberStyle);
            reportSheet.cell(currentRow, CUST_CELLS.VALUE).number(record.orderValue).style(wbStyle.rowCurrencyStyle);
            reportSheet.cell(currentRow, CUST_CELLS.RELEASE_DATE).string(dateString(record.releaseDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string(dateString(record.shipDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.STATUS).string(status).style(wbStyle.rowStyle);
            currentRow += 1;
          });

          currentRow += 1;
          reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.DESCRIPTION).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.UNITS).formula(`SUM(${rowValueAtCol(CUST_CELLS.UNITS, firstDataRow)}:${rowValueAtCol(CUST_CELLS.UNITS, currentRow - 1)})`).style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.VALUE).formula(`SUM(${rowValueAtCol(CUST_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(CUST_CELLS.VALUE, currentRow - 1)})`).style(wbStyle.columnTotalCurrencyStyle);
          reportSheet.cell(currentRow, CUST_CELLS.RELEASE_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.STATUS).string('').style(wbStyle.columnTotalNumberStyle);

          reportSheet.cell(totalsRow, CUST_CELLS.SALES_ORDER).string(type).style(wbStyle.heroStyle);
          reportSheet.cell(totalsRow, CUST_CELLS.ORDER_DATE).number(data.length).style(wbStyle.sectionDataStyle);
          reportSheet.cell(totalsRow, CUST_CELLS.VALUE).formula(`SUM(${rowValueAtCol(CUST_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(CUST_CELLS.VALUE, currentRow - 1)})`).style(wbStyle.sectionCurrencyStyle);
          reportSheet.cell(totalsRow, CUST_CELLS.SHIP_DATE).formula(`SUMIFS(${rowValueAtCol(CUST_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(CUST_CELLS.VALUE, currentRow - 1)}, ${rowValueAtCol(CUST_CELLS.STATUS, firstDataRow)}:${rowValueAtCol(CUST_CELLS.STATUS, currentRow - 1)}, "Shipped")`).style(wbStyle.sectionCurrencyStyle);

          currentRow += 4;
        });

        workbook.writeToBuffer().then((workbookBuffer) => {
          const blob = new Blob([workbookBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, `${customerId}_Orders_Report.xlsx`);
        });
      });
  };

  return (
    <>
      {csvData.length ? (
        <DownloadButton
          id="orders-download-button"
          key={downloadButtonKey}
          filename={`${customerId}_Orders_from_${startDate.toLocaleDateString()}.csv`}
          data={csvData}
          context={context}
          onClick={() => {
            setTimeout(() => {
              setCsvData('');
            }, 200);
          }}
        >
          <DownloadIcon />
        </DownloadButton>
      ) : (
        <ComponentButton
          key={`${customerId}-order-download-button`}
          id={`${customerId}-order-download-button`}
          onClick={onClickDownload}
          type="primary"
          context={context}
        >
          <DownloadIcon />
        </ComponentButton>
      )}
    </>
  );
};
