import React, { useContext } from 'react';
import shortid from 'shortid';
import { Modal } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import firebase from 'firebase';
import { MESSAGE_DB_PATH_ATOM, orderConfirmationNotificationUsersAtom } from 'shared/state/messageState';
import {
  currentShopOrderAtom,
  ORDER_ITEMS_DB_COLLECTION,
  orderItemsAtom,
  ORDERS_DB_COLLECTION,
} from 'shared/state/orderState';
import { PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import { IOrderItem } from 'shared/types/dbRecords';
import { devLog } from 'shared/util/logging';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { ConfirmationButton, ConfirmedButton } from './ConfirmOrderButton';

const ConfirmMaterialsButton = () => {
  const { currentUser } = useContext(AuthContext);
  const { database, firestore } = useFirebase();
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbCollectionString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const partDbCollectionString = useRecoilValue(PART_VIEWER_COLLECTION);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const confirmationNotificationUsers = useRecoilValue(orderConfirmationNotificationUsersAtom);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const onConfirm = (e: any) => {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

    firestore.collection(ordersDbCollectionString).doc(currentShopOrder.id).update({
      materialsConfirmed: timestamp,
      materialsConfirmedBy: currentUser.email,
    }).then(() => {
      const messageId = shortid.generate();
      const notified = 0;
      const notificationUsers = confirmationNotificationUsers.filter((_user: string) => !currentUser.email.match(new RegExp(_user, 'i')));

      if (notificationUsers.length === 0) {
        setCurrentShopOrder({
          ...currentShopOrder,
          materialsConfirmed: timestamp,
          materialsConfirmedBy: currentUser.email,
        });
      } else {
        database.ref(`${messageDbString}/unread/${messageId}`).set({
          users: notificationUsers,
          id: messageId,
          subject: `Materials for sales order ${currentShopOrder.salesOrder} were just confirmed`,
          message: `${currentUser.email.split('@')[0]} just confirmed ${currentShopOrder.customer.id} order #${currentShopOrder.salesOrder}. This order is ready for release.`,
          clickUrl: `orders/create?edit=true&orderId=${currentShopOrder.id}`,
          archived: false,
          sent: new Date().getTime(),
        }).then(() => {
          setCurrentShopOrder({
            ...currentShopOrder,
            materialsConfirmed: timestamp,
            materialsConfirmedBy: currentUser.email,
          });
        });
      }
      Promise.all(orderItems.filter((o: IOrderItem) => o.Sku.match(/^[A-Z]{5}/))
        .map((o: IOrderItem) => firestore.collection(partDbCollectionString).doc(o.Sku).get())).then((documents) => {
        Promise.all(documents.map((d) => firestore.collection(partDbCollectionString).doc(d.id).update({ materialsConfirmed: timestamp, materialsConfirmedBy: currentUser.email }))).then((res) => {
          const confirmedOrderItems = orderItems.map((o: IOrderItem) => ({ ...o, materialsConfirmed: timestamp, materialsConfirmedBy: currentUser.email })) as IOrderItem[];
          firestore.collection(orderItemsDbCollectionString).doc(currentShopOrder.id).set({ orderItems: confirmedOrderItems }).then(() => {
            devLog('ConfirmMaterialsButton', 74, 'All parts confirmed');
            devLog('ConfirmMaterialsButton', 75, confirmedOrderItems);
            setOrderItems(confirmedOrderItems);
            setCurrentShopOrder({
              ...currentShopOrder,
              materialsConfirmed: timestamp,
              materialsConfirmedBy: currentUser.email,
            });
          });
        });
      });
    });
  };
  const onUnconfirm = (e: any) => {
    Modal.confirm({
      content: 'Mark materials "not confirmed"?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => {
        firestore.collection(ordersDbCollectionString).doc(currentShopOrder.id).update({
          materialsConfirmed: null,
          materialsConfirmedBy: null,
        }).then(() => {
          const items = orderItems.filter((o: IOrderItem) => o?.Sku?.match(/^[A-Z]{5}/));
          if (items.length === 0) {
            setCurrentShopOrder({
              ...currentShopOrder,
              materialsConfirmed: null,
              materialsConfirmedBy: null,
            });
          } else {
            Promise.all(items.filter((o: IOrderItem) => o?.Sku?.match(/^[A-Z]{5}/))
              .map((o: IOrderItem) => firestore.collection(partDbCollectionString).doc(o.Sku).get())).then((documents) => {
              Promise.all(documents.map((d) => firestore.collection(partDbCollectionString).doc(d.id).update({
                materialsConfirmed: null,
                materialsConfirmedBy: null,
              }))).then((res) => {
                const confirmedOrderItems = orderItems.map((o: IOrderItem) => ({
                  ...o,
                  materialsConfirmed: null,
                  materialsConfirmedBy: '',
                })) as IOrderItem[];
                devLog('ConfirmMaterialsButton', 73, 'All parts unconfirmed');
                firestore.collection(orderItemsDbCollectionString).doc(currentShopOrder.id).set({ orderItems: confirmedOrderItems }).then(() => {
                  setOrderItems(confirmedOrderItems);
                  setCurrentShopOrder({
                    ...currentShopOrder,
                    materialsConfirmed: null,
                    materialsConfirmedBy: null,
                  });
                });
              });
            });
          }
        });
      },
    });
  };

  return (
    <>
      { currentShopOrder && (
      <>
        { !currentShopOrder.materialsConfirmed ? (
          <ConfirmationButton disabled={orderItems.filter((i) => i).length === 0} key={shortid.generate()} onClick={onConfirm}>Confirm materials</ConfirmationButton>
        ) : (
          <ConfirmedButton key={shortid.generate()} onClick={onUnconfirm}>Materials confirmed</ConfirmedButton>
        )}
      </>
      )}
    </>
  );
};

export default ConfirmMaterialsButton;
