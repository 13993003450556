import { Select } from 'antd';
import {
  definedParts,
  PART_STATE_ATOM,
} from 'shared/data';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { bodyDataAtom, hideUnselectedOptionsAtom, partEditModeAtom } from 'shared/state/pricingState';
import { MenuSelect } from '../../styledComponents';
import { IUnitOption } from '../../../../shared/types/pricingTool';

const _ = require('lodash');

export default () => {
  const bodyData = useRecoilValue(bodyDataAtom);
  const editMode = useRecoilValue(partEditModeAtom);
  const bodyModifications = bodyData.options.bodyBlankModifications.items;
  const partType = 'body';

  // @ts-ignore
  const [newPartState, setNewPartState] = useRecoilState(PART_STATE_ATOM[partType]);

  const onClickHandler = (e: any): void => {
    const modification = _.find(definedParts(bodyModifications), (o: IUnitOption) => o.id === e) || {};
    if (modification) {
      setNewPartState({
        ...newPartState,
        // [partType]: {
        //   ...newPartState[partType],
        blankModification: modification,
        // },
      });
    }
  };

  return (
    <MenuSelect key="body-blank-modifier-menu-select" disabled={!editMode} value={newPartState.blankModification.id || 'Select a blank modifier'} onChange={onClickHandler}>
      {/* <Menu onClick={onClickHandler}> */}
      {bodyModifications
        .map((item: IUnitOption) => (
          <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
        ))}
      {/* </Menu> */}
    </MenuSelect>
  );
};
