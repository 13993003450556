import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { includes, omit } from 'lodash';
import qs from 'qs';
import { Table } from 'antd';
import styled from 'styled-components';
import {
  currentCustomerPrices,
} from 'shared/state/pricingState';
import { Body } from 'shared/typography';
import {
  copiedBomPartNumberAtom,
  showBomCopyDrawerAtom,
} from 'shared/state/partViewState';
import { scrollToHash, scrollOptions } from 'shared/scroll';
import { currentCustomerAtom } from 'shared/state/customerState';
import { IInventoryPart } from 'shared/types/dbRecords';
import { stageRedirect } from 'shared/util';
import { rgba as hexRgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import { customerUserEmailsAtom } from 'shared/state/routingState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { CustomerPricingWrapper } from '../../styledComponents';
import { EmptyPriceItem } from './styledComponents';
import {
  CHILD_SKU_COLUMN,
  SKU_COLUMN,
  CHILD_DESCRIPTION_COLUMN,
  DESCRIPTION_COLUMN,
  SELL_COLUMN,
  ACTION_COLUMN,
  CUSTOMER_SELL_COLUMN,
} from './PriceList/PriceListColumns';

const ItemTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;

interface IComponent {
  renderItems: any[];
  childViewType: 'sku'|'like'|'description';
  currentPage: number;
  pageChangeCallback: (value: number) => void;
}

export default ({
  renderItems, currentPage, pageChangeCallback, childViewType,
}: IComponent) => {
  const customerUsers = useRecoilValue(customerUserEmailsAtom);
  const { currentUser } = useContext(AuthContext);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const pricing = useRecoilValue(currentCustomerPrices);
  const [showBomCopyDrawer, setShowBomCopyDrawer] = useRecoilState(showBomCopyDrawerAtom);
  const setCopiedBomPartNumber = useSetRecoilState(copiedBomPartNumberAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const [_renderItems, setRenderItems] = useState<any>([]);
  const [_currentPage, setCurrentPage] = useState<number>(currentPage);
  const [_childViewType, setChildViewType] = useState<'sku'|'like'>(childViewType);
  const { partId } = qs.parse(window.location.search.replace('?', ''));
  const _tableColumns = [
    _childViewType === 'like' ? CHILD_SKU_COLUMN : SKU_COLUMN,
    _childViewType === 'like' ? CHILD_DESCRIPTION_COLUMN : DESCRIPTION_COLUMN,
    ACTION_COLUMN(_childViewType),
    includes(customerEmails.emails, currentUser.email) ? CUSTOMER_SELL_COLUMN : SELL_COLUMN,
  ];
  const [tableColumns, setTableColumns] = useState(_tableColumns);

  const setRowClass = (record: any) => {
    const view = _childViewType;
    const isChild = !!record.childSku?.length;
    const rootClass = view === 'like' && isChild ? 'sales-order-row clickable-row' : 'sales-order-parent-row clickable-row';
    const activeClass = record.active ? '' : ' pricing-record-inactive';
    return `${rootClass}${activeClass}`;
  };

  const onPageChange = (newPage: number) => {
    scrollToHash('#customerList', {
      ...scrollOptions,
      time: 1000,
    });
    pageChangeCallback(newPage);
  };

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setRenderItems(renderItems);

    // if we are not using the price list to copy a BOM, scroll back to the part last viewed
    if (!showBomCopyDrawer) {
      setTimeout(() => {
        const el = document.querySelector(`[data-row-key="${partId}"]`);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      });
    }
  }, [renderItems]);

  useEffect(() => {
    const _columns = [
      childViewType === 'like' ? CHILD_SKU_COLUMN : SKU_COLUMN,
      childViewType === 'like' ? CHILD_DESCRIPTION_COLUMN : DESCRIPTION_COLUMN,
      includes(customerEmails.emails, currentUser.email) ? CUSTOMER_SELL_COLUMN : SELL_COLUMN,
      ACTION_COLUMN(childViewType),
    ];
    // @ts-ignore
    setTableColumns(_columns);
    setChildViewType(childViewType);
  }, [childViewType]);

  const onRowClick = (record: any) => (e: any) => {
    const copyButtonId = `${record.Sku}-copy`;
    if (showBomCopyDrawer) {
      setCopiedBomPartNumber(record.Sku);
      setShowBomCopyDrawer(false);
    } else if (includes(customerUsers.emails, currentUser.email)) {
      const copyButton = document.getElementById(copyButtonId);
      if (!copyButton) return;
      copyButton.click();
    } else {
      stageRedirect(`/pricing?partId=${record.Sku}`);
      const partType = (record.type.match(/[G|B]N/i) || record.type === 'neck') ? 'neck' : 'body';
      window.location.href = `/pricing/${partType}?partId=${record.Sku}`;
    }
  };

  return (
    <>
      {(pricing.length === 0 && !!currentCustomer.id)
        ? (
          <EmptyPriceItem key="empty-price-item-wrapper">
            <Body key="no-priced-items-text">{`No priced items for ${currentCustomer.DisplayName}`}</Body>
          </EmptyPriceItem>
        ) : (
          <CustomerPricingWrapper key="price-list-customer-pricing-wrapper">
            <ItemTable
              dataSource={_renderItems.filter((r: any) => !!r.Sku)}
              rowKey="Sku"
              size="large"
              rowClassName={setRowClass}
              columns={tableColumns}
              pagination={{ pageSize: 100, current: _currentPage, onChange: onPageChange }}
              scroll={{ y: window.innerHeight * 0.7 }}
              tableLayout="auto"
              onRow={(record: IInventoryPart) => ({
                onClick: onRowClick(record),
              })}
              expandable={null}
            />
          </CustomerPricingWrapper>
        )}
    </>
  );
};
