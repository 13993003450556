import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ItemDetailsLabel, ItemDetailsColumnWrapper } from 'shared/styledComponents/inputs';
import { Switch } from 'antd';

const ActiveSwitch = styled(Switch)`
  margin: 4px 0 6px 0;
`;
const ComponentWrapper = styled(ItemDetailsColumnWrapper)`
    margin: ${(props: { margin: string}) => props.margin};
`;

interface IComponent {
  initialState: boolean;
  callback: (state: boolean) => void;
  disabled: boolean;
  componentLabel: string|null;
  checkedLabel: string;
  uncheckedLabel: string;
  componentMargin: string;
}

const DetailActiveSelectorWithCallback = ({
  callback, disabled, initialState, componentLabel = null, checkedLabel = 'ACTIVE', uncheckedLabel = 'INACTIVE', componentMargin = '0 0 0 auto',
}: IComponent) => {
  const [active, setActive] = useState<boolean>(initialState);

  const onChange = (e: any) => {
    setActive(e);
    callback(e);
  };

  useEffect(() => {
    setActive(initialState);
  }, [initialState]);

  return (
    <ComponentWrapper key="part-active-item-details-wrapper" margin={componentMargin}>
      {componentLabel ? (
        <ItemDetailsLabel key="part-active-item-details-label">{componentLabel}</ItemDetailsLabel>
      ) : (
        <ItemDetailsLabel key="part-active-item-details-label">&nbsp;</ItemDetailsLabel>
      )}
      <ActiveSwitch
        disabled={disabled}
        checked={active}
        checkedChildren={checkedLabel}
        unCheckedChildren={uncheckedLabel}
        onChange={onChange}
      />
    </ComponentWrapper>
  );
};

export default DetailActiveSelectorWithCallback;
