import { Menu, Select } from 'antd';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PART_DATA_ATOM, PART_STATE_ATOM } from 'shared/data';
import { IArchetype } from '../../../types';
import { MenuSelect } from '../../styledComponents';
import { hideUnselectedOptionsAtom, partEditModeAtom } from '../../../../shared/state/pricingState';

const shortid = require('shortid');

interface ComponentInterface {
  partType: 'body'|'neck';
  prompt: string;
}

export default ({ partType, prompt }: ComponentInterface) => {
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(PART_STATE_ATOM[partType]);
  const editMode = useRecoilValue(partEditModeAtom);

  // @ts-ignore
  const partArchetype = useRecoilValue(PART_DATA_ATOM[partType]).archetype;
  const instrumentGroups = partArchetype.types.map((t: string) => ({
    type: t,
    items: partArchetype.items.filter((i: any) => i.instrument === t),
  }));

  const onClick = (e: any) => {
    const model = partArchetype.items.filter((b: { id: string, type: string }) => b.id === e)[0];
    setPartState({
      ...partState,
      // [partType]: {
      //   ...partState[partType],
      archetype: model,
      // }
    });
  };

  return (
    <MenuSelect key="archetype-menu-select" disabled={!editMode} value={partState.archetype?.id || prompt} onChange={onClick}>
      {instrumentGroups.map((itemGroup: { type: string, items: IArchetype[]}) => {
        const types = { L: 'Large', M: 'Medium', S: 'Small' };
        // @ts-ignore
        let type = types[itemGroup.type];
        if (window.location.href.match(/neck/i)) type = itemGroup.type === 'L' ? 'Bass' : 'Guitar';
        return (
          <Select.OptGroup key={`archetype-select-optgroup-${type}`} label={type}>
            {itemGroup.items.map((i: IArchetype) => (
              <Select.Option key={i.id} value={i.id}>{i.type}</Select.Option>
            ))}
          </Select.OptGroup>
        );
      })}
    </MenuSelect>
  );
};
