import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import firebase from 'firebase';
import { IQACorrectiveStep } from 'shared/types/dbRecords';
import { UnfoldMore, UnfoldLess } from '@styled-icons/material';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWIthCallback';
import { DetailRow } from 'shared/styledComponents/containers';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWIthCallback';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWIthCallback';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import ActiveSelector from 'pages/Customer/Components/PriceListInputs/ActiveSelector';
import { Button } from 'antd';

interface IComponent {
  correctiveStep: IQACorrectiveStep;
  callback: (correctiveStep: IQACorrectiveStep) => void;
  isShopUser: boolean;
  reportType: 'non-conformance'|'quality';
}

const HeaderRow = styled(FlexRow)`
    width: 100%;
    justify-content: flex-start;
    height: 40px;
    gap: 16px;
`;

const CollapseButton = styled(Button)`
    border-radius: 8px;
    margin: 0 0 14px auto;
`;

const UnfoldMoreIcon = styled(UnfoldMore)`
    height: 24px;
`;
const UnfoldLessIcon = styled(UnfoldLess)`
    height: 24px;
`;
const ComponentWrapper = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 16px 16px 16px;
    margin-bottom: 16px;
    max-height: ${(props: { collapse: boolean}) => (props.collapse ? '48px' : '1000px')};
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid lightgrey;
`;

const QACorrectiveStep = ({
  correctiveStep, callback, isShopUser, reportType,
}: IComponent) => {
  const [_correctiveStep, setCorrectiveStep] = useState<IQACorrectiveStep>(correctiveStep);
  const [collapse, setCollapse] = useState<boolean>(true);
  const [showDetails, setShowDetails] = useState<boolean>(true);

  const onChangeInputValue = (key: string) => (value: string, blur: boolean) => {
    const updatedStep = { ..._correctiveStep };
    updatedStep[key] = value;
    setCorrectiveStep(updatedStep);
    if (blur) callback(updatedStep);
    if (blur && key === 'title' && collapse) setCollapse(!collapse);
  };
  const onChangeValue = (key: string, blur: boolean = false) => (value: string) => {
    const updatedStep = { ..._correctiveStep };
    updatedStep[key] = value;
    setCorrectiveStep(updatedStep);
    if (blur) callback(updatedStep);
  };

  const onChangeDate = (key: string) => (date: Date) => {
    if (!Number.isNaN(date.getTime())) {
      const updatedStep = { ..._correctiveStep };
      updatedStep[key] = firebase.firestore.Timestamp.fromDate(date);
      setCorrectiveStep(updatedStep);
      callback(updatedStep);
    }
  };

  const onToggleCollapse = (e: any) => {
    const newValue = !collapse;
    if (newValue) { // do collapse
      setCollapse(newValue);
      setTimeout(() => {
        setShowDetails(false);
      }, 300);
    } else {
      setShowDetails(true);
      setCollapse(newValue);
    }
  };

  useEffect(() => {
    setCorrectiveStep(correctiveStep);
  }, [correctiveStep]);

  return (
    <ComponentWrapper collapse={collapse}>
      <HeaderRow>
        <ActiveSelector
          key={`qa-ticket-${_correctiveStep.id}-type-selector`}
          activeType={_correctiveStep.type}
          callback={onChangeValue('type', true)}
          allValue="Immediate"
          activeValue="Permanent"
          inactiveValue={undefined}
          label="Suggestion type"
          margin="0 0 12px 0"
        />
        <DetailInputWithCallback
          id={`qa-ticket-${_correctiveStep.id}-title`}
          placeholder={`Title of ${reportType === 'non-conformance' ? 'corrective' : 'implmentation'} step`}
          value={_correctiveStep.title}
          callback={onChangeInputValue('title')}
          extend={false}
          isLabel={false}
          width="300px"
        />
        <CollapseButton onClick={onToggleCollapse} icon={collapse ? <UnfoldMoreIcon /> : <UnfoldLessIcon />} />
      </HeaderRow>
      {showDetails && (
      <>
        <DetailTextAreaWithCallback
          id={`qa-ticket-${_correctiveStep.id}--correction`}
          label={`Suggestion for ${_correctiveStep.type === 'immediate' ? 'Immediate' : 'Permanent'} Correction`}
          placeholder="Step(s) that should be taken to mitigate problem."
          value={correctiveStep.description}
          changeCallback={onChangeValue('description')}
          blurCallback={onChangeValue('description', true)}
          disabled={isShopUser}
        />
        <DetailRow>
          <DetailInputWithCallback
            id={`qa-ticket-${_correctiveStep.id}-corrected-by`}
            label="Person(s) Responsible for Correction"
            value={_correctiveStep.personResponsible}
            callback={onChangeValue('personResponsible')}
            extend={false}
            isLabel={false}
            width="256px"
          />
          <DetailDateWithCallback
            value={_correctiveStep.implementationDate?.toDate()}
            id={`qa-ticket-${_correctiveStep.id}-implementationDate`}
            label="Implementation Date"
            callback={onChangeDate('implementationDate')}
            disabled={false}
            disableDates={null}
          />
        </DetailRow>

        {/* Results */}
        <DetailTextAreaWithCallback
          id={`qa-ticket-${_correctiveStep.id}-results`}
          label="Results"
          placeholder="Results of corrective step"
          value={_correctiveStep.result}
          changeCallback={onChangeValue('result')}
          blurCallback={onChangeValue('result', true)}
          disabled={isShopUser}
        />
        <DetailDateWithCallback
          value={_correctiveStep.resultDate?.toDate()}
          placeholder="Date of resolution"
          id={`qa-ticket-${_correctiveStep.id}-resultDate`}
          label="Result date"
          callback={onChangeDate('resultDate')}
          disabled={false}
          disableDates={null}
        />
      </>
      )}
    </ComponentWrapper>
  );
};

export default QACorrectiveStep;
