import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { includes, sortBy } from 'lodash';
import { Drawer } from 'antd';
import { FlexRow } from 'shared/containers/FlexContainer';
import useFirebase from 'vendor/Firebase';
import Loader from 'shared/components/Utility/Loader';
import { IBomItem } from 'shared/types/dbRecords';

const Panel = styled(Drawer)`
  height: 100vh;
  position: absolute;
  z-index: 1000000;
`;
const ItemRow = styled(FlexRow)`
  width: calc(100% - 4px);
  height: 40px;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.02)' : 'transparent')};
  justify-content: flex-start;
  gap: 16px;
  border-bottom: 1px solid lightgrey;
  margin: 0;
`;

const ItemHeader = styled(ItemRow)`
  background-color: transparent;
  border-bottom: 2px solid black;
  margin-bottom: 8px;
`;

const PartNumber = styled.p`
  margin: 0;  
  width: 120px;
  border-right: 1px solid lightgrey;
  font-weight: bold;
  padding-right: 4px;
`;

const PartNumberLabel = styled(PartNumber)`
  border-right: none;
`;
const PartDescription = styled.p`
  margin: 0;
`;

const PartDescriptionLabel = styled(PartDescription)`
  font-weight: bold;
`;

interface IComponent {
  showDrawer: boolean;
  inventoryPartNumber: string;
  onClose: (e: any) => void;
}
interface IWhereUsedPart {
  Sku: string;
  Description: string;
}
const WhereUsedPanel = ({ showDrawer, inventoryPartNumber, onClose }: IComponent) => {
  const { firestore } = useFirebase();
  const [parts, setParts] = useState<IWhereUsedPart[]>([]);
  const [label, setLabel] = useState<string>('Finding parts.');

  useEffect(() => {
    if (showDrawer) {
      let count = 1;
      const interval = setInterval(() => {
        count += 1;
        if (count > 3) count = 1;
        const dots = '.'.repeat(count);
        setLabel(`Finding parts${dots}`);
      }, 400);
      firestore.collection('part-bom-data').get().then((snap) => {
        const partList: IWhereUsedPart[] = [];
        snap.forEach((doc) => {
          const data = doc.data();
          if (includes(data.bom.map((b: IBomItem) => b.Sku), inventoryPartNumber)) partList.push({ Sku: doc.id, Description: data.Description });
        });
        clearInterval(interval);
        setParts(sortBy(partList, (p: IWhereUsedPart) => p.Sku));
      });
    }
  }, [showDrawer]);

  return (
    <Panel
      width="50%"
      open={showDrawer}
      title={`${inventoryPartNumber} - Where Used`}
      placement="right"
      closable
      onClose={onClose}
    >
      {parts.length ? (
        <>
          <ItemHeader>
            <PartNumberLabel>Part #</PartNumberLabel>
            <PartDescriptionLabel>Description</PartDescriptionLabel>
          </ItemHeader>
          {parts.map((p: IWhereUsedPart, index) => (
            <ItemRow shade={index % 2 === 1}>
              <PartNumber>{p.Sku}</PartNumber>
              <PartDescription>{p.Description}</PartDescription>
            </ItemRow>
          ))}
        </>
      ) : (
        <Loader radius={40} label={label} />
      )}
    </Panel>
  );
};

export default WhereUsedPanel;
