import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { currentShopOrderAtom } from 'shared/state/orderState';
import { salesOrderEditedAtom } from 'shared/state/routingState';
import theme from '../../theme';
import { rgba } from '../../palette/utils';
import { FlexRow } from '../../containers/FlexContainer';

const ComponentWrapper = styled(ItemDetailsColumnWrapper)`
  width: ${(props: { width: string | undefined }) => props.width || '100%'};
`;
// @ts-ignore
const FieldInput = styled.textarea`
    width: ${(props: { width: string }) => props.width || '100%'};
    height: ${(props: { height: string }) => props.height || '120px'};
    min-height: ${(props: { height: string }) => (props.height ? 'unset' : '120px')};
    padding: 6px 12px 16px;
    border: 1px solid lightgrey;

    &:focus {
        outline: none !important;
        border: 1px solid ${theme.palette.primary.hue} !important;
        box-shadow: 0 0 4px ${rgba(theme.palette.primary.hue, 0.6)};
    }
}
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

interface IComponent {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  changeCallback: (value: string) => void;
  blurCallback: (value: string) => void;
  width?: string;
  height?: string;
  useNumber?: boolean;
  disabled?: boolean
  LabelExtra?: any
  style?: any;
}
const DetailTextAreaWithCallback = ({
  id, value, blurCallback, changeCallback, label, placeholder, width, height, disabled = false, LabelExtra = <div />, style = {},
}: IComponent) => {
  const [fieldValue, setFieldValue] = useState<string|undefined>(value);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onChange = (e: any) => {
    setFieldValue(e.target.value);
    changeCallback(e.target.value);
  };

  const onBlur = (e: any) => {
    setFieldValue(e.target.value);
    blurCallback(fieldValue || e.target.value);
  };

  useEffect(() => {
    setFieldValue(value);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <ComponentWrapper width={width} key="part-desc-item-details-wrapper">
      <LabelWrapper>
        <ItemDetailsLabel key={`${id}-item-details-wrapper`}>{label}</ItemDetailsLabel>
        {LabelExtra}
      </LabelWrapper>
      <FieldInput
        ref={textAreaRef}
        style={style}
        width={width}
        height={height}
        key={`${id}-input-field`}
        id={`${id}-input-field`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        // onFocusOut={onBlur}
        value={fieldValue}
        disabled={disabled}
      />
    </ComponentWrapper>
  );
};

export default DetailTextAreaWithCallback;
