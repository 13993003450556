import React, { useEffect, useState } from 'react';
import qs from 'qs';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { useRecoilState, useRecoilValue } from 'recoil';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWIthCallback';
import { currentInventoryPartAtom, INVENTORY_ITEMS_COLLECTION } from 'shared/state/inventoryState';
import { IInventoryChild, IInventoryPart } from 'shared/types/dbRecords';
import DetailDateWithCallback from 'shared/components/Input/DetailDateWIthCallback';
import { qboDateString } from 'shared/data/calendar';
import useFirebase from 'vendor/Firebase';
import InventoryOnOrder from './InventoryDetailFields/InventoryOnOrder';
import DetailActiveSelectorWithCallback from '../../../shared/components/Input/DetailActiveSelectorWithCallback';

const GAP = 20;
const ComponentWrapper = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`;
const InventoryCountWrapper = styled(FlexRow)`
  justify-content: flex-start;
  gap: 8px;
`;

const SubItemWrapper = styled(FlexRow)`
    width: 100%;
    justify-content: flex-start;
    gap: 12px; 
    padding-top: 4px;
    border-bottom: 1px solid lightgrey;
`;

interface IComponent {
  onHand: number;
  partChildren: IInventoryChild[];
  disabled: boolean;
  reorderQty: number;
  criticalPart: boolean;
  inventoryAdjustDate: string;
}
const InventoryStockInfo = ({
  disabled, onHand, criticalPart, inventoryAdjustDate, partChildren = [], reorderQty = 0,
}: IComponent) => {
  const { firestore } = useFirebase();
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const [_onHand, setOnHand] = useState<number>(onHand);
  const [_reorderQty, setReorderQty] = useState<number>(reorderQty);
  const [_startDate, setStartDate] = useState<Date>(new Date(`${inventoryAdjustDate}T07:00:00`));
  const [helper, setHelper] = useState<string>('');
  const [critical, setCritical] = useState<boolean>(criticalPart);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const partCreation = !!window.location.href.match(/new|copy=true/);

  const onChangeQtyOnHand = (value: number) => {
    setPartState({ ...partState, QtyOnHand: value });
  };

  const onChangeCritical = (_critical: boolean) => {
    setPartState({ ...partState, criticalPart: _critical });
    setCritical(_critical);
  };

  const onChangeReorderQty = (_reorder: number) => {
    setPartState({ ...partState, reorderQty: _reorder });
    setReorderQty(_reorder);
  };

  const onChangeStartDate = (_date: Date) => {
    setPartState({ ...partState, InvStartDate: qboDateString(_date) });
    setStartDate(_date);
  };

  useEffect(() => {
    if (partChildren.length) {
      firestore.collection(inventoryDbString).where('Sku', 'in', partChildren.map((c) => c.sku)).get().then((snap) => {
        const data = snap.docs.map((doc) => doc.data() as IInventoryPart);
        const quantityOnHand = data.map((d: IInventoryPart) => {
          const factor = (d.Parent?.ParentPerChild || 1) > 0 ? (d.Parent?.ParentPerChild || 1) : 1 / (d.Parent?.ParentPerChild || 1);
          return d.QtyOnHand * factor;
        }).reduce((a, b) => a + b, 0);
        setOnHand(partCreation ? 0 : quantityOnHand);
        setCritical(criticalPart);
        setReorderQty(reorderQty);
        setHelper('This is a parent part—quantities are derived by children and cannot be adjusted here.');
      });
    } else {
      setOnHand(partCreation ? 0 : onHand);
      setCritical(criticalPart);
      setReorderQty(reorderQty);
      if (disabled) {
        setHelper('You have insufficient permissions to alter this value. Please contact Keith if you need to adjust this quantity.');
      } else if (partCreation) {
        setHelper('Quantity adjustment is disabled until after part is created.');
      }
    }
  }, [partChildren, onHand, criticalPart, reorderQty]);

  return (
    <ComponentWrapper>
      <InventoryCountWrapper>
        <DetailNumberInputWithCallback
          key="inventory-part-quantity-on-hand"
          label={`On Hand (${partState.PurchasingUnit || 'EA'})`}
          placeholder="0"
          value={_onHand}
          callback={onChangeQtyOnHand}
          disabled={partCreation || disabled || partState.Children?.length > 0}
          useHelper={partCreation || disabled || partState.Children?.length > 0}
          helper={helper}
        />
        <DetailDateWithCallback
          key="inventory-part-quantity-start-date"
          label={`Inv. ${partCreation ? 'Start' : 'Adj.'} Date`}
          value={_startDate}
          callback={onChangeStartDate}
          disableDates={false}
        />
        <InventoryOnOrder />
        <DetailActiveSelectorWithCallback
          initialState={critical}
          callback={onChangeCritical}
          disabled={disabled}
          componentLabel="Critical Part?"
          checkedLabel="YES"
          uncheckedLabel="NO"
        />
        <DetailNumberInputWithCallback key="inventory-part-reorder-quantity" label="Reorder Qty" placeholder="0" value={_reorderQty} callback={onChangeReorderQty} disabled={disabled || partState.Children?.length > 0} />
      </InventoryCountWrapper>
    </ComponentWrapper>
  );
};

export default InventoryStockInfo;
