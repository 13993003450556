import React, { useContext, useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import {
  find, findIndex, flatten, includes, uniq,
} from 'lodash';
import shortid from 'shortid';
import qs from 'qs';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  Button, DatePicker, Divider, Dropdown, InputNumber, Modal, Radio,
} from 'antd';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { FlexRow } from 'shared/containers/FlexContainer';

import { salesOrderEditedAtom } from 'shared/state/routingState';
import { useTestDataAtom } from 'shared/state/pricingState';
import { IPartWood } from 'shared/types/pricingTool';
import { costUpdateNotificationUsersAtom, MESSAGE_DB_PATH_ATOM } from 'shared/state/messageState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import {
  AddRunnerColumn, RunnerHeaderRow, RunnerPageTitle, DeleteSalesOrderButton,
} from '../../ProductionSchedule/styledComponents';
import {
  DescInput,
  FieldWrapper,
  FieldRow,
  InputCaption,
  SOInput,
} from '../../ProductionSchedule/Components/styledComponents';

const MarginP = styled.p`
  margin: 0;
`;

export default () => {
  const { currentUser } = useContext(AuthContext);
  const { database } = useFirebase();
  const [partName, setPartName] = useState('');
  const [pricing, setPricing] = useState({ S: 0, M: 0, L: 0 });
  const [partNumbers, setPartNumbers] = useState({ S: 0, M: 0, L: 0 });
  const [materialCost, setMaterialCost] = useState({ S: 0, M: 0, L: 0 });
  const [costUpdated, setCostUpdated] = useState<boolean>(false);
  const [pricingItems, setPricingItems] = useState<IPartWood[]>([]);
  const [type, setType] = useState<'standard'|'tilt'|'any'>('any');
  const [edited, setEdited] = useRecoilState(salesOrderEditedAtom);
  const useTestData = useRecoilValue(useTestDataAtom);
  const costUpdateNotificationUsers = useRecoilValue(costUpdateNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);

  const urlParse = qs.parse(window.location.search.replace('?', ''));
  const woodType = urlParse.woodType;
  const partId = urlParse.partId;
  const partType = useTestData ? `${urlParse.partType}Test` : urlParse.partType;
  const altPartType = useTestData ? urlParse.partType : `${urlParse.partType}Test`;

  const handlers = {
    change: {
      partName: (e: any) => {
        if (!edited) setEdited(true);
        setPartName(e.target.value);
      },
      materialCost: (part: 'S'|'M'|'L') => (e: any) => {
        if (!edited) setEdited(true);
        setCostUpdated(true);
        const cost = e.target.value.length ? parseFloat(e.target.value) : 0;
        if (part.match(/m/i)) {
          setMaterialCost({
            ...materialCost,
            M: cost,
          });
        } else if (part.match(/s/i)) {
          setMaterialCost({
            ...materialCost,
            S: cost,
          });
        } else {
          setMaterialCost(({
            ...materialCost,
            L: cost,
          }));
        }
      },
      pricing: (part: 'S'|'M'|'L') => (e: any) => {
        if (!edited) setEdited(true);
        const price = e.target.value.length ? parseFloat(e.target.value) : 0;
        if (part.match(/m/i)) {
          setPricing({
            ...pricing,
            M: price,
          });
        } else if (part.match(/s/i)) {
          setPricing({
            ...pricing,
            S: price,
          });
        } else {
          setPricing({
            ...pricing,
            L: price,
          });
        }
      },
      partNumber: (part: 'S'|'M'|'L') => (e: any) => {
        const _partNumber = parseInt(e.target.value, 10);
        const existingPartNumbers = uniq(flatten(pricingItems.map((item) => Object.values(item?.partNumber ?? {}))));
        if (!edited) setEdited(true);
        if (part.match(/m/i)) {
          setPartNumbers({
            ...partNumbers,
            M: parseInt(e.target.value, 10),
          });
        } else if (part.match(/s/i)) {
          setPartNumbers({
            ...partNumbers,
            S: parseInt(e.target.value, 10),
          });
        } else {
          setPartNumbers({
            ...partNumbers,
            L: parseInt(e.target.value, 10),
          });
        }
        if (_partNumber >= 1000000 && includes(existingPartNumbers, _partNumber)) {
          return Modal.confirm({
            content: 'This part number already exists in our database! Click "Cancel" to enter a different number, or edit the existing part.',
            okText: 'Edit existing',
            onOk: () => {
              const _part = find(pricingItems, (item) => includes(Object.values(item?.partNumber ?? {}), _partNumber));
              if (_part) {
                window.location.href = `/pricing/material/edit?partType=${partType}&woodType=${woodType}&partId=${_part.id}`;
              }
            },
          });
        }
      },
      type: (e: any) => {
        if (!edited) setEdited(true);
        setType(e.target.value);
      },
    },
    delete: (e: any) => {
      e.preventDefault();
      Modal.confirm({
        content: 'Are you sure you want to delete this part?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          database.ref(`/${partType}/${woodType}/items`).once('value').then((snap) => {
            const data = snap.val() as IPartWood[];
            database.ref(`/${partType}/${woodType}/items`).set(data.filter((i: IPartWood) => i.id !== partId)).then(() => {
              // if (!useTestData) {
              //   database.ref(`/${partType}Test/${woodType}/items`).set(data.filter((i: IPartWood) => i.id !== partId)).then(() => {
              //     window.location.href = '/pricing/edit';
              //   });
              // } else {
              window.location.href = '/pricing/edit';
              // }
            });
          });
        },
      });
    },
    update: (e: any) => {
      e.preventDefault();
      database.ref(`/${partType}/${woodType}/items`).once('value').then((snap) => {
        const records = snap.val() as IPartWood[];
        const updateIndex = findIndex(records, (i: IPartWood) => i.id === partId);
        if (costUpdated) {
          const messageId = shortid.generate();
          const notificationUsers = costUpdateNotificationUsers.filter((_user: string) => !currentUser.email.match(new RegExp(_user, 'i')));

          notificationUsers.forEach((_user: string) => {
            database.ref(`${messageDbString}/${_user}/${messageId}`).set({
              id: messageId,
              subject: 'The cost for a part has been updated',
              message: `${currentUser.email.split('@')[0]} just updated the cost of ${partName} in our pricing tool. Verify that the current listed sell price is valid.`,
              clickUrl: window.location.href,
              archived: false,
              read: false,
              sent: new Date().getTime(),
            });
          });
        }
        database.ref(`/${partType}/${woodType}/items/${updateIndex}`).update({
          materialCost, price: pricing, partNumber: partNumbers, label: partName,
        }).then(() => {
          if (!useTestData) {
            database.ref(`/${partType}Test/${woodType}/items/${updateIndex}`).update({
              partNumber: partNumbers, label: partName,
            }).then(() => {
              window.location.href = '/pricing/edit';
            });
          } else {
            window.location.href = '/pricing/edit';
          }
        });
      });
      // const order = {
      //   ...Object.fromEntries(Object.entries(currentShopOrder).filter((i) => !i[0].match(/runners/i))),
      // };
      //
      // const runners = generateRunners(workOrders, currentShopOrder);
      // if (runners.length > 0) {
      //   // @ts-ignore
      //   order.runners = runners;
      // }
      //
      // firestore.collection(ORDERS_DB_COLLECTION).doc(orderId).set(order).then(() => {
      //   redirect();
      // });
    },
    // eslint-disable-next-line consistent-return
    submit: (e: any) => {
      e.preventDefault();
      if (partId) return handlers.update(e);

      let errorText = null;
      if (partName.length === 0) {
        errorText = 'Please add a label for this part';
      }
      if (!pricing.M && !pricing.L) {
        errorText = 'Please add a price for this part';
      }
      if (errorText) {
        return Modal.error({
          content: errorText,
          okText: 'OK',
        });
      }
      const record = {
        id: shortid.generate(),
        price: pricing,
        materialCost,
        partNumber: { S: partNumbers.S || 0, M: partNumbers.M || 0, L: partNumbers.L || 0 },
        type,
        label: partName,
      };
      database.ref(`/${partType}/${woodType}/items`).once('value').then((snap) => {
        const records = snap.val() as IPartWood[];
        const updateIndex = records.length;

        if (costUpdated) {
          const messageId = shortid.generate();
          const notificationUsers = costUpdateNotificationUsers.filter((_user: string) => !currentUser.email.match(new RegExp(_user, 'i')));

          notificationUsers.forEach((_user: string) => {
            database.ref(`${messageDbString}/${_user}/${messageId}`).set({
              id: messageId,
              subject: 'The cost for a part has been updated',
              message: `${currentUser.email.split('@')[0]} just updated the cost of ${partName} in our pricing tool. Verify that the current listed sell price is valid.`,
              clickUrl: window.location.href,
              archived: false,
              read: false,
              sent: new Date().getTime(),
            });
          });
        }
        database.ref(`/${partType}/${woodType}/items/${updateIndex}`).set(record).then(() => {
          if (!useTestData) {
            const items = database.ref(`/${altPartType}/${woodType}/items`).once('value').then((s) => {
              const testRecords = s.val() as IPartWood[];
              const newIndex = testRecords.length;
              database.ref(`/${altPartType}/${woodType}/items/${newIndex}`).set(record).then(() => {
                window.location.href = '/pricing/edit';
              });
            });
          } else {
            window.location.href = '/pricing/edit';
          }
        });
      });
    },
  };

  useEffect(() => {
    database.ref(`/${partType}/${woodType}/items`).once('value').then((snap) => {
      setPricingItems(snap.val());
      if (partId) {
        const data = find(snap.val(), (i: IPartWood) => i.id === partId) as IPartWood;
        if (!data) return;

        setPartName(data.label);
        setPricing(data.price);
        setMaterialCost(data.materialCost || { S: 0, M: 0, L: 0 });
        setPartNumbers(data.partNumber || { S: 0, M: 0, L: 0 });
      }
    });
  }, []);

  return (
    <ConfigurationCol>
      <RunnerHeaderRow>
        <RunnerPageTitle>{`${partId ? 'Edit' : 'Add'} Part Material`}</RunnerPageTitle>
        <FlexRow style={{ flexGrow: 2, marginTop: 4, gap: 20 }} justify="flex-start">
          <Button type="primary" shape="round" onClick={handlers.submit}>{partId ? 'Save' : 'Create'}</Button>
          {!!partId
          && <DeleteSalesOrderButton type="primary" onClick={handlers.delete}>Delete</DeleteSalesOrderButton>}
        </FlexRow>
      </RunnerHeaderRow>
      <AddRunnerColumn>
        <Divider key={shortid.generate()} orientation="left">Customer</Divider>
        <FieldRow>
          <FieldWrapper style={{ width: 456 }}>
            <InputCaption>Part name</InputCaption>
            <DescInput
              onChange={handlers.change.partName}
              defaultValue=""
              value={partName}
              placeholder="e.g., 4/4 Maple, Quartersawn"
            />
          </FieldWrapper>
        </FieldRow>

        <Divider key={shortid.generate()} orientation="left">Guitar (Small) Details</Divider>
        <FieldRow>
          <FieldWrapper>
            <InputCaption>Part #</InputCaption>
            <SOInput
              onChange={handlers.change.partNumber('S')}
              defaultValue=""
              value={partNumbers.S}
              placeholder="e.g., 9999"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Cost</InputCaption>
            <SOInput
              onChange={handlers.change.materialCost('S')}
              defaultValue=""
              value={materialCost.S || ''}
              placeholder="e.g., 120"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Sell</InputCaption>
            <SOInput
              onChange={handlers.change.pricing('S')}
              defaultValue=""
              value={pricing.S || ''}
              placeholder="e.g., 200"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Margin</InputCaption>
            <MarginP>
              {(!!pricing?.S && materialCost?.S) ? `${((1 - (materialCost.S / pricing.S)) * 100).toFixed(2)}%` : '--'}
            </MarginP>
          </FieldWrapper>
        </FieldRow>
        <Divider key={shortid.generate()} orientation="left">Guitar (Medium) Details</Divider>
        <FieldRow>
          <FieldWrapper>
            <InputCaption>Part #</InputCaption>
            <SOInput
              onChange={handlers.change.partNumber('M')}
              defaultValue=""
              value={partNumbers.M}
              placeholder="e.g., 9999"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Cost</InputCaption>
            <SOInput
              onChange={handlers.change.materialCost('M')}
              defaultValue=""
              value={materialCost.M || ''}
              placeholder="e.g., 120"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Sell</InputCaption>
            <SOInput
              onChange={handlers.change.pricing('M')}
              defaultValue=""
              value={pricing.M || ''}
              placeholder="e.g., 200"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Margin</InputCaption>
            <MarginP>
              {(!!pricing?.M && materialCost?.M) ? `${((1 - (materialCost.M / pricing.M)) * 100).toFixed(2)}%` : '--'}
            </MarginP>
          </FieldWrapper>
        </FieldRow>
        <Divider key={shortid.generate()} orientation="left">Bass Details</Divider>
        <FieldRow>
          <FieldWrapper>
            <InputCaption>Part #</InputCaption>
            <SOInput
              onChange={handlers.change.partNumber('L')}
              defaultValue=""
              value={partNumbers.L}
              placeholder="e.g., 9999"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Cost</InputCaption>
            <SOInput
              onChange={handlers.change.materialCost('L')}
              defaultValue=""
              value={materialCost.L || ''}
              placeholder="e.g., 100"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Sell</InputCaption>
            <SOInput
              onChange={handlers.change.pricing('L')}
              defaultValue=""
              value={pricing.L || ''}
              placeholder="e.g., 200"
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputCaption>Margin</InputCaption>
            <MarginP>
              {(!!pricing?.L && materialCost?.L) ? `${((1 - (materialCost.L / pricing.L)) * 100).toFixed(2)}%` : '--'}
            </MarginP>
          </FieldWrapper>
        </FieldRow>
      </AddRunnerColumn>
    </ConfigurationCol>
  );
};
