import { addMeta, cleanMeta } from '../text';

import { resolveActive } from './helpers';

const _ = require('lodash');
const {
  extractPattern,
  regexMaker,
  normalizeTerm,
  partType,
  simpleTest,
} = require('./helpers');

export const WOOD_MAP = {
  None: ['None'],
  Wenge: ['Wenge'],
  RstFlatMap: ['Flt RstMap', 'FltRstMap', 'FltS Rst Map', 'RstFlatMap', 'RstFltMap'],
  Map: ['Map', '(?<=_)Mp', 'Mpl', 'Maple'],
  QSMap: ['QTR MAP', 'Qtr  Map', 'QTRMAP', 'Qtr Sawn Map', 'Quarter\\s?sawn\\s?Map'],
  RstQSMap: ['QtrMap RST', 'RstQAMap', 'RstQtr Map', 'RST QTR MAP', 'Rst Qtr  Map', 'Rst\\s?QTRMAP', 'Rst\\s?QtrMap', 'RstQSMap'],
  EBY: ['Ebony', 'EBY', ' EBY', 'Ebony -Black'],
  MacEBY: ['MacEBY', 'Mac Eby', 'MCSR EBY', 'MCSREBY'],
  'EBY PH': ['EBY PhCap', 'Eb PHCap', 'Eb PH cap', 'Eby PH'],
  IRW: ['Dark IRW', 'IRW'],
  'Hon RW': ['HRW'],
  'Mad RW': ['MADIRW', 'MadRW'],
  'IRW PH': ['IRW PH', 'IRW PH cap'],
  '5ANatFlameMap': ['(?!Rst)5AFlame(?!Map)', '(?!Rst)5AFlameMaple', '(?!Rst)5AFlameMap', '5ANatFlame(?!Map)', '5AFlameNat(?!Map)'],
  '6ANatFlameMap': ['(?!Rst)5AFlame(?!Map)', '(?!Rst)6AFlameMaple', '(?!Rst)6AFlameMap', '6ANatFlame(?!Map)', '6AFlameNat(?!Map)'],
  '3ANatFlameMap': ['(?!Rst)5AFlame(?!Map)', '(?!Rst)3AFlameMaple', '(?!Rst)3AFlameMap', '3ANatFlame(?!Map)', '3AFlameNat(?!Map)'],
  Bse: ['\\bBSE\\b', 'BSE'],
  '5ANatBseMap': ['5ABSE', 'BSE 5A', '5A BSE'],
  '4ANatBseMap': ['4ABSE', 'BSE 4A', '4A BSE'],
  '3ANatBseMap': ['3ABSE', 'BSE 3A', '3A BSE'],
  '5ARstFlameMap': ['5A\\s?Roasted\\s?Map', '5ARstFlameMap', '5ARstMap', 'RstMap5A', '5ARstFlmMap', 'RstFLM 5A', 'RST5AFLM', 'Rst FLM 5A', 'Rst 5A FMap', 'Rst5AFlmMap', '5A RstFlm'],
  '3ARstFlameMap': ['3A\\s?Roasted\\s?Map', '3ARstFlameMap', '3ARstMap', 'RstMap3A', '3ARstFlmMap', 'RST 3A FLM RST', 'RstFLM 3A', 'RST3AFLM', 'Rst FLM 3A', 'Rst 3A FMap', 'Rst3AFlmMap', 'Rst 3AFlmMap', '3ARstFLM', '3A Rst Flm Map', '3A FLM RST'],
  '2ARstFlameMap': ['2A\\s?Roasted\\s?Map', '2ARstFlameMap', '2ARstMap', 'RstMap2A', '2ARstFlmMap', '2 A Flm Rst', '2A Rst Flm', 'RST 2A FLM RST', 'RstFLM 2A', 'RST2AFLM', 'Rst FLM 2A', 'Rst 2A FMap', 'Rst2AFlmMap', 'Rst 2AFlmMap'],
  '6ARstFlameMap': ['6A\\s?Roasted\\s?Map', '6ARstFlameMap', '6ARstMap', 'RstMap6A', '6ARstFlmMap', '6AFlame', '6A FLM', 'MG FLM'],
  '5ARstBseMap': ['RstBSE 5A', '5A BSE Rst Map'],
  '3ARstBseMap': ['RSTBSE 3A', '3A BSE Rst Map'],
  '6ARstBseMap': ['RSTBSE 6A', '6A BSE Rst Map'],
  MGRstFlameMap: ['RstMap MG'],
  'HoneyRst Map': ['HonRST Map'],
  'Black Richlite': ['Blk RIC'],
  PauFerro: ['PAU(?!F)', 'PauF(?!e)'],
  VinFlame: ['Vin Flame', 'VinFlame', 'VinFLM'],
  CS: ['customer supplied', 'cs', 'c/s', 'cs ph', '1Pc CS', '1 Pc CS'],
  KhyMah: ['kamah', 'khnmah', 'kha mah', 'khamah', 'khayan', 'khymah', 'khmah', 'khy mah', 'kh mah', 'khmah', 'k mah'],
  GenMah: ['1pc genmah', '1pc gen mah', 'gen mah', 'genmah'],
  QSGenMah: ['Qtr GenMah'],
  OGRW: ['ogrw', 'ogvg rwd', 'redwood'],
  EWPine: ['ew pine', 'ewp', 'ewpine', 'ewht pine', 'ewhtpine', 'whtpine', 'e wht pine'],
  'Rst EWPine': ['rst ew pine', 'rst ewp', 'rst whtpine', 'rstwhtpine', 'rstwpine', 'rst wht pine'],
  'Black Veneer': ['BlkVen', 'Blk Ven', 'BlackVeneer', 'Black Veneer'],
  'White Korina': ['wht korina', 'white korina'],
  'Black Korina': ['Blk Limba', 'BlkKor', 'BlackKor', 'BlkKorina', 'BlackKorina', 'Blk Kor', 'Black Korina', 'Blk Korina'],
  Self: ['SELF', 'Slf'],
};

export const INLAY_MAP = {
  Abalone: ['Abl\\s?', 'Aba(?!l)\\s?', 'Abalon(?!e)\\s?'],
  Black: ['Blk\\s?', 'Black\\s?', 'BlackP\\s?'],
  Cream: ['Crm\\s?', 'Cream\\s?'],
  Gold: ['Gold\\s?', 'GLD\\s?'],
  White: ['Wht\\s?', 'White\\s?P?\\s?'],
  Pearloid: ['Pearld', 'Pearl(?!o)', 'Pearloid'],
  PearloidBlock: ['Pearloid\\s?Block', 'Pearloid\\s?Blk'],
  PearloidBind: ['Pearloid\\sBind'],
  BlackMop: ['MOP\\s?Blk', 'MOP Black'],
  WhiteMop: ['MOP\\s?Wht', 'MOP White'],
  GoldMop: ['MOP\\s?Gld', 'MOP Gold'],
  Mop: ['MOP\\s?', 'Mop\\s?'],
  Lumi: ['Luminlay\\s?', 'Lum(?!i)\\s?', 'Lumilay\\s?'],
  'BlackF\/BlackS': ['Blk\\s?F&S', 'BlkPl\\s?F&S', 'BlackPl\\s?F&S', 'BlackP\\s?F&S'],
  'IvoryF\/IvoryS': ['Ivory\\s?F&S'],
  'MopF\/MopS': ['MopF&S'],
  'AbaloneF\/AbaloneS': ['Abl\\s?F&S', 'Aba\\s?F&S', 'Abalone\\s?F&S'],
  'BlackBlock/BlackS': ['BlackBlock & BlackS'],
  Blocks: ['\\s?Block[s]?'],
};

const extractNut = (partDescription: string) => {
  const match = extractPattern(partDescription, '(1.[0-9]+N)');
  return match || '';
};

const extractWood = (partDescription: string) => {
  const m = regexMaker(_.flatMapDeep(Object.entries(WOOD_MAP)), [], false, true, 'end');
  const wood = partDescription.match(m) || ['//'];
  const self = simpleTest(partDescription, 'self');
  const [core, fretboard, ph] = wood[0].split('/').map((s) => s.replace(/_/g, '').trim());
  const _ph = ph ? ph.replace(/(\s?PH([\scap]+)?)/i, '') : 'None';
  return [
    core && core.match(/self/i) && self ? 'CS' : core,
    fretboard ? fretboard.match(/cs/i) ? 'CS' : self ? 'SELF' : fretboard : '',
    _ph,
  ].map((w) => (w ? normalizeTerm(WOOD_MAP, w) : ''));
};

const extractInlay = (partDescription: string) => {
  const inlayMatcher = /([a-z\s]+)(Frame[s]?|Crown[s]?|Block[s]?|Leaves|Oval[s]?|F)(-[^\/]+)?\/(([0-9\.]{1,3}mm)?[a-z]+)(S)/i;
  const inlayMatch = partDescription.match(inlayMatcher);

  if (!inlayMatch) {
    return { frontInlay: { material: '', shape: '' }, sideInlay: { material: '', shape: '' } };
  }

  const [match, _frontMaterial, _frontType, _frontMisc, _sideMaterial, materialSize, _sideType, ...rest] = inlayMatch;
  let frontMaterial = _frontMaterial;
  let frontType = _frontType;
  let sideMaterial = _sideMaterial;
  let sideType = _sideType;

  /*
  * if the front or side "material" is "No" then it is actually geometric in nature,
  * for this reason, it should be concatenated with the type value as opposed to material.
  */
  if (_frontMaterial === 'No') {
    frontMaterial = '';
    frontType = `${_frontMaterial}${_frontType}`;
  }

  if (_sideMaterial === 'No') {
    sideMaterial = '';
    sideType = `${_sideMaterial}${_sideType}`;
  } else if (_sideMaterial.match(/lumi/i)) {
  /*
 * if the "material" is a type of luminlay then we need to indicate the size of the side dot
 * so we know if it is the same geometric shape as another.
 */
    const size = _sideMaterial.match(/[0-9]mm/);
    if (size) {
      sideMaterial = _sideMaterial.replace(size[0], '');
      sideType = `${size[0]}${_sideType}`;
    }
  }

  return { frontInlay: { material: frontMaterial, shape: frontType }, sideInlay: { material: sideMaterial, shape: sideType } };
};

const extractFretCount = (partDescription: string) => {
  let match = extractPattern(partDescription, '[0-9]{2}F');
  if (match) return match.replace('F', '');

  match = extractPattern(partDescription, '[0-9]{1}-[0-9]{2}-[0-9]{2}');
  return match ? parseInt(match.split('-')[1], 10) : '';
};

const extractFretProfile = (partDescription: string) => {
  let fretwireProfile = 'any';
  let fretwireMaterial = 'NoFrets';

  let match = partDescription.match(/_(\*{2})?[0-9]{5}(SS)?(EVO)?(EG)?(NoFrets)?(\*{2})?_/i);
  if (match) {
    const profileMatch = match[0].match(/[0-9]{5}/);
    if (profileMatch) fretwireProfile = profileMatch[0];
    const materialMatch = match[0].match(/(ss|evo|eg|nofrets)/i);
    fretwireMaterial = materialMatch ? materialMatch[0] : 'NS';
  }

  match = extractPattern(partDescription, '_(lined)?(frets|fretless)_');
  if (match) {
    // @ts-ignore
    fretwireProfile = match.replace(/_/g, '');
    fretwireMaterial = fretwireProfile.match(/lined/i) ? 'Plastic' : 'None';
  }

  return { profile: fretwireProfile, material: fretwireMaterial };
};

const extractModel = (partDescription: string) => {
  const match = partDescription.match(new RegExp('[GB]N_[-0-9/a-z\\s]+_', 'i'));
  return match ? match[0].replace(/[GB]N_/, '').replace(/_/, '') : 'Other';
};

const extractBinding = (partDescription: string) => {
  const r = /(Map|White|Cream|Black|Pearloid)?(bind){1}(shelf)?_/i;
  const match = partDescription.match(r);
  if (match) {
    if (match[1]) return match[1];
    return 'Binding Shelf';
  }
  return 'None';
};

const extractStrings = (partDescription: string) => {
  if (simpleTest(partDescription, '^BN')) {
    let match = extractPattern(partDescription, '[456]ST');
    if (match) return match.replace(/st/i, '');
    match = extractPattern(partDescription, '[0-9]{1}-[0-9]{2}-[0-9]{2}');
    return match ? match.split('-')[0] : '';
  }
  return simpleTest(partDescription, '12ST') ? '12' : '6';
};

const graphite = (partDescription: string) => {
  const match = partDescription.match(/(cs|ww|dbl|pi)?\s?gr[a]?ph/i);
  if (match && match[0] === ' Graph') console.log(partDescription);
  return match ? match[0] : '';
};

const cncLoad = (partNumber: string, description: string) => {
  if (description.match(/1Pc/) || partNumber.match(/NASHG/)) {
    return 'baby';
  } if (description.match(/tilt/i)) {
    return 'papa';
  }
  return 'mama';
};

export const parseNecksFromPartData = (partData: any[]) => {
  const partDetails = partData.map((p) => [p.active ? 'Y' : 'N', p.Sku, p.Description, p.price.toString()]);
  return neckParser(partDetails);
};
const neckParser = (neckData: string[][]) => neckData
  .map((l: string[]) => {
    try {
      const [active, partNumber, _description, price, ...rest] = l;
      const description = cleanMeta(_description);
      const customer = partNumber.slice(0, 5);
      const type = partType(description);
      const [core, fretboard, ph] = extractWood(description);
      const { frontInlay, sideInlay } = extractInlay(description);
      const partPrice = price ? parseInt(price.replace('$', ''), 10) : 0;
      return {
        active: resolveActive(active),
        // common part attributes
        customer: customer || '',
        cncLoad: cncLoad(partNumber, description),
        Sku: partNumber,
        Description: addMeta(description),
        type: type || '',
        price: partPrice,
        model: extractModel(description),
        neckCore: core[0] || '',
        fretboard: fretboard[0] || '',
        peghead: (ph && ph[0]) ? ph[0] : 'None',
        lefty: simpleTest(description, 'lefty|lft|left', 'Y', 'N'),
        scale: extractPattern(description, '[0-9]{1}/[0-9]{2}(/[0-9]{2})?'),

        // neck-specific
        // bound: extractPattern(description, '_([a-z]+\\s)?(bound|bind)_', 'N binding').replace(/_/g, ''),
        bound: extractBinding(description),
        carve: extractPattern(description, '([0-9]+(-[0-9]+)?(A|BT|C|D|SRV|SV|U))'),
        cnc: simpleTest(description, 'cnc', 'Y', 'N'),
        fbs: simpleTest(description, 'nofbs', 'N', 'Y'),
        fretCount: extractFretCount(description),
        fretwire: extractFretProfile(description),
        frontInlay,
        graphite: graphite(description),
        looseFrets: simpleTest(description, 'loose', 'Y', 'N'),
        nfs: simpleTest(description, 'nfs', 'N', 'Y'),
        nut: extractNut(description),
        nutType: simpleTest(description, 'floyd', 'Floyd', 'Standard'),
        radius: extractPattern(description, '[-0-9/.]+?C?R'),
        revPh: simpleTest(description, 'revph', 'Y', 'N'),
        skunk: extractPattern(description, 'sk(?:unk|&b)'),
        sideInlay,
        stringCount: extractStrings(description),
        tilt: simpleTest(description, 'tilt', 'Y', 'N'),
        trAccess: simpleTest(description, '[12]WP') ? 'Peghead' : simpleTest(description, '[12]WS', 'Spoke', 'Heel'),
        trType: simpleTest(description, '2W', '2-way', '1-way'),
        vintage: simpleTest(description, 'Vin', 'Y', 'N'),
        volume: '',
      };
    } catch (e) {
      return undefined;
    }
  });

export default neckParser;
