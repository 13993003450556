import React, { useState } from 'react';
import {
  Button, Checkbox, Modal, Switch,
} from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import theme from 'shared/theme';
import {
  BODY_DATA_PATH, NECK_DATA_PATH, PRICING_DB_COLLECTION, useTestDataAtom,
} from 'shared/state/pricingState';
import {
  RAW_MATERIALS_COLLECTION, ROUTER_DATA_PATH,
} from 'shared/state/siteState';
import { PART_VIEWER_COLLECTION, pricedStatusDisplayAtom } from 'shared/state/partViewState';
import { ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { CUSTOMERS_DB_COLLECTION } from 'shared/state/customerState';

const TestModeSwitch = styled(Switch)`
    background-color: ${(props: any) => (props.checked ? theme.palette.error.hue : theme.palette.primary.hue)} !important;
`;

export default () => {
  const [useTestData, setUseTestData] = useRecoilState(useTestDataAtom);
  const [skipTestModal, setSkipTestModal] = useState(!!localStorage.getItem('bypassTestModePrompt')?.match(/true/i));
  const [modalVisible, setModalVisible] = useState(false);
  const setOrdersDBString = useSetRecoilState(ORDERS_DB_COLLECTION);
  const setPricingDBString = useSetRecoilState(PRICING_DB_COLLECTION);
  const setPartCollectionDBString = useSetRecoilState(PART_VIEWER_COLLECTION);
  const setCustomersDBString = useSetRecoilState(CUSTOMERS_DB_COLLECTION);
  const setRawMaterialsDBString = useSetRecoilState(RAW_MATERIALS_COLLECTION);
  const setBodyDataString = useSetRecoilState(BODY_DATA_PATH);
  const setNeckDataString = useSetRecoilState(NECK_DATA_PATH);
  const setRouterDataString = useSetRecoilState(ROUTER_DATA_PATH);
  const setPricedStatus = useSetRecoilState(pricedStatusDisplayAtom);

  const onModalOK = (e: any) => {
    setModalVisible(false);
    if (skipTestModal) localStorage.setItem('bypassTestModePrompt', 'true');
    else localStorage.setItem('bypassTestModePrompt', 'false');
  };
  const onSkipTestModal = (e: any) => {
    setSkipTestModal(!skipTestModal);
  };

  const TestModal = (
    <Modal
      open={modalVisible}
      onOk={onModalOK}
      footer={[
        <Checkbox checked={skipTestModal} onChange={onSkipTestModal}>Don&apos;t show this again</Checkbox>,
        <Button key="ok" type="primary" onClick={onModalOK}>
          OK
        </Button>,
      ]}
    >
      `You are now in&nbsp;
      {useTestData ? 'TEST DATA' : 'PRODUCTION'}
      {' '}
      MODE!
      Any changes you make here will
      {' '}
      {useTestData ? 'not' : 'definitely'}
      {' '}
      affect production, so
      {' '}
      {useTestData ? 'feel free to play around a bit.' : 'be sure you are only changing what should really be changed!'}
    </Modal>
  );

  const toggleUseTestData = (checked: boolean) => {
    // if (window.location.href.match(/edit\?/i)) return;

    const newState = checked;
    // if we are going into test mode, restrict pricing tool to priced items
    if (newState) setPricedStatus('priced');

    setUseTestData(newState);
    localStorage.setItem('testData', newState.toString());

    if (newState) {
      setOrdersDBString('orders-dev');
      setPricingDBString('pricing-dev');
      setPartCollectionDBString('part-viewer-data-test');
      setCustomersDBString('customers-dev');
      setRawMaterialsDBString('rawMaterials-dev');
      setBodyDataString('bodyTest');
      setNeckDataString('neckTest');
      setRouterDataString('routerTest');
    } else {
      setOrdersDBString('orders');
      setPricingDBString('pricing');
      setPartCollectionDBString('part-viewer-data');
      setCustomersDBString('customers');
      setRawMaterialsDBString('rawMaterials');
      setBodyDataString('body');
      setNeckDataString('neck');
      setRouterDataString('router');
    }
    if (!skipTestModal) setModalVisible(true);
  };

  return (
    <TestModeSwitch
      checked={useTestData}
      checkedChildren="TEST"
      unCheckedChildren="LIVE"
      onChange={toggleUseTestData}
    >
      Test Mode
    </TestModeSwitch>
  );
};
