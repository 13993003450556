import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useFirebase from 'vendor/Firebase';
import {
  BODY_DATA_PATH,
  NECK_DATA_PATH,
  useTestDataAtom,
} from 'shared/state/pricingState';
import { Button } from 'antd';
import { columns } from './ArchetypeColumns';
import { SalesOrderTable } from '../../ProductionSchedule/styledComponents';
import { IArchetype } from '../../types';
import { IPartWood } from '../../../shared/types/pricingTool';

const _ = require('lodash');

interface ComponentInterface {
  partType: string;
}

export default ({ partType }: ComponentInterface) => {
  const { database } = useFirebase();
  const useTestData = useRecoilValue(useTestDataAtom);
  const [listItems, setListItems] = useState<IArchetype[]>([]);

  const onNewClick = (e: any) => {
    window.location.href = `/pricing/archetype/edit?partType=${localStorage.getItem('pricingEditCategory')}`;
  };

  useEffect(() => {
    database.ref(`${partType}/archetype/items`).once('value', (snapshot) => {
      setListItems([]);
      const data = snapshot.val();
      setTimeout(() => {
        // @ts-ignore
        setListItems(Object.values(data).sort((a: IPartWood, b: IPartWood) => {
          if (a.label > b.label) return 1;
          if (a.label < b.label) return -1;
          return 0;
        }));
      }, 0);
    });
  }, [useTestData]);

  return (
    <>
      <Button type="primary" shape="round" onClick={onNewClick}>Add new</Button>
      <SalesOrderTable
        rowKey="recordId"
        size="small"
        // @ts-ignore
        columns={columns}
        dataSource={listItems}
        pagination={false}
        rowClassName="sales-order-row"
      />
    </>
  );
};
