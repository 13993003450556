import { atom } from 'recoil';
import { IShipment } from 'shared/types/jb';
import shortid from 'shortid';

const testData = localStorage.getItem('testData') === 'true';

export const SHIPMENTS_DB_COLLECTION = atom<string>({
  key: 'SHIPMENTS_DB_COLLECTION',
  default: 'order-shipments',
});
export const shipmentItemsAtom = atom<IShipment[]>({
  key: 'shipmentItemsAtom',
  default: [],
});
export const currentShipmentPageAtom = atom<number>({
  key: 'currentShipmentPageAtom',
  default: (() => {
    const currentPage = sessionStorage.getItem('currentShipmentPage');
    if (currentPage) return parseInt(currentPage, 10);
    return 1;
  })(),
});

export const currentShipmentItemAtom = atom<string>({
  key: 'currentShipmentItemAtom',
  default: '',
});

export const shipmentListPageSizeAtom = atom<number>({
  key: 'shipmentListPageSize',
  default: 100,
});
export const shipmentListPageCountAtom = atom<number>({
  key: 'shipmentListPageCount',
  default: 1,
});
export const shipmentPartEditedAtom = atom<boolean>({
  key: 'shipmentPartEditedAtom',
  default: false,
});
export const shipmentSearchQueryAtom = atom({
  key: 'shipmentSearchQueryAtom',
  default: sessionStorage.getItem('shipment.query.string') || '',
});
export const currentShipmentPartAtom = atom<IShipment>({
  key: 'currentShipmentPartAtom',
  default: {
    active: true,
    description: '',
    partNumber: '',
    price: 0,
    purchaseCost: 0,
    purchaseFactor: 1,
    purchasingUnit: 'EA',
    purchasingGLCode: null,
    quantityOnHand: 0,
    quantityOnOrder: 0,
    routerStep: undefined,
    useWeightedCost: false,
    uniqueId: shortid.generate(),
    weightedCost: 0,
    vendors: [],
  } as unknown as IShipment,
});
export const SHIPMENT_ITEMS_COLLECTION = atom({
  key: 'SHIPMENT_VIEWER_COLLECTION',
  default: `order-shipments${testData ? '-test' : ''}`,
});
